/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiCategory } from './ApiCategory';
import {
    ApiCategoryFromJSON,
    ApiCategoryFromJSONTyped,
    ApiCategoryToJSON,
} from './ApiCategory';
import type { ApiCoordinatePair } from './ApiCoordinatePair';
import {
    ApiCoordinatePairFromJSON,
    ApiCoordinatePairFromJSONTyped,
    ApiCoordinatePairToJSON,
} from './ApiCoordinatePair';
import type { ApiDistance } from './ApiDistance';
import {
    ApiDistanceFromJSON,
    ApiDistanceFromJSONTyped,
    ApiDistanceToJSON,
} from './ApiDistance';
import type { ApiDistrict } from './ApiDistrict';
import {
    ApiDistrictFromJSON,
    ApiDistrictFromJSONTyped,
    ApiDistrictToJSON,
} from './ApiDistrict';
import type { ApiImage } from './ApiImage';
import {
    ApiImageFromJSON,
    ApiImageFromJSONTyped,
    ApiImageToJSON,
} from './ApiImage';
import type { ApiOpeningHours } from './ApiOpeningHours';
import {
    ApiOpeningHoursFromJSON,
    ApiOpeningHoursFromJSONTyped,
    ApiOpeningHoursToJSON,
} from './ApiOpeningHours';
import type { ApiPdf } from './ApiPdf';
import {
    ApiPdfFromJSON,
    ApiPdfFromJSONTyped,
    ApiPdfToJSON,
} from './ApiPdf';
import type { ApiPropertyLobbyInfos } from './ApiPropertyLobbyInfos';
import {
    ApiPropertyLobbyInfosFromJSON,
    ApiPropertyLobbyInfosFromJSONTyped,
    ApiPropertyLobbyInfosToJSON,
} from './ApiPropertyLobbyInfos';
import type { ApiPropertyTranslations } from './ApiPropertyTranslations';
import {
    ApiPropertyTranslationsFromJSON,
    ApiPropertyTranslationsFromJSONTyped,
    ApiPropertyTranslationsToJSON,
} from './ApiPropertyTranslations';
import type { ApiResponsibility } from './ApiResponsibility';
import {
    ApiResponsibilityFromJSON,
    ApiResponsibilityFromJSONTyped,
    ApiResponsibilityToJSON,
} from './ApiResponsibility';
import type { ApiService } from './ApiService';
import {
    ApiServiceFromJSON,
    ApiServiceFromJSONTyped,
    ApiServiceToJSON,
} from './ApiService';
import type { ApiSpace } from './ApiSpace';
import {
    ApiSpaceFromJSON,
    ApiSpaceFromJSONTyped,
    ApiSpaceToJSON,
} from './ApiSpace';

/**
 * 
 * @export
 * @interface ApiProperty
 */
export interface ApiProperty {
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    haltiaId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    taitoriId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    city: string;
    /**
     * 
     * @type {ApiCoordinatePair}
     * @memberof ApiProperty
     */
    coordinates?: ApiCoordinatePair | null;
    /**
     * 
     * @type {number}
     * @memberof ApiProperty
     */
    spacesFree?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiProperty
     */
    spacesReserved?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    description?: string;
    /**
     * 
     * @type {Array<ApiCategory>}
     * @memberof ApiProperty
     */
    categories: Array<ApiCategory>;
    /**
     * 
     * @type {Array<ApiDistrict>}
     * @memberof ApiProperty
     */
    districts: Array<ApiDistrict>;
    /**
     * 
     * @type {Array<ApiService>}
     * @memberof ApiProperty
     */
    services: Array<ApiService>;
    /**
     * 
     * @type {Array<ApiResponsibility>}
     * @memberof ApiProperty
     */
    responsibilities: Array<ApiResponsibility>;
    /**
     * 
     * @type {Array<ApiDistance>}
     * @memberof ApiProperty
     */
    distances: Array<ApiDistance>;
    /**
     * 
     * @type {Array<ApiImage>}
     * @memberof ApiProperty
     */
    images: Array<ApiImage>;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    defaultImageId?: string;
    /**
     * 
     * @type {Array<ApiPdf>}
     * @memberof ApiProperty
     */
    pdfs: Array<ApiPdf>;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    defaultBlueprintPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    defaultArrivalInstructionsPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    defaultBrochurePdfId?: string;
    /**
     * 
     * @type {ApiPropertyTranslations}
     * @memberof ApiProperty
     */
    translations: ApiPropertyTranslations;
    /**
     * 
     * @type {Array<ApiSpace>}
     * @memberof ApiProperty
     */
    freeSpaces: Array<ApiSpace>;
    /**
     * 
     * @type {Array<ApiOpeningHours>}
     * @memberof ApiProperty
     */
    openingHours: Array<ApiOpeningHours>;
    /**
     * 
     * @type {ApiPropertyLobbyInfos}
     * @memberof ApiProperty
     */
    lobbyInfos?: ApiPropertyLobbyInfos;
    /**
     * 
     * @type {string}
     * @memberof ApiProperty
     */
    displayName: string;
    /**
     * 
     * @type {number}
     * @memberof ApiProperty
     */
    searchResultOrder?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiProperty
     */
    hidden?: boolean;
}

/**
 * Check if a given object implements the ApiProperty interface.
 */
export function instanceOfApiProperty(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "districts" in value;
    isInstance = isInstance && "services" in value;
    isInstance = isInstance && "responsibilities" in value;
    isInstance = isInstance && "distances" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "pdfs" in value;
    isInstance = isInstance && "translations" in value;
    isInstance = isInstance && "freeSpaces" in value;
    isInstance = isInstance && "openingHours" in value;
    isInstance = isInstance && "displayName" in value;

    return isInstance;
}

export function ApiPropertyFromJSON(json: any): ApiProperty {
    return ApiPropertyFromJSONTyped(json, false);
}

export function ApiPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'haltiaId': !exists(json, 'haltiaId') ? undefined : json['haltiaId'],
        'taitoriId': !exists(json, 'taitoriId') ? undefined : json['taitoriId'],
        'name': json['name'],
        'address': json['address'],
        'postalCode': json['postalCode'],
        'city': json['city'],
        'coordinates': !exists(json, 'coordinates') ? undefined : ApiCoordinatePairFromJSON(json['coordinates']),
        'spacesFree': !exists(json, 'spacesFree') ? undefined : json['spacesFree'],
        'spacesReserved': !exists(json, 'spacesReserved') ? undefined : json['spacesReserved'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'categories': ((json['categories'] as Array<any>).map(ApiCategoryFromJSON)),
        'districts': ((json['districts'] as Array<any>).map(ApiDistrictFromJSON)),
        'services': ((json['services'] as Array<any>).map(ApiServiceFromJSON)),
        'responsibilities': ((json['responsibilities'] as Array<any>).map(ApiResponsibilityFromJSON)),
        'distances': ((json['distances'] as Array<any>).map(ApiDistanceFromJSON)),
        'images': ((json['images'] as Array<any>).map(ApiImageFromJSON)),
        'defaultImageId': !exists(json, 'defaultImageId') ? undefined : json['defaultImageId'],
        'pdfs': ((json['pdfs'] as Array<any>).map(ApiPdfFromJSON)),
        'defaultBlueprintPdfId': !exists(json, 'defaultBlueprintPdfId') ? undefined : json['defaultBlueprintPdfId'],
        'defaultArrivalInstructionsPdfId': !exists(json, 'defaultArrivalInstructionsPdfId') ? undefined : json['defaultArrivalInstructionsPdfId'],
        'defaultBrochurePdfId': !exists(json, 'defaultBrochurePdfId') ? undefined : json['defaultBrochurePdfId'],
        'translations': ApiPropertyTranslationsFromJSON(json['translations']),
        'freeSpaces': ((json['freeSpaces'] as Array<any>).map(ApiSpaceFromJSON)),
        'openingHours': ((json['openingHours'] as Array<any>).map(ApiOpeningHoursFromJSON)),
        'lobbyInfos': !exists(json, 'lobbyInfos') ? undefined : ApiPropertyLobbyInfosFromJSON(json['lobbyInfos']),
        'displayName': json['displayName'],
        'searchResultOrder': !exists(json, 'searchResultOrder') ? undefined : json['searchResultOrder'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
    };
}

export function ApiPropertyToJSON(value?: ApiProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'haltiaId': value.haltiaId,
        'taitoriId': value.taitoriId,
        'name': value.name,
        'address': value.address,
        'postalCode': value.postalCode,
        'city': value.city,
        'coordinates': ApiCoordinatePairToJSON(value.coordinates),
        'spacesFree': value.spacesFree,
        'spacesReserved': value.spacesReserved,
        'description': value.description,
        'categories': ((value.categories as Array<any>).map(ApiCategoryToJSON)),
        'districts': ((value.districts as Array<any>).map(ApiDistrictToJSON)),
        'services': ((value.services as Array<any>).map(ApiServiceToJSON)),
        'responsibilities': ((value.responsibilities as Array<any>).map(ApiResponsibilityToJSON)),
        'distances': ((value.distances as Array<any>).map(ApiDistanceToJSON)),
        'images': ((value.images as Array<any>).map(ApiImageToJSON)),
        'defaultImageId': value.defaultImageId,
        'pdfs': ((value.pdfs as Array<any>).map(ApiPdfToJSON)),
        'defaultBlueprintPdfId': value.defaultBlueprintPdfId,
        'defaultArrivalInstructionsPdfId': value.defaultArrivalInstructionsPdfId,
        'defaultBrochurePdfId': value.defaultBrochurePdfId,
        'translations': ApiPropertyTranslationsToJSON(value.translations),
        'freeSpaces': ((value.freeSpaces as Array<any>).map(ApiSpaceToJSON)),
        'openingHours': ((value.openingHours as Array<any>).map(ApiOpeningHoursToJSON)),
        'lobbyInfos': ApiPropertyLobbyInfosToJSON(value.lobbyInfos),
        'displayName': value.displayName,
        'searchResultOrder': value.searchResultOrder,
        'hidden': value.hidden,
    };
}

