/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDistance,
  ApiDistanceCreateInput,
  ApiDistanceUpdateInput,
} from '../models';
import {
    ApiDistanceFromJSON,
    ApiDistanceToJSON,
    ApiDistanceCreateInputFromJSON,
    ApiDistanceCreateInputToJSON,
    ApiDistanceUpdateInputFromJSON,
    ApiDistanceUpdateInputToJSON,
} from '../models';

export interface CreateDistanceRequest {
    apiDistanceCreateInput: ApiDistanceCreateInput;
}

export interface DeleteDistanceRequest {
    distanceId: string;
}

export interface UpdateDistanceRequest {
    distanceId: string;
    apiDistanceUpdateInput: ApiDistanceUpdateInput;
}

/**
 * 
 */
export class DistancesPrivateApi extends runtime.BaseAPI {

    /**
     * Create distance
     * Create distance
     */
    async createDistanceRaw(requestParameters: CreateDistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiDistance>> {
        if (requestParameters.apiDistanceCreateInput === null || requestParameters.apiDistanceCreateInput === undefined) {
            throw new runtime.RequiredError('apiDistanceCreateInput','Required parameter requestParameters.apiDistanceCreateInput was null or undefined when calling createDistance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/distances`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDistanceCreateInputToJSON(requestParameters.apiDistanceCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDistanceFromJSON(jsonValue));
    }

    /**
     * Create distance
     * Create distance
     */
    async createDistance(requestParameters: CreateDistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiDistance> {
        const response = await this.createDistanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete distance
     * Delete distance
     */
    async deleteDistanceRaw(requestParameters: DeleteDistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.distanceId === null || requestParameters.distanceId === undefined) {
            throw new runtime.RequiredError('distanceId','Required parameter requestParameters.distanceId was null or undefined when calling deleteDistance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/distances/{distanceId}`.replace(`{${"distanceId"}}`, encodeURIComponent(String(requestParameters.distanceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete distance
     * Delete distance
     */
    async deleteDistance(requestParameters: DeleteDistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDistanceRaw(requestParameters, initOverrides);
    }

    /**
     * Distances
     * Distances
     */
    async listDistancesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiDistance>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/distances`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiDistanceFromJSON));
    }

    /**
     * Distances
     * Distances
     */
    async listDistances(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiDistance>> {
        const response = await this.listDistancesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update distance
     * Update distance
     */
    async updateDistanceRaw(requestParameters: UpdateDistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiDistance>> {
        if (requestParameters.distanceId === null || requestParameters.distanceId === undefined) {
            throw new runtime.RequiredError('distanceId','Required parameter requestParameters.distanceId was null or undefined when calling updateDistance.');
        }

        if (requestParameters.apiDistanceUpdateInput === null || requestParameters.apiDistanceUpdateInput === undefined) {
            throw new runtime.RequiredError('apiDistanceUpdateInput','Required parameter requestParameters.apiDistanceUpdateInput was null or undefined when calling updateDistance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/distances/{distanceId}`.replace(`{${"distanceId"}}`, encodeURIComponent(String(requestParameters.distanceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDistanceUpdateInputToJSON(requestParameters.apiDistanceUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDistanceFromJSON(jsonValue));
    }

    /**
     * Update distance
     * Update distance
     */
    async updateDistance(requestParameters: UpdateDistanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiDistance> {
        const response = await this.updateDistanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
