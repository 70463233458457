import { ApiCategory } from '@antilooppi/public-api'
import i18next from 'i18next'

export const keywordTranslation = (category: ApiCategory) => {
  switch (i18next.language) {
    case 'en':
      return category.nameEn
  }
  return category.nameFi
}
