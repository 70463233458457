import * as React from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { getAuthConfig } from './authConfig'

const instance = new PublicClientApplication(getAuthConfig())

export const AuthProvider = (props: { children: React.ReactNode }) => {
  return <MsalProvider instance={instance}>{props.children}</MsalProvider>
}
