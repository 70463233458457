import { z } from 'zod'
import { ApiUserReservationDeleteInput, ApiUserReservationInput } from '../../generated/private-api'

export const UserReservationInputSchema: z.ZodType<ApiUserReservationInput> = z.object({
  email: z.string(),
  startDate: z.string(),
  endDate: z.string().optional(),
  spaceId: z.string(),
  propertyId: z.string(),
  exVatPrice: z.number(),
  vatPercent: z.number(),
  bookingReference: z.string(),
  cancellationSecret: z.string(),
  paymentOrInvoiceId: z.string(),
  paymentMethod: z.string(),
})

export const UserReservationDeleteInput: z.ZodType<ApiUserReservationDeleteInput> = z.object({
  userReservationId: z.string(),
})
