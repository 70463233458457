import Button from '../Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useContactForm } from '../../lib/useContactForm'
import { useRouter } from 'next/router'

const ContactUsButton = ({ className = '' }: { className?: string }) => {
  const { t } = useTranslation()

  const {
    query: { propertyId, spaceId },
  } = useRouter()
  const { showContactForm } = useContactForm(propertyId as string, spaceId as string)

  return (
    <Button
      text={t('contactUs')}
      size='reset'
      className={`py-2 px-6 font-serif text-2xl font-normal md:ml-2 md:py-1 md:text-xl ${className}`}
      onClick={() => showContactForm()}
    />
  )
}

export default ContactUsButton
