import { TFunction } from 'react-i18next'

export const resolveTimeUnitAbbreviated = (translate: TFunction, pricingType?: string) => {
  switch (pricingType) {
    case 'hour':
      return translate('timePeriodHourAbbr')
    case 'week':
      return translate('timePeriodWeekAbbr')
    case 'month':
      return translate('timePeriodMonthAbbr')
    default:
      return ''
  }
}

export const resolveTimeUnit = (translate: TFunction, pricingType?: string) => {
  switch (pricingType) {
    case 'hour':
      return translate('timePeriodHour')
    case 'week':
      return translate('timePeriodWeek')
    case 'month':
      return translate('timePeriodMonth')
    default:
      return ''
  }
}

const withPricingUnit = (price: string, translate: TFunction, pricingType?: string) => {
  const timeUnit = resolveTimeUnitAbbreviated(translate, pricingType)
  return `${price}${timeUnit ? ` / ${timeUnit}` : ''}`
}

export function formatAmountForDisplay(
  amount: number,
  currency: string,
  translate: TFunction,
  pricingType?: string
): string {
  const numberFormat = new Intl.NumberFormat(['fi-FI'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
  })
  const fractionFormat = new Intl.NumberFormat(['fi-FI'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
  })

  const formattedPrice = amount % 1 == 0 ? fractionFormat.format(amount) : numberFormat.format(amount)

  return withPricingUnit(formattedPrice, translate, pricingType)
}

export function formatAmount(amount: number, currency: string): number {
  const numberFormat = new Intl.NumberFormat(['fi-FI'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency = true
  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }
  return zeroDecimalCurrency ? amount : Math.round(amount * 100)
}
