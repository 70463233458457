/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiProductDisplayTypeUpdateInput
 */
export interface ApiProductDisplayTypeUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof ApiProductDisplayTypeUpdateInput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductDisplayTypeUpdateInput
     */
    productTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductDisplayTypeUpdateInput
     */
    nameFi: string;
    /**
     * 
     * @type {string}
     * @memberof ApiProductDisplayTypeUpdateInput
     */
    nameEn: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiProductDisplayTypeUpdateInput
     */
    reservable: boolean;
}

/**
 * Check if a given object implements the ApiProductDisplayTypeUpdateInput interface.
 */
export function instanceOfApiProductDisplayTypeUpdateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productTypeId" in value;
    isInstance = isInstance && "nameFi" in value;
    isInstance = isInstance && "nameEn" in value;
    isInstance = isInstance && "reservable" in value;

    return isInstance;
}

export function ApiProductDisplayTypeUpdateInputFromJSON(json: any): ApiProductDisplayTypeUpdateInput {
    return ApiProductDisplayTypeUpdateInputFromJSONTyped(json, false);
}

export function ApiProductDisplayTypeUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiProductDisplayTypeUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productTypeId': json['productTypeId'],
        'nameFi': json['nameFi'],
        'nameEn': json['nameEn'],
        'reservable': json['reservable'],
    };
}

export function ApiProductDisplayTypeUpdateInputToJSON(value?: ApiProductDisplayTypeUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productTypeId': value.productTypeId,
        'nameFi': value.nameFi,
        'nameEn': value.nameEn,
        'reservable': value.reservable,
    };
}

