import { z } from 'zod'
import { ApiDistanceCreateInput, ApiDistanceUpdateInput } from '../../generated/private-api'

export const DistanceCreateInputSchema: z.ZodType<ApiDistanceCreateInput> = z.object({
  nameFi: z.string(),
  nameEn: z.string(),
  iconUrl: z.string(),
})

export const DistanceUpdateInputSchema: z.ZodType<ApiDistanceUpdateInput> = z.object({
  id: z.string(),
  nameFi: z.string(),
  nameEn: z.string(),
  iconUrl: z.string(),
})
