import usePrevious from '../../lib/usePrevious'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

export type ToastTheme = 'success' | 'error'
export type AnimationState = 'from' | 'enter' | 'exit'

const Toast = ({
  id,
  message,
  theme = 'success',
  visible = false,
  onClick,
}: {
  id: string
  message: string
  theme?: ToastTheme
  visible?: boolean
  onClick?: () => void
}) => {
  const colorClasses = theme === 'success' ? 'bg-green' : 'bg-orange'

  const prevVisible = usePrevious(visible)

  const [animationState, setAnimationState] = useState<AnimationState>('from')

  useEffect(() => {
    if (prevVisible !== true && !visible) {
      setAnimationState('from')
    }
    if (prevVisible !== true && visible) {
      setAnimationState('enter')
    }
    if (prevVisible === true && !visible) {
      setAnimationState('exit')
    }
  }, [prevVisible, visible])

  const classes = classNames({
    'opacity-0': ['from', 'exit'].includes(animationState),
    'opacity-100': ['enter'].includes(animationState),
  })

  return (
    <div
      id={id}
      className={`pointer-events-auto opacity-0 transition-opacity duration-300 ${classes}`}
      onClick={onClick}>
      <div className={`rounded-full px-6 py-2 text-xl ${colorClasses}`}>{message}</div>
    </div>
  )
}

export default Toast
