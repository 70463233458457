import { FunctionComponent, SVGProps } from 'react'

const resolveSize = (size: 'small' | 'medium' | 'large' | 'full'): string => {
  switch (size) {
    case 'small':
      return 'w-[0.875rem] h-[0.875rem]'
    case 'medium':
      return 'w-[1.25rem] h-[1.25rem]'
    case 'large':
      return 'w-[1.5rem] h-[1.5rem]'
    case 'full':
      return 'w-full h-full'
    default:
      return ''
  }
}

const Icon = ({
  icon: Component,
  size = 'medium',
  className = '',
  title = '',
}: {
  icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>
  size?: 'small' | 'medium' | 'large' | 'full'
  className?: string
  title?: string
}) => {
  const sizeClasses = resolveSize(size)
  return <Component className={`${sizeClasses} ${className}`} title={title} />
}

export default Icon
