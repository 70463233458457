import { z } from 'zod'
import { ApiPayment } from '../../generated/public-api'

export const PaymentSchema: z.ZodType<ApiPayment> = z.object({
  id: z.string(),
  spaceId: z.string(),
  propertyId: z.string(),
  reservedHours: z.number(),
  termAccepted: z.boolean(),
  vatIncluded: z.number(),
  vatExcluded: z.number(),
  email: z.string(),
})
