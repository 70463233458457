/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDeletePdf,
  ApiPdf,
  ApiPdfInput,
} from '../models';
import {
    ApiDeletePdfFromJSON,
    ApiDeletePdfToJSON,
    ApiPdfFromJSON,
    ApiPdfToJSON,
    ApiPdfInputFromJSON,
    ApiPdfInputToJSON,
} from '../models';

export interface DeletePdfRequest {
    pdfId: string;
}

export interface PostPdfRequest {
    apiPdfInput: ApiPdfInput;
}

/**
 * 
 */
export class PdfsPrivateApi extends runtime.BaseAPI {

    /**
     * Delete PDF file from database and storage
     * Delete PDF file
     */
    async deletePdfRaw(requestParameters: DeletePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiDeletePdf>> {
        if (requestParameters.pdfId === null || requestParameters.pdfId === undefined) {
            throw new runtime.RequiredError('pdfId','Required parameter requestParameters.pdfId was null or undefined when calling deletePdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfs/{pdfId}`.replace(`{${"pdfId"}}`, encodeURIComponent(String(requestParameters.pdfId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDeletePdfFromJSON(jsonValue));
    }

    /**
     * Delete PDF file from database and storage
     * Delete PDF file
     */
    async deletePdf(requestParameters: DeletePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiDeletePdf> {
        const response = await this.deletePdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create PDF file to storage account and metadata to database
     * Create PDF file
     */
    async postPdfRaw(requestParameters: PostPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPdf>> {
        if (requestParameters.apiPdfInput === null || requestParameters.apiPdfInput === undefined) {
            throw new runtime.RequiredError('apiPdfInput','Required parameter requestParameters.apiPdfInput was null or undefined when calling postPdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pdfs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPdfInputToJSON(requestParameters.apiPdfInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPdfFromJSON(jsonValue));
    }

    /**
     * Create PDF file to storage account and metadata to database
     * Create PDF file
     */
    async postPdf(requestParameters: PostPdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPdf> {
        const response = await this.postPdfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update existing PDF mime types
     * Update existing PDF mime types
     */
    async updatePdfMimeTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/update-pdf-mime-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update existing PDF mime types
     * Update existing PDF mime types
     */
    async updatePdfMimeTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePdfMimeTypesRaw(initOverrides);
    }

}
