/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiCustomerDetails
 */
export interface ApiCustomerDetails {
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    businessId?: string;
    /**
     * captcha solution
     * @type {string}
     * @memberof ApiCustomerDetails
     */
    solution?: string;
}

/**
 * Check if a given object implements the ApiCustomerDetails interface.
 */
export function instanceOfApiCustomerDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "companyName" in value;

    return isInstance;
}

export function ApiCustomerDetailsFromJSON(json: any): ApiCustomerDetails {
    return ApiCustomerDetailsFromJSONTyped(json, false);
}

export function ApiCustomerDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCustomerDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
        'companyName': json['companyName'],
        'businessId': !exists(json, 'businessId') ? undefined : json['businessId'],
        'solution': !exists(json, 'solution') ? undefined : json['solution'],
    };
}

export function ApiCustomerDetailsToJSON(value?: ApiCustomerDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'companyName': value.companyName,
        'businessId': value.businessId,
        'solution': value.solution,
    };
}

