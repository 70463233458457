/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDistance,
  ApiDistrict,
  ApiImage,
  ApiProperty,
  ApiResponsibility,
  ApiService,
} from '../models';
import {
    ApiDistanceFromJSON,
    ApiDistanceToJSON,
    ApiDistrictFromJSON,
    ApiDistrictToJSON,
    ApiImageFromJSON,
    ApiImageToJSON,
    ApiPropertyFromJSON,
    ApiPropertyToJSON,
    ApiResponsibilityFromJSON,
    ApiResponsibilityToJSON,
    ApiServiceFromJSON,
    ApiServiceToJSON,
} from '../models';

export interface GetPropertyRequest {
    propertyId: string;
    include?: string;
}

export interface ListPropertiesRequest {
    maxPersons?: number;
    minPersons?: number;
    minArea?: number;
    maxArea?: number;
    startDate?: string;
    keywords?: string;
    productTypes?: string;
    published?: boolean;
    reservationStatus?: ListPropertiesReservationStatusEnum;
    include?: string;
}

export interface ListPropertyDistancesRequest {
    propertyId: string;
}

export interface ListPropertyDistrictsRequest {
    propertyId: string;
}

export interface ListPropertyImagesRequest {
    propertyId: string;
}

export interface ListPropertyResponsibilitiesRequest {
    propertyId: string;
}

export interface ListPropertyServicesRequest {
    propertyId: string;
}

/**
 * 
 */
export class PropertiesPublicApi extends runtime.BaseAPI {

    /**
     * Get property
     * Get property
     */
    async getPropertyRaw(requestParameters: GetPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiProperty>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling getProperty.');
        }

        const queryParameters: any = {};

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPropertyFromJSON(jsonValue));
    }

    /**
     * Get property
     * Get property
     */
    async getProperty(requestParameters: GetPropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiProperty> {
        const response = await this.getPropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List properties
     * List properties
     */
    async listPropertiesRaw(requestParameters: ListPropertiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiProperty>>> {
        const queryParameters: any = {};

        if (requestParameters.maxPersons !== undefined) {
            queryParameters['maxPersons'] = requestParameters.maxPersons;
        }

        if (requestParameters.minPersons !== undefined) {
            queryParameters['minPersons'] = requestParameters.minPersons;
        }

        if (requestParameters.minArea !== undefined) {
            queryParameters['minArea'] = requestParameters.minArea;
        }

        if (requestParameters.maxArea !== undefined) {
            queryParameters['maxArea'] = requestParameters.maxArea;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.keywords !== undefined) {
            queryParameters['keywords'] = requestParameters.keywords;
        }

        if (requestParameters.productTypes !== undefined) {
            queryParameters['productTypes'] = requestParameters.productTypes;
        }

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        if (requestParameters.reservationStatus !== undefined) {
            queryParameters['reservationStatus'] = requestParameters.reservationStatus;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiPropertyFromJSON));
    }

    /**
     * List properties
     * List properties
     */
    async listProperties(requestParameters: ListPropertiesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiProperty>> {
        const response = await this.listPropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List distances for property
     * List distances for property
     */
    async listPropertyDistancesRaw(requestParameters: ListPropertyDistancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiDistance>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling listPropertyDistances.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-distances/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiDistanceFromJSON));
    }

    /**
     * List distances for property
     * List distances for property
     */
    async listPropertyDistances(requestParameters: ListPropertyDistancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiDistance>> {
        const response = await this.listPropertyDistancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List districts for property
     * List districts for property
     */
    async listPropertyDistrictsRaw(requestParameters: ListPropertyDistrictsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiDistrict>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling listPropertyDistricts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-districts/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiDistrictFromJSON));
    }

    /**
     * List districts for property
     * List districts for property
     */
    async listPropertyDistricts(requestParameters: ListPropertyDistrictsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiDistrict>> {
        const response = await this.listPropertyDistrictsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List images for property
     * List images for property
     */
    async listPropertyImagesRaw(requestParameters: ListPropertyImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiImage>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling listPropertyImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-images/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiImageFromJSON));
    }

    /**
     * List images for property
     * List images for property
     */
    async listPropertyImages(requestParameters: ListPropertyImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiImage>> {
        const response = await this.listPropertyImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List responsibilities for property
     * List responsibilities for property
     */
    async listPropertyResponsibilitiesRaw(requestParameters: ListPropertyResponsibilitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiResponsibility>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling listPropertyResponsibilities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-responsibilities/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiResponsibilityFromJSON));
    }

    /**
     * List responsibilities for property
     * List responsibilities for property
     */
    async listPropertyResponsibilities(requestParameters: ListPropertyResponsibilitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiResponsibility>> {
        const response = await this.listPropertyResponsibilitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List services for property
     * List services for property
     */
    async listPropertyServicesRaw(requestParameters: ListPropertyServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiService>>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling listPropertyServices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/property-services/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiServiceFromJSON));
    }

    /**
     * List services for property
     * List services for property
     */
    async listPropertyServices(requestParameters: ListPropertyServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiService>> {
        const response = await this.listPropertyServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListPropertiesReservationStatusEnum = {
    Reserved: 'reserved',
    Free: 'free'
} as const;
export type ListPropertiesReservationStatusEnum = typeof ListPropertiesReservationStatusEnum[keyof typeof ListPropertiesReservationStatusEnum];
