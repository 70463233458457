/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiSpaceTranslationMap } from './ApiSpaceTranslationMap';
import {
    ApiSpaceTranslationMapFromJSON,
    ApiSpaceTranslationMapFromJSONTyped,
    ApiSpaceTranslationMapToJSON,
} from './ApiSpaceTranslationMap';

/**
 * 
 * @export
 * @interface ApiSpaceTranslations
 */
export interface ApiSpaceTranslations {
    [key: string]: ApiSpaceTranslationMap | any;
    /**
     * 
     * @type {ApiSpaceTranslationMap}
     * @memberof ApiSpaceTranslations
     */
    fi: ApiSpaceTranslationMap;
    /**
     * 
     * @type {ApiSpaceTranslationMap}
     * @memberof ApiSpaceTranslations
     */
    en?: ApiSpaceTranslationMap;
}

/**
 * Check if a given object implements the ApiSpaceTranslations interface.
 */
export function instanceOfApiSpaceTranslations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fi" in value;

    return isInstance;
}

export function ApiSpaceTranslationsFromJSON(json: any): ApiSpaceTranslations {
    return ApiSpaceTranslationsFromJSONTyped(json, false);
}

export function ApiSpaceTranslationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiSpaceTranslations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'fi': ApiSpaceTranslationMapFromJSON(json['fi']),
        'en': !exists(json, 'en') ? undefined : ApiSpaceTranslationMapFromJSON(json['en']),
    };
}

export function ApiSpaceTranslationsToJSON(value?: ApiSpaceTranslations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'fi': ApiSpaceTranslationMapToJSON(value.fi),
        'en': ApiSpaceTranslationMapToJSON(value.en),
    };
}

