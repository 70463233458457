import { z } from 'zod'
import { ApiPdfInput } from '../../generated/private-api'

export const PdfInputSchema: z.ZodType<ApiPdfInput> = z.object({
  id: z.string().optional(),
  spaceId: z.string().optional(),
  propertyId: z.string(),
  filename: z.string(),
  pdfdata: z.string(),
  description: z.string().optional(),
  type: z.number(),
  order: z.number().optional(),
})
