/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiLeadInput,
  ApiOppoInput,
} from '../models';
import {
    ApiLeadInputFromJSON,
    ApiLeadInputToJSON,
    ApiOppoInputFromJSON,
    ApiOppoInputToJSON,
} from '../models';

export interface SendLeadRequest {
    apiLeadInput: ApiLeadInput;
}

export interface SendOppoRequest {
    apiOppoInput: ApiOppoInput;
}

/**
 * 
 */
export class CRMPublicApi extends runtime.BaseAPI {

    /**
     * Send sales lead to CRM
     * Send lead
     */
    async sendLeadRaw(requestParameters: SendLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.apiLeadInput === null || requestParameters.apiLeadInput === undefined) {
            throw new runtime.RequiredError('apiLeadInput','Required parameter requestParameters.apiLeadInput was null or undefined when calling sendLead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crm-leads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiLeadInputToJSON(requestParameters.apiLeadInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Send sales lead to CRM
     * Send lead
     */
    async sendLead(requestParameters: SendLeadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.sendLeadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send sales opportunity to CRM
     * Send opportunity
     */
    async sendOppoRaw(requestParameters: SendOppoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.apiOppoInput === null || requestParameters.apiOppoInput === undefined) {
            throw new runtime.RequiredError('apiOppoInput','Required parameter requestParameters.apiOppoInput was null or undefined when calling sendOppo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crm-oppos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiOppoInputToJSON(requestParameters.apiOppoInput),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Send sales opportunity to CRM
     * Send opportunity
     */
    async sendOppo(requestParameters: SendOppoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.sendOppoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
