/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiSpaceTranslationMap } from './ApiSpaceTranslationMap';
import {
    ApiSpaceTranslationMapFromJSON,
    ApiSpaceTranslationMapFromJSONTyped,
    ApiSpaceTranslationMapToJSON,
} from './ApiSpaceTranslationMap';

/**
 * 
 * @export
 * @interface ApiSpaceUpdateInputTranslations
 */
export interface ApiSpaceUpdateInputTranslations {
    /**
     * 
     * @type {ApiSpaceTranslationMap}
     * @memberof ApiSpaceUpdateInputTranslations
     */
    fi: ApiSpaceTranslationMap;
    /**
     * 
     * @type {ApiSpaceTranslationMap}
     * @memberof ApiSpaceUpdateInputTranslations
     */
    en?: ApiSpaceTranslationMap;
}

/**
 * Check if a given object implements the ApiSpaceUpdateInputTranslations interface.
 */
export function instanceOfApiSpaceUpdateInputTranslations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fi" in value;

    return isInstance;
}

export function ApiSpaceUpdateInputTranslationsFromJSON(json: any): ApiSpaceUpdateInputTranslations {
    return ApiSpaceUpdateInputTranslationsFromJSONTyped(json, false);
}

export function ApiSpaceUpdateInputTranslationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiSpaceUpdateInputTranslations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': ApiSpaceTranslationMapFromJSON(json['fi']),
        'en': !exists(json, 'en') ? undefined : ApiSpaceTranslationMapFromJSON(json['en']),
    };
}

export function ApiSpaceUpdateInputTranslationsToJSON(value?: ApiSpaceUpdateInputTranslations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': ApiSpaceTranslationMapToJSON(value.fi),
        'en': ApiSpaceTranslationMapToJSON(value.en),
    };
}

