/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiEmployeeUpdateInput
 */
export interface ApiEmployeeUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeUpdateInput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeUpdateInput
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeUpdateInput
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeUpdateInput
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeUpdateInput
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiEmployeeUpdateInput
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the ApiEmployeeUpdateInput interface.
 */
export function instanceOfApiEmployeeUpdateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function ApiEmployeeUpdateInputFromJSON(json: any): ApiEmployeeUpdateInput {
    return ApiEmployeeUpdateInputFromJSONTyped(json, false);
}

export function ApiEmployeeUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiEmployeeUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    };
}

export function ApiEmployeeUpdateInputToJSON(value?: ApiEmployeeUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'roleName': value.roleName,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
    };
}

