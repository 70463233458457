/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiBookingCalendar
 */
export interface ApiBookingCalendar {
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    customerFirstname: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    customerLastname: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    customerCompany: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    customerEmail: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    customerPhone: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    propertyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    staffId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBookingCalendar
     */
    businessId: string;
}

/**
 * Check if a given object implements the ApiBookingCalendar interface.
 */
export function instanceOfApiBookingCalendar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customerFirstname" in value;
    isInstance = isInstance && "customerLastname" in value;
    isInstance = isInstance && "customerCompany" in value;
    isInstance = isInstance && "customerEmail" in value;
    isInstance = isInstance && "customerPhone" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "staffId" in value;
    isInstance = isInstance && "businessId" in value;

    return isInstance;
}

export function ApiBookingCalendarFromJSON(json: any): ApiBookingCalendar {
    return ApiBookingCalendarFromJSONTyped(json, false);
}

export function ApiBookingCalendarFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiBookingCalendar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'customerFirstname': json['customerFirstname'],
        'customerLastname': json['customerLastname'],
        'customerCompany': json['customerCompany'],
        'customerEmail': json['customerEmail'],
        'customerPhone': json['customerPhone'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'startDate': json['startDate'],
        'endDate': json['endDate'],
        'staffId': json['staffId'],
        'businessId': json['businessId'],
    };
}

export function ApiBookingCalendarToJSON(value?: ApiBookingCalendar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerFirstname': value.customerFirstname,
        'customerLastname': value.customerLastname,
        'customerCompany': value.customerCompany,
        'customerEmail': value.customerEmail,
        'customerPhone': value.customerPhone,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'staffId': value.staffId,
        'businessId': value.businessId,
    };
}

