/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiPropertyTranslationMap } from './ApiPropertyTranslationMap';
import {
    ApiPropertyTranslationMapFromJSON,
    ApiPropertyTranslationMapFromJSONTyped,
    ApiPropertyTranslationMapToJSON,
} from './ApiPropertyTranslationMap';

/**
 * 
 * @export
 * @interface ApiPropertyLobbyInfos
 */
export interface ApiPropertyLobbyInfos {
    /**
     * 
     * @type {ApiPropertyTranslationMap}
     * @memberof ApiPropertyLobbyInfos
     */
    fi: ApiPropertyTranslationMap;
    /**
     * 
     * @type {ApiPropertyTranslationMap}
     * @memberof ApiPropertyLobbyInfos
     */
    en?: ApiPropertyTranslationMap;
}

/**
 * Check if a given object implements the ApiPropertyLobbyInfos interface.
 */
export function instanceOfApiPropertyLobbyInfos(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fi" in value;

    return isInstance;
}

export function ApiPropertyLobbyInfosFromJSON(json: any): ApiPropertyLobbyInfos {
    return ApiPropertyLobbyInfosFromJSONTyped(json, false);
}

export function ApiPropertyLobbyInfosFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPropertyLobbyInfos {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fi': ApiPropertyTranslationMapFromJSON(json['fi']),
        'en': !exists(json, 'en') ? undefined : ApiPropertyTranslationMapFromJSON(json['en']),
    };
}

export function ApiPropertyLobbyInfosToJSON(value?: ApiPropertyLobbyInfos | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fi': ApiPropertyTranslationMapToJSON(value.fi),
        'en': ApiPropertyTranslationMapToJSON(value.en),
    };
}

