import getConfig from 'next/config'

export type AntilooppiServerRuntimeConfig = Record<string, never>
export type AntilooppiPublicRuntimeConfig = { deployEnv: string }
export type AntilooppiRuntimeConfig = {
  serverRuntimeConfig: AntilooppiServerRuntimeConfig
  publicRuntimeConfig: AntilooppiPublicRuntimeConfig
}

const runtimeConfig = getConfig() as AntilooppiRuntimeConfig

export const getServerRuntimeConfig = (): AntilooppiServerRuntimeConfig => {
  return runtimeConfig.serverRuntimeConfig
}
export const getPublicRuntimeConfig = (): AntilooppiPublicRuntimeConfig => {
  return runtimeConfig.publicRuntimeConfig
}
