import React, { MouseEventHandler } from 'react'
import Link from 'next/link'

const NavBarLink = ({
  title,
  href = '#',
  active = false,
  onClick,
  className = '',
}: {
  title: string
  href?: string
  active?: boolean
  onClick?: MouseEventHandler<HTMLAnchorElement>
  className?: string
}) => {
  return (
    <Link href={href} legacyBehavior scroll={false}>
      <a
        onClick={onClick}
        className={`items-center justify-center whitespace-nowrap rounded px-0 py-2 underline-offset-[5px] hover:underline md:inline-flex md:w-auto md:px-2 lg:px-3 ${className} ${
          active ? 'underline' : ''
        }`}>
        {title}
      </a>
    </Link>
  )
}

export default NavBarLink
