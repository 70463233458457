import { clear, getItem, removeItem, setItem } from './storage'

const getStorage = (): Storage | null => (typeof window !== 'undefined' ? sessionStorage : null)

export function getFromSessionStorage<T = string>(keyName: string): T | undefined
export function getFromSessionStorage<T = string>(keyName: string, defaultValue: T): T
export function getFromSessionStorage<T = string>(keyName: string, defaultValue?: T): T | undefined {
  return getItem(getStorage(), keyName, defaultValue)
}

export const setInSessionStorage = <T>(keyName: string, value: T): void => {
  setItem(getStorage(), keyName, value)
}

export const removeFromSessionStorage = (keyName: string): void => {
  removeItem(getStorage(), keyName)
}

export const clearSessionStorage = (): void => {
  clear(getStorage())
}
