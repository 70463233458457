/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiSpace,
} from '../models';
import {
    ApiSpaceFromJSON,
    ApiSpaceToJSON,
} from '../models';

export interface GetSpaceRequest {
    spaceId: string;
}

export interface ListSpacesRequest {
    propertyId?: string;
    minPersons?: number;
    maxPersons?: number;
    minArea?: number;
    maxArea?: number;
    startDate?: string;
    keywords?: string;
    productTypes?: string;
    published?: boolean;
    reservationStatus?: ListSpacesReservationStatusEnum;
    highlighted?: boolean;
    include?: string;
}

/**
 * 
 */
export class SpacesPublicApi extends runtime.BaseAPI {

    /**
     * Get space
     * Get space
     */
    async getSpaceRaw(requestParameters: GetSpaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiSpace>> {
        if (requestParameters.spaceId === null || requestParameters.spaceId === undefined) {
            throw new runtime.RequiredError('spaceId','Required parameter requestParameters.spaceId was null or undefined when calling getSpace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/spaces/{spaceId}`.replace(`{${"spaceId"}}`, encodeURIComponent(String(requestParameters.spaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSpaceFromJSON(jsonValue));
    }

    /**
     * Get space
     * Get space
     */
    async getSpace(requestParameters: GetSpaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiSpace> {
        const response = await this.getSpaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List spaces
     * List spaces
     */
    async listSpacesRaw(requestParameters: ListSpacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiSpace>>> {
        const queryParameters: any = {};

        if (requestParameters.propertyId !== undefined) {
            queryParameters['propertyId'] = requestParameters.propertyId;
        }

        if (requestParameters.minPersons !== undefined) {
            queryParameters['minPersons'] = requestParameters.minPersons;
        }

        if (requestParameters.maxPersons !== undefined) {
            queryParameters['maxPersons'] = requestParameters.maxPersons;
        }

        if (requestParameters.minArea !== undefined) {
            queryParameters['minArea'] = requestParameters.minArea;
        }

        if (requestParameters.maxArea !== undefined) {
            queryParameters['maxArea'] = requestParameters.maxArea;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.keywords !== undefined) {
            queryParameters['keywords'] = requestParameters.keywords;
        }

        if (requestParameters.productTypes !== undefined) {
            queryParameters['productTypes'] = requestParameters.productTypes;
        }

        if (requestParameters.published !== undefined) {
            queryParameters['published'] = requestParameters.published;
        }

        if (requestParameters.reservationStatus !== undefined) {
            queryParameters['reservationStatus'] = requestParameters.reservationStatus;
        }

        if (requestParameters.highlighted !== undefined) {
            queryParameters['highlighted'] = requestParameters.highlighted;
        }

        if (requestParameters.include !== undefined) {
            queryParameters['include'] = requestParameters.include;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/spaces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiSpaceFromJSON));
    }

    /**
     * List spaces
     * List spaces
     */
    async listSpaces(requestParameters: ListSpacesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiSpace>> {
        const response = await this.listSpacesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ListSpacesReservationStatusEnum = {
    Reserved: 'reserved',
    Free: 'free'
} as const;
export type ListSpacesReservationStatusEnum = typeof ListSpacesReservationStatusEnum[keyof typeof ListSpacesReservationStatusEnum];
