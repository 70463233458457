import { useQuery } from '@tanstack/react-query'
import { ApiError, ApiProductType } from '@antilooppi/public-api'
import { usePublicApi } from '../usePublicApi'
import { useAuth } from '../auth/useAuth'

// Product types, internal to Antilooppi (as opposed to product display types)
export const useProductTypes = () => {
  const { auth } = useAuth()
  const { productTypesApi } = usePublicApi(auth.user)
  return useQuery<ApiProductType[], ApiError>([auth?.user?.username, 'product-types'], () =>
    productTypesApi.listProductTypes()
  )
}
