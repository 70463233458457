import { ReactNode } from 'react'
import { Portal } from '../Portal'

const ToastContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Portal wrapperId='toast-container'>
      <div className='pointer-events-none fixed top-0 left-0 z-50 mt-28 flex h-full w-full flex-col items-center space-y-4'>
        {children}
      </div>
    </Portal>
  )
}

export default ToastContainer
