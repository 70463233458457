/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiOpeningHours
 */
export interface ApiOpeningHours {
    /**
     * 
     * @type {string}
     * @memberof ApiOpeningHours
     */
    opened: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOpeningHours
     */
    closed: string;
}

/**
 * Check if a given object implements the ApiOpeningHours interface.
 */
export function instanceOfApiOpeningHours(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "opened" in value;
    isInstance = isInstance && "closed" in value;

    return isInstance;
}

export function ApiOpeningHoursFromJSON(json: any): ApiOpeningHours {
    return ApiOpeningHoursFromJSONTyped(json, false);
}

export function ApiOpeningHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiOpeningHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'opened': json['opened'],
        'closed': json['closed'],
    };
}

export function ApiOpeningHoursToJSON(value?: ApiOpeningHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'opened': value.opened,
        'closed': value.closed,
    };
}

