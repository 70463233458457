import { ListSpacesRequest } from '@antilooppi/public-api'
import { PropertyInclude } from './property-filters'

export type SpaceFilter = {
  persons?: { min?: number; max?: number }
  area?: { min?: number; max?: number }
  startDate?: Date
  keywords?: string[]
  productTypes?: string[]
  published?: boolean
  reservationStatus?: 'free' | 'reserved'
  highlighted?: boolean
}

export const makeListSpacesRequest = (filter: SpaceFilter = {}, include: PropertyInclude[] = []): ListSpacesRequest => {
  const params: ListSpacesRequest = {}
  if (filter.persons?.min != null) {
    params.minPersons = filter.persons?.min
  }
  if (filter.persons?.max != null) {
    params.maxPersons = filter.persons?.max
  }
  if (filter.area?.min != null) {
    params.minArea = filter.area?.min
  }
  if (filter.area?.max != null) {
    params.maxArea = filter.area?.max
  }
  if (filter.startDate != null) {
    params.startDate = new Date(filter.startDate)?.toISOString()
  }
  if (filter.keywords != null) {
    params.keywords = filter.keywords.join(',')
  }
  if (filter.productTypes != null) {
    params.productTypes = filter.productTypes.join(',')
  }
  if (filter.published != null) {
    params.published = filter.published
  }
  if (filter.reservationStatus != null) {
    params.reservationStatus = filter.reservationStatus
  }
  if (filter.highlighted != null) {
    params.highlighted = filter.highlighted
  }
  if (include != null && include.length > 0) {
    params.include = include.join(',')
  }
  return params
}

export const isFilterEmpty = (filter: SpaceFilter): boolean => {
  return Object.entries(filter).every(([_key, value]) => value === undefined)
}
