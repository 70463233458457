/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiAdminSettings,
  ApiAdminSettingsInput,
  ApiAgentMaterial,
  ApiAgentMaterialCreateInput,
  ApiAgentMaterialDeleteInput,
} from '../models';
import {
    ApiAdminSettingsFromJSON,
    ApiAdminSettingsToJSON,
    ApiAdminSettingsInputFromJSON,
    ApiAdminSettingsInputToJSON,
    ApiAgentMaterialFromJSON,
    ApiAgentMaterialToJSON,
    ApiAgentMaterialCreateInputFromJSON,
    ApiAgentMaterialCreateInputToJSON,
    ApiAgentMaterialDeleteInputFromJSON,
    ApiAgentMaterialDeleteInputToJSON,
} from '../models';

export interface CreateAgentMaterialRequest {
    apiAgentMaterialCreateInput: ApiAgentMaterialCreateInput;
}

export interface DeleteAgentMaterialRequest {
    apiAgentMaterialDeleteInput: ApiAgentMaterialDeleteInput;
}

export interface SaveAdminSettingsRequest {
    apiAdminSettingsInput: ApiAdminSettingsInput;
}

/**
 * 
 */
export class AdminSettingsPrivateApi extends runtime.BaseAPI {

    /**
     * Create real estage agent material XLSX file using `cutoffDate` as a \'no earlier than\' parameter
     */
    async createAgentMaterialRaw(requestParameters: CreateAgentMaterialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAgentMaterial>> {
        if (requestParameters.apiAgentMaterialCreateInput === null || requestParameters.apiAgentMaterialCreateInput === undefined) {
            throw new runtime.RequiredError('apiAgentMaterialCreateInput','Required parameter requestParameters.apiAgentMaterialCreateInput was null or undefined when calling createAgentMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/agent-material`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAgentMaterialCreateInputToJSON(requestParameters.apiAgentMaterialCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAgentMaterialFromJSON(jsonValue));
    }

    /**
     * Create real estage agent material XLSX file using `cutoffDate` as a \'no earlier than\' parameter
     */
    async createAgentMaterial(requestParameters: CreateAgentMaterialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAgentMaterial> {
        const response = await this.createAgentMaterialRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete agent material
     * Delete agent material
     */
    async deleteAgentMaterialRaw(requestParameters: DeleteAgentMaterialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.apiAgentMaterialDeleteInput === null || requestParameters.apiAgentMaterialDeleteInput === undefined) {
            throw new runtime.RequiredError('apiAgentMaterialDeleteInput','Required parameter requestParameters.apiAgentMaterialDeleteInput was null or undefined when calling deleteAgentMaterial.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/agent-material`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAgentMaterialDeleteInputToJSON(requestParameters.apiAgentMaterialDeleteInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete agent material
     * Delete agent material
     */
    async deleteAgentMaterial(requestParameters: DeleteAgentMaterialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAgentMaterialRaw(requestParameters, initOverrides);
    }

    /**
     * Save admin settings
     * Save admin settings
     */
    async saveAdminSettingsRaw(requestParameters: SaveAdminSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiAdminSettings>> {
        if (requestParameters.apiAdminSettingsInput === null || requestParameters.apiAdminSettingsInput === undefined) {
            throw new runtime.RequiredError('apiAdminSettingsInput','Required parameter requestParameters.apiAdminSettingsInput was null or undefined when calling saveAdminSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiAdminSettingsInputToJSON(requestParameters.apiAdminSettingsInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiAdminSettingsFromJSON(jsonValue));
    }

    /**
     * Save admin settings
     * Save admin settings
     */
    async saveAdminSettings(requestParameters: SaveAdminSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiAdminSettings> {
        const response = await this.saveAdminSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
