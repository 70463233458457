import App, { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from '../lib/auth/authProvider'
import { ProtectedRoute } from '../lib/auth/protectedRoute'
import '../i18n/i18next'
import '../css/styles.css'
import ErrorBoundary from '../components/ErrorBoundary'
import { SidebarProvider } from '../lib/useSidebar'
import { ToastProvider } from '../lib/useToast'

const queryClient = new QueryClient({ defaultOptions: { queries: { cacheTime: 5 * 60 * 1000 } } })

const AntilooppiApp = ({ Component, pageProps }: AppProps) => {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <ProtectedRoute>
          <Head>
            <title>Spaces - Antilooppi</title>
            <link rel='shortcut icon' href='/favicon.png' />
          </Head>
          <main className='app bg-offwhite'>
            <ErrorBoundary>
              <ToastProvider>
                <SidebarProvider>
                  <Component {...pageProps} />
                </SidebarProvider>
              </ToastProvider>
            </ErrorBoundary>
          </main>
        </ProtectedRoute>
      </QueryClientProvider>
    </AuthProvider>
  )
}

AntilooppiApp.getInitialProps = async (context: AppContext) => {
  const ctx = await App.getInitialProps(context)

  return { ...ctx }
}

export default AntilooppiApp
