import { formatISO, parseISO } from 'date-fns'

export const isISODate = (value: string): boolean => {
  return !isNaN(parseISO(value).valueOf())
}

export const replacer = (key: string, value: unknown): unknown => {
  if (value instanceof Date) {
    return formatISO(value)
  }
  return value
}

export const reviver = (key: string, value: string): unknown => {
  if (isISODate(value)) {
    return parseISO(value)
  }
  return value
}

export function getItem<T>(storage: Storage | null, keyName: string): T | undefined
export function getItem<T>(storage: Storage | null, keyName: string, defaultValue: T): T
export function getItem<T>(storage: Storage | null, keyName: string, defaultValue?: T): T | undefined {
  const value = storage != null && storage.getItem(keyName)
  return value && value !== 'undefined' ? (JSON.parse(value, reviver) as T) : defaultValue
}

export const setItem = <T>(storage: Storage | null, keyName: string, value: T): void => {
  storage != null && storage.setItem(keyName, JSON.stringify(value, replacer))
}

export const removeItem = (storage: Storage | null, keyName: string): void => {
  storage != null && storage.removeItem(keyName)
}

export const clear = (storage: Storage | null): void => {
  storage != null && storage.clear()
}
