import { LeadInputSchema } from '@antilooppi/api'
import { ApiLeadInput, ApiProductType, ApiProperty, ApiSpace } from '@antilooppi/public-api'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { KeyboardEventHandler, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AntilooppiLogo } from '../assets/antilooppi-logo.svg'
import { useCreateLead } from '../lib/api/leads'
import { useProductTypes } from '../lib/api/product-types'
import { iconArrowLeft, iconArrowRight } from '../lib/icons/icons'
import { useToast } from '../lib/useToast'
import SpaceHeaderRow from './Booker/SpaceHeaderRow'
import Button from './Button'
import Captcha from './Captcha'
import ErrorLabel from './ErrorLabel'
import Icon from './Icon'
import Input from './Input'
import Spinner from './Spinner'
import TextArea from './TextArea'

const ContactForm = ({
  property,
  space,
  onComplete,
}: {
  property?: ApiProperty
  space?: ApiSpace
  onComplete: () => void
}) => {
  const { i18n, t } = useTranslation()
  const createLeadMutation = useCreateLead()
  const { showToast } = useToast()
  const { data: allProductTypes } = useProductTypes()
  const [selectedProductType, setSelectedProductType] = useState<ApiProductType>()

  const findProductType = (value: string): ApiProductType | undefined => {
    const foundProductType = allProductTypes?.find((productType) => productType.value === value)
    console.log('foundProductType:', foundProductType)
    return foundProductType
  }

  const { register, handleSubmit, formState, reset, setValue, trigger } = useForm<ApiLeadInput>({
    resolver: zodResolver(LeadInputSchema),
    mode: 'onTouched',
  })

  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === 'text' &&
      ['Enter', 'NumpadEnter'].includes(event.key)
    ) {
      event.preventDefault()
    }
  }

  const onCaptchaDone = useCallback(
    (solution: string) => {
      // Captcha was solved. The form can be submitted.
      setValue('solution', solution)
      trigger('solution')
    },
    [setValue, trigger]
  )

  const onCaptchaError = useCallback((error: Error) => {
    // There was an error when trying to solve the Captcha.
    console.log(error)
  }, [])

  const onSubmit = async (data: ApiLeadInput) => {
    try {
      const leadData: ApiLeadInput = {
        ...data,
        productDisplayTypes:
          space?.productDisplayTypes && space?.productDisplayTypes.length > 0
            ? space.productDisplayTypes.map((pdt) => pdt.nameFi)
            : undefined,
        productType: (space?.productType?.value as string) ?? (selectedProductType?.value as string),
        propertyId: property?.id,
        propertyName: property?.displayName,
        spaceId: space?.id,
        spaceName: space?.displayName,
      }

      await createLeadMutation.mutateAsync(leadData)
      showToast(t('contactRequestSent'))
      reset()
      onComplete()
    } catch (error) {
      showToast(t('failedToSendContactRequest'), { theme: 'error' })
      console.error(error)
    }
  }

  return (
    <div>
      <div className='my-5 flex items-center justify-between'>
        <div className='p2 invisible'>
          <Icon icon={iconArrowLeft} size='large' />
        </div>
        <div className='h-[27px] w-[31px] md:h-[53px] md:w-[60px]'>
          <AntilooppiLogo width='100%' height='100%' />
        </div>
        <div className='p2 invisible'>
          <Icon icon={iconArrowRight} size='large' />
        </div>
      </div>

      <h2 className='mb-8 text-center'>{t('contactRequest')}</h2>
      {space && property && <SpaceHeaderRow space={space} property={property} showPrice={false} />}

      <div className='w-full py-4'>
        <h5 className='text-center'>{t('enterFollowingInformation')}</h5>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
        <div className='space-y-2'>
          <div className='relative'>
            <Input fieldName='firstName' label={t('firstName')} register={register} required />
            <ErrorLabel message={formState.errors.firstName?.message} />
          </div>

          <div className='relative'>
            <Input fieldName='lastName' label={t('lastName')} register={register} required />
            <ErrorLabel message={formState.errors.lastName?.message} />
          </div>

          <div className='relative'>
            <Input fieldName='jobTitle' label={t('jobTitle')} register={register} required />
            <ErrorLabel message={formState.errors.jobTitle?.message} />
          </div>

          <div className='relative'>
            <Input fieldName='companyName' label={t('organisation')} register={register} required />
            <ErrorLabel message={formState.errors.companyName?.message} />
          </div>

          <div className='relative'>
            <Input fieldName='email' label={t('email')} register={register} required />
            <ErrorLabel message={formState.errors.email?.message} />
          </div>

          <div className='relative'>
            <Input fieldName='phoneNumber' label={t('phoneNumber')} register={register} required />
            <ErrorLabel message={formState.errors.phoneNumber?.message} />
          </div>

          <div className='relative'>
            <Input
              fieldName='businessId'
              label={t('businessId')}
              register={register}
              registerOptions={{ pattern: /^\d{6,7}-\d$/ }}
              required
            />
            <ErrorLabel message={formState.errors.businessId?.message} />
          </div>

          <div className='relative'>
            <TextArea fieldName='description' label={t('freeText')} register={register} rows={3} />
            <ErrorLabel message={formState.errors.description?.message} />
          </div>

          {!space?.productType && (
            <div className='relative'>
              <label className='text-xl' htmlFor='productType'>
                {t('productTypeInterest')}
              </label>
              <div className='relative'>
                <select
                  {...register('productType', { required: true })}
                  className='w-full text-lg'
                  onChange={(event) => {
                    console.log('onChange():', event.target.value)
                    setSelectedProductType(findProductType(event.target.value))
                  }}>
                  <option value='' disabled selected>
                    {t('chooseProductType')}
                  </option>
                  {allProductTypes?.map((productType) => {
                    const title =
                      productType.value === 'pool_meet'
                        ? t('meetingRoomsTitle')
                        : i18n.language === 'fi'
                        ? productType.nameFi
                        : productType.nameEn
                    return (
                      <option value={productType.value} key={productType.value}>
                        {title}
                      </option>
                    )
                  })}
                </select>
              </div>
              <ErrorLabel message={formState.errors.productType?.message} />
            </div>
          )}
        </div>
        <div className='relative'>
          <Input fieldName='solution' label='' type='hidden' register={register} />
        </div>

        <div className='m-8 flex justify-center'>
          <Captcha doneCallback={onCaptchaDone} errorCallback={onCaptchaError} />
        </div>

        {formState.isSubmitting ? (
          <div className='p-8'>
            <Spinner />
          </div>
        ) : (
          <div className='mt-8 flex w-full gap-4'>
            <Button text={`${t('cancel')} \u2192`} className='w-full text-center' onClick={() => onComplete()} />
            <Button
              type='submit'
              text={`${t('sendQuery')} \u2192`}
              className='w-full text-center'
              disabled={!formState.isDirty || !formState.isValid}
            />
          </div>
        )}
      </form>
    </div>
  )
}

export default ContactForm
