import { z } from 'zod'
import { ApiInvoiceCustomerDomainCreateInput, ApiInvoiceCustomerDomainUpdateInput } from '../../generated/private-api'

export const InvoiceCustomerDomainCreateInputSchema: z.ZodType<ApiInvoiceCustomerDomainCreateInput> = z.object({
  domain: z.string(),
  enabled: z.boolean(),
})

export const InvoiceCustomerDomainUpdateInputSchema: z.ZodType<ApiInvoiceCustomerDomainUpdateInput> = z.object({
  id: z.string(),
  domain: z.string(),
  enabled: z.boolean(),
})
