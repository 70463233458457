/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiIcon,
  ApiIconInput,
} from '../models';
import {
    ApiIconFromJSON,
    ApiIconToJSON,
    ApiIconInputFromJSON,
    ApiIconInputToJSON,
} from '../models';

export interface DeleteIconRequest {
    iconUrl: string;
}

export interface PostIconRequest {
    apiIconInput: ApiIconInput;
}

/**
 * 
 */
export class IconsPrivateApi extends runtime.BaseAPI {

    /**
     * Delete icon from storage
     * Delete icon
     */
    async deleteIconRaw(requestParameters: DeleteIconRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.iconUrl === null || requestParameters.iconUrl === undefined) {
            throw new runtime.RequiredError('iconUrl','Required parameter requestParameters.iconUrl was null or undefined when calling deleteIcon.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/icons/{iconUrl}`.replace(`{${"iconUrl"}}`, encodeURIComponent(String(requestParameters.iconUrl))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete icon from storage
     * Delete icon
     */
    async deleteIcon(requestParameters: DeleteIconRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteIconRaw(requestParameters, initOverrides);
    }

    /**
     * Create icon to storage account and metadata to database
     * Create icon
     */
    async postIconRaw(requestParameters: PostIconRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiIcon>> {
        if (requestParameters.apiIconInput === null || requestParameters.apiIconInput === undefined) {
            throw new runtime.RequiredError('apiIconInput','Required parameter requestParameters.apiIconInput was null or undefined when calling postIcon.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/icons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiIconInputToJSON(requestParameters.apiIconInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiIconFromJSON(jsonValue));
    }

    /**
     * Create icon to storage account and metadata to database
     * Create icon
     */
    async postIcon(requestParameters: PostIconRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiIcon> {
        const response = await this.postIconRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
