/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPdfInput
 */
export interface ApiPdfInput {
    /**
     * 
     * @type {string}
     * @memberof ApiPdfInput
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPdfInput
     */
    spaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPdfInput
     */
    propertyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPdfInput
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPdfInput
     */
    pdfdata: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPdfInput
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPdfInput
     */
    type: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPdfInput
     */
    pdforder?: number;
}

/**
 * Check if a given object implements the ApiPdfInput interface.
 */
export function instanceOfApiPdfInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "pdfdata" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ApiPdfInputFromJSON(json: any): ApiPdfInput {
    return ApiPdfInputFromJSONTyped(json, false);
}

export function ApiPdfInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPdfInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'spaceId': !exists(json, 'spaceId') ? undefined : json['spaceId'],
        'propertyId': json['propertyId'],
        'filename': json['filename'],
        'pdfdata': json['pdfdata'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': json['type'],
        'pdforder': !exists(json, 'pdforder') ? undefined : json['pdforder'],
    };
}

export function ApiPdfInputToJSON(value?: ApiPdfInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'filename': value.filename,
        'pdfdata': value.pdfdata,
        'description': value.description,
        'type': value.type,
        'pdforder': value.pdforder,
    };
}

