export enum PropertyIncludes {
  ALL_SPACES = 'allSpaces',
  PUBLISHED_ONLY = 'publishedOnly',
  FREE_SPACES_ONLY = 'freeSpacesOnly',
  FIRST_PROPERTY_IMAGE_ONLY = 'firstPropertyImageOnly',
  FIRST_SPACE_IMAGE_ONLY = 'firstSpaceImageOnly',
  RESERVED_PUBLISHED_SPACES = 'reservedPublishedSpaces',
  NO_PROPERTY_IMAGES = 'noPropertyImages',
  NO_SPACE_IMAGES = 'noSpaceImages',
  NO_IMAGES = 'noImages',
  NO_PDFS = 'noPdfs',
  NO_PRODUCT_TYPE = 'noProductType',
  NO_PRODUCT_DISPLAY_TYPES = 'noProductDisplayTypes',
  NO_RESPONSIBILITIES = 'noResponsibilities',
  NO_SERVICES = 'noServices',
  NO_DISTANCES = 'noDistances',
  NO_DISTRICTS = 'noDistricts',
  NO_HALTIA = 'noHaltia',
  NO_OPENING_HOURS = 'noOpeningHours',
  NO_KEYWORDS = 'noKeywords',
  NO_PROPERTY_SPACES = 'noPropertySpaces',
  ADMIN_ALSO_HIDDEN_PROPERTIES = 'adminAlsoHiddenProperties',
  ADMIN_EMPLOYEES = 'adminEmployees',
}

export const IncludeBasicDataOnly = [
  PropertyIncludes.NO_IMAGES,
  PropertyIncludes.NO_PDFS,
  PropertyIncludes.NO_PRODUCT_TYPE,
  PropertyIncludes.NO_PRODUCT_DISPLAY_TYPES,
  PropertyIncludes.NO_SERVICES,
  PropertyIncludes.NO_RESPONSIBILITIES,
  PropertyIncludes.NO_DISTANCES,
  PropertyIncludes.NO_DISTRICTS,
  PropertyIncludes.NO_HALTIA,
  PropertyIncludes.NO_OPENING_HOURS,
  PropertyIncludes.NO_KEYWORDS,
]

export const AdminIncludeBasicPropertyDataWithAddress = [
  PropertyIncludes.NO_PROPERTY_IMAGES,
  PropertyIncludes.FIRST_SPACE_IMAGE_ONLY,
  PropertyIncludes.NO_PRODUCT_DISPLAY_TYPES,
  PropertyIncludes.NO_SERVICES,
  PropertyIncludes.NO_RESPONSIBILITIES,
  PropertyIncludes.NO_DISTANCES,
  PropertyIncludes.NO_DISTRICTS,
  PropertyIncludes.NO_OPENING_HOURS,
  PropertyIncludes.NO_KEYWORDS,
  PropertyIncludes.ALL_SPACES,
  PropertyIncludes.ADMIN_EMPLOYEES,
]

export const IncludeBasicPropertyDataWithHaltia = [
  PropertyIncludes.NO_IMAGES,
  PropertyIncludes.NO_PDFS,
  PropertyIncludes.NO_PRODUCT_TYPE,
  PropertyIncludes.NO_PRODUCT_DISPLAY_TYPES,
  PropertyIncludes.NO_SERVICES,
  PropertyIncludes.NO_RESPONSIBILITIES,
  PropertyIncludes.NO_DISTANCES,
  PropertyIncludes.NO_DISTRICTS,
  PropertyIncludes.NO_OPENING_HOURS,
  PropertyIncludes.NO_KEYWORDS,
]

export const IncludeOnlyExportedData = [
  PropertyIncludes.PUBLISHED_ONLY,
  PropertyIncludes.NO_DISTRICTS,
  PropertyIncludes.NO_HALTIA,
  PropertyIncludes.NO_OPENING_HOURS,
  PropertyIncludes.NO_PROPERTY_SPACES,
]

export const IncludeProfilePageSpaces = [
  PropertyIncludes.FIRST_SPACE_IMAGE_ONLY,
  PropertyIncludes.NO_KEYWORDS,
  PropertyIncludes.NO_PRODUCT_DISPLAY_TYPES,
  PropertyIncludes.NO_SERVICES,
  PropertyIncludes.NO_RESPONSIBILITIES,
  PropertyIncludes.NO_PDFS,
  PropertyIncludes.NO_PRODUCT_TYPE,
  PropertyIncludes.ADMIN_EMPLOYEES,
]
