/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiInvoiceInput
 */
export interface ApiInvoiceInput {
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    property: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    communityname: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    businessid: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    target: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    host: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    unit: string;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoiceInput
     */
    exvattotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoiceInput
     */
    exvatprice: number;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoiceInput
     */
    vatpercent: number;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    reservationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    invoiceReference: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoiceInput
     */
    termsAccepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceInput
     */
    propertyId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoiceInput
     */
    internal: boolean;
}

/**
 * Check if a given object implements the ApiInvoiceInput interface.
 */
export function instanceOfApiInvoiceInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "property" in value;
    isInstance = isInstance && "communityname" in value;
    isInstance = isInstance && "businessid" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "exvatprice" in value;
    isInstance = isInstance && "vatpercent" in value;
    isInstance = isInstance && "invoiceReference" in value;
    isInstance = isInstance && "termsAccepted" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "internal" in value;

    return isInstance;
}

export function ApiInvoiceInputFromJSON(json: any): ApiInvoiceInput {
    return ApiInvoiceInputFromJSONTyped(json, false);
}

export function ApiInvoiceInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvoiceInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'property': json['property'],
        'communityname': json['communityname'],
        'businessid': json['businessid'],
        'target': json['target'],
        'host': json['host'],
        'unit': json['unit'],
        'exvattotal': !exists(json, 'exvattotal') ? undefined : json['exvattotal'],
        'exvatprice': json['exvatprice'],
        'vatpercent': json['vatpercent'],
        'reservationNumber': !exists(json, 'reservation_number') ? undefined : json['reservation_number'],
        'invoiceReference': json['invoice_reference'],
        'termsAccepted': json['terms_accepted'],
        'email': json['email'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'internal': json['internal'],
    };
}

export function ApiInvoiceInputToJSON(value?: ApiInvoiceInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'property': value.property,
        'communityname': value.communityname,
        'businessid': value.businessid,
        'target': value.target,
        'host': value.host,
        'unit': value.unit,
        'exvattotal': value.exvattotal,
        'exvatprice': value.exvatprice,
        'vatpercent': value.vatpercent,
        'reservation_number': value.reservationNumber,
        'invoice_reference': value.invoiceReference,
        'terms_accepted': value.termsAccepted,
        'email': value.email,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'internal': value.internal,
    };
}

