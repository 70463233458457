/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiInvoiceReservation
 */
export interface ApiInvoiceReservation {
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceReservation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceReservation
     */
    invoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceReservation
     */
    reservationStart: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceReservation
     */
    reservationEnd: string;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoiceReservation
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoiceReservation
     */
    exvattotal: number;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceReservation
     */
    reservationNumber: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoiceReservation
     */
    canceled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoiceReservation
     */
    sent: boolean;
}

/**
 * Check if a given object implements the ApiInvoiceReservation interface.
 */
export function instanceOfApiInvoiceReservation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "invoiceId" in value;
    isInstance = isInstance && "reservationStart" in value;
    isInstance = isInstance && "reservationEnd" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "exvattotal" in value;
    isInstance = isInstance && "reservationNumber" in value;
    isInstance = isInstance && "canceled" in value;
    isInstance = isInstance && "sent" in value;

    return isInstance;
}

export function ApiInvoiceReservationFromJSON(json: any): ApiInvoiceReservation {
    return ApiInvoiceReservationFromJSONTyped(json, false);
}

export function ApiInvoiceReservationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvoiceReservation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'invoiceId': json['invoiceId'],
        'reservationStart': json['reservationStart'],
        'reservationEnd': json['reservationEnd'],
        'quantity': json['quantity'],
        'exvattotal': json['exvattotal'],
        'reservationNumber': json['reservationNumber'],
        'canceled': json['canceled'],
        'sent': json['sent'],
    };
}

export function ApiInvoiceReservationToJSON(value?: ApiInvoiceReservation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'invoiceId': value.invoiceId,
        'reservationStart': value.reservationStart,
        'reservationEnd': value.reservationEnd,
        'quantity': value.quantity,
        'exvattotal': value.exvattotal,
        'reservationNumber': value.reservationNumber,
        'canceled': value.canceled,
        'sent': value.sent,
    };
}

