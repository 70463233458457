import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NavBar, { NavBarLinkConfig } from './NavBar'
import { useIsAuthenticated } from '@azure/msal-react'
import { useAuth } from '../../lib/auth/useAuth'
import { setInLocalStorage } from '../../lib/storage/localStorage'
import { LocalStorageKey } from '../../lib/storage/localStorageKey'

const DefaultNavBar = () => {
  const { auth } = useAuth()
  const authenticated = useIsAuthenticated()

  const [loggedIn, setLoggedIn] = useState(false)

  const { t, i18n } = useTranslation()

  // Fix for react hydration error
  // https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    setLoggedIn(authenticated)
  }, [authenticated])

  const signIn = () => {
    auth.instance.login('signupsignin')
  }

  const changeLanguage = (lang: string) => {
    setInLocalStorage(LocalStorageKey.LANGUAGE, lang)
    i18n.changeLanguage(lang)
  }

  const loginLink = { title: t('signIn'), onClick: () => signIn() }

  const primaryLinks: NavBarLinkConfig[] = [
    { title: t('spacesearch'), url: '/' },
    { title: t('properties'), url: `https://antilooppi.fi/properties/?lang=${i18n.language}` },
    { title: t('antilooppi'), url: `https://antilooppi.fi/antilooppi/?lang=${i18n.language}` },
    ...(i18n.language === 'fi'
      ? [{ title: t('poolspace'), url: 'https://antilooppi.fi/pool/' }]
      : [{ title: t('poolspace'), url: 'https://antilooppi.fi/pool-flexible-office/?lang=en' }]),
    ...(i18n.language === 'fi'
      ? [{ title: t('turnkeySpacesTitle'), url: 'https://antilooppi.fi/aina-valmis/' }]
      : [{ title: t('turnkeySpacesTitle'), url: 'https://antilooppi.fi/always-ready-offices/?lang=en' }]),
    i18n.language === 'en'
      ? { title: 'Suomeksi', onClick: () => changeLanguage('fi') }
      : { title: 'In English', onClick: () => changeLanguage('en') },
  ]

  const secondaryLinks: NavBarLinkConfig[] = [
    { title: t('contact'), url: `https://antilooppi.fi/contact-us/?lang=${i18n.language}` },
    { title: t('sustainability'), url: `https://antilooppi.fi/sustainability/?lang=${i18n.language}` },
    { title: t('news'), url: `https://antilooppi.fi/news/?lang=${i18n.language}` },
  ]
  if (!loggedIn) {
    secondaryLinks.push(loginLink)
  }

  return <NavBar home='/' primaryLinks={primaryLinks} secondaryLinks={secondaryLinks} showContactUs />
}

export default DefaultNavBar
