import { ApiProperty, ApiSpace } from '@antilooppi/public-api'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import imgSpaceDefaultBackground from '../../assets/admin-space-background.png'
import spaceArrowRightImage from '../../assets/space-arrow-right.png'
import { getSpacePromoImagePath } from '../../lib/image'
import Image from '../Image'
import SpaceGridItemDetails from './SpaceGridItemDetails'

const SpaceGridItem = ({
  property,
  space,
  startTime,
  endTime,
}: {
  property: ApiProperty
  space: ApiSpace
  startTime?: Date
  endTime?: Date
}) => {
  const { t } = useTranslation()

  const url =
    startTime && endTime
      ? `/space/${space.propertyId}/${space.id}?startTime=${startTime.getTime()}&endTime=${endTime.getTime()}`
      : `/space/${space.propertyId}/${space.id}`

  return (
    <Link className='cursor-pointer' href={url}>
      <div className='md:h-spacegriditem md:w-spacegriditem h-spacegriditem relative flex w-full select-none flex-col'>
        <Image
          className='spaceGridItemImage'
          src={getSpacePromoImagePath(space) ?? imgSpaceDefaultBackground}
          alt={t('altSpaceImage')}
          layout='fill'
          objectFit='cover'
        />
      </div>
      <div className='flex w-full flex-row'>
        <div className='spaceGridItem mt-2 w-full pr-2'>{space.displayName}</div>
        <div className='relative mt-2 flex h-8 w-9 flex-row pr-1 md:mr-2'>
          <Image alt='' layout='fill' objectFit='cover' src={spaceArrowRightImage} />
        </div>
      </div>
      <div className='text-2xl'>{property.address}</div>
      <SpaceGridItemDetails space={space} />
    </Link>
  )
}

export default SpaceGridItem
