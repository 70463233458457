import ActiveFilterItem from './ActiveFilterItem'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { FilterMinMaxItem, FilterSelectedItem } from '../../lib/filter-types/filter-types'

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
}

const timeFormatOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
}

const ActiveFilterGroup = ({
  selectedDistricts,
  selectedProperties,
  selectedCapacity,
  selectedArea,
  selectedProductDisplayTypes,
  selectedKeywords,
  selectedDate,
  selectedStartTime,
  selectedEndTime,
  isReserving,
  clearDistrictFilter,
  clearPropertyFilter,
  clearCapacityFilter,
  clearAreaFilter,
  clearProductDisplayTypeFilter,
  clearKeywordFilter,
}: {
  selectedDistricts: FilterSelectedItem[]
  selectedProperties: FilterSelectedItem[]
  selectedCapacity: FilterMinMaxItem
  selectedArea: FilterMinMaxItem
  selectedProductDisplayTypes: FilterSelectedItem[]
  selectedKeywords: FilterSelectedItem[]
  selectedDate: Date
  selectedStartTime: Date
  selectedEndTime: Date
  isReserving: boolean
  clearDistrictFilter: () => void
  clearPropertyFilter: () => void
  clearCapacityFilter: () => void
  clearAreaFilter: () => void
  clearProductDisplayTypeFilter: () => void
  clearKeywordFilter: () => void
}) => {
  const { i18n, t } = useTranslation()

  const maxIndividualItems = 3

  const selectedItemsAsString = (selectedItems: FilterSelectedItem[], aggregateTitle: string) => {
    if (selectedItems.length > maxIndividualItems) {
      return `${aggregateTitle} (${selectedItems.length})`
    }
    return selectedItems?.map((selectedItem) => selectedItem.name).join(', ') ?? ''
  }

  return (
    <div className='flex flex-wrap gap-x-3'>
      {isReserving && (
        <ActiveFilterItem
          title={
            selectedDate.toLocaleString(i18n.language, dateFormatOptions) +
            ' ' +
            selectedStartTime.toLocaleTimeString(i18n.language, timeFormatOptions) +
            '-' +
            selectedEndTime.toLocaleTimeString(i18n.language, timeFormatOptions)
          }
          noWrap={true}
        />
      )}
      {_.first(selectedDistricts) && (
        <ActiveFilterItem
          title={selectedItemsAsString(selectedDistricts, t('areas'))}
          onDelete={() => {
            clearDistrictFilter()
          }}
        />
      )}
      {_.first(selectedProperties) && (
        <ActiveFilterItem
          title={selectedItemsAsString(selectedProperties, t('properties'))}
          onDelete={() => {
            clearPropertyFilter()
          }}
        />
      )}
      {(selectedCapacity.min || selectedCapacity.max) && (
        <ActiveFilterItem
          title={
            selectedCapacity.min && selectedCapacity.max
              ? `${selectedCapacity.min}-${selectedCapacity.max} ${t('people')}`
              : selectedCapacity.min
              ? `${selectedCapacity.min}+ ${t('people')}`
              : selectedCapacity.max
              ? `max ${selectedCapacity.max} ${t('people')}`
              : ''
          }
          noWrap={true}
          onDelete={() => {
            clearCapacityFilter()
          }}
        />
      )}
      {(selectedArea.min || selectedArea.max) && (
        <ActiveFilterItem
          title={
            selectedArea.min && selectedArea.max
              ? `${selectedArea.min}m\u00B2-${selectedArea.max}m\u00B2`
              : selectedArea.min
              ? `${selectedArea.min}m\u00B2+`
              : selectedArea.max
              ? `max ${selectedArea.max}m\u00B2`
              : ''
          }
          noWrap={true}
          onDelete={() => {
            clearAreaFilter()
          }}
        />
      )}
      {_.first(selectedProductDisplayTypes) && (
        <ActiveFilterItem
          title={selectedItemsAsString(selectedProductDisplayTypes, t('productDisplayTypes'))}
          onDelete={() => {
            clearProductDisplayTypeFilter()
          }}
        />
      )}
      {_.first(selectedKeywords) && (
        <ActiveFilterItem
          title={selectedItemsAsString(selectedKeywords, t('keywords'))}
          onDelete={() => {
            clearKeywordFilter()
          }}
        />
      )}
    </div>
  )
}

export default ActiveFilterGroup
