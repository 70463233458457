/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiUser
 */
export interface ApiUser {
    /**
     * 
     * @type {string}
     * @memberof ApiUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUser
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUser
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUser
     */
    businessId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiUser
     */
    roles: Array<string>;
}

/**
 * Check if a given object implements the ApiUser interface.
 */
export function instanceOfApiUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "businessId" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function ApiUserFromJSON(json: any): ApiUser {
    return ApiUserFromJSONTyped(json, false);
}

export function ApiUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
        'companyName': json['companyName'],
        'businessId': json['businessId'],
        'roles': json['roles'],
    };
}

export function ApiUserToJSON(value?: ApiUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'companyName': value.companyName,
        'businessId': value.businessId,
        'roles': value.roles,
    };
}

