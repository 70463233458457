import { ButtonHTMLAttributes, Children, isValidElement, ReactNode } from 'react'

export type ButtonSize = 'normal' | 'normalWithPadding' | 'small' | 'large' | 'reset' | 'empty'
export type ButtonTheme = 'blue' | 'green' | 'white' | 'red'

const getColorClasses = (theme: ButtonTheme) => {
  switch (theme) {
    case 'blue':
      return 'text-blue enabled:hover:bg-darkblue enabled:hover:text-white border-blue bg-transparent disabled:text-gray disabled:border-gray'
    case 'green':
      return 'text-blue border-blue bg-green disabled:bg-gray'
    case 'red':
      return 'text-white disabled:opacity-20 bg-red enabled:hover:bg-redButtonHover enabled:hover:border-redButtonHover border-red bg-transparent disabled:border-gray'
    default:
      return 'text-white enabled:hover:bg-orange border-white bg-transparent disabled:text-gray disabled:border-gray'
  }
}

const getSizeClasses = (size: ButtonSize) => {
  switch (size) {
    case 'small':
      return 'text-xl py-2 px-0 sm:px-2 md:px-4 font-body font-normal'
    case 'normal':
      return 'text-2xl py-2 px-2 sm:px-4 md:px-6 font-serif font-normal'
    case 'normalWithPadding':
      return 'text-2xl py-2 px-8 sm:px-4 md:px-6 font-serif font-normal'
    case 'large':
      return 'text-3xl py-3 px-2 sm:px-4 md:px-6 font-normal font-serif'
    case 'empty':
      return 'text-2xl border-[1.5px] px-4'
    default:
      return ''
  }
}

const getIconColor = (theme: ButtonTheme) => {
  switch (theme) {
    case 'red':
      return 'fill-white'
    default:
      return ''
  }
}

const getIconHoverColor = (theme: ButtonTheme) => {
  switch (theme) {
    case 'blue':
      return 'group-hover:fill-white'
    default:
      return ''
  }
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  theme?: ButtonTheme
  size?: ButtonSize
  icon?: ReactNode
  textClassName?: string
}

const Button = ({
  text = '',
  theme = 'blue',
  size = 'normal',
  className = '',
  textClassName = '',
  icon = null,
  children = null,
  ...props
}: ButtonProps) => {
  const colorClasses = getColorClasses(theme)
  const sizeClasses = getSizeClasses(size)
  const iconClass = (text ? 'group-disabled:text-gray ml-2' : 'group-disabled:text-gray') + ` ${getIconColor(theme)}`
  const textClass = textClassName ? textClassName : 'w-full text-center'

  const textEl = text ? <div className={`${textClass}`}>{text}</div> : null
  const iconEl = icon ? <div className={`${iconClass} ${getIconHoverColor(theme)}`}>{icon}</div> : null

  return (
    <button
      className={`group flex items-center justify-center rounded-full border text-center ${colorClasses} ${sizeClasses} ${className}`}
      {...props}>
      {textEl
        ? textEl
        : children
        ? Children.map(children, (child) => {
            return isValidElement(child) ? child : null
          })
        : null}
      {iconEl}
    </button>
  )
}

export default Button
