import { AntilooppiConfig } from '../antilooppi-config'

export const antilooppiConfigLocal: AntilooppiConfig = {
  hostname: 'http://localhost:4200',
  b2cClientId: '4199f445-1a4c-46ea-84e5-bffd1a9ce211',
  b2cTenantName: 'antilooppib2ctest',
  b2cApiAudience: '4d38a257-96de-40f5-8846-359d88bbc2d4',
  b2cHostName: 'test.login.antilooppi.fi',
  privateApiUrl: 'http://127.0.0.1:7071/api',
  privateApiKey: 'dummy-api-key-for-local-environment',
  publicApiUrl: 'http://127.0.0.1:7071/api',
  publicApiKey: 'dummy-api-key-for-local-environment',
  googleMapsApiKey: 'AIzaSyD8gBHmAULWvBWtTHXiX4aT3-ZchPtmA8o',
  stripePublicKey:
    'pk_test_51Ld5nFGavmKkMUZajLgRHd1AKqV7sTadTd4pt2iQdp22iWVcLCf8FUi2fq8PMd3Udywvf3VuuY9MalZEYSqA6gAO0085RmqEXH',
  friendlyCaptchaSiteKey: 'FCMJ275GALGSAB76',
  jsonContentCredentials: 'antilooppi:jsoncontent',
}
