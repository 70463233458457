/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiProperty,
  ApiPropertyUpdateInput,
  ApiPropertyUpdateSearchResultOrderInput,
} from '../models';
import {
    ApiPropertyFromJSON,
    ApiPropertyToJSON,
    ApiPropertyUpdateInputFromJSON,
    ApiPropertyUpdateInputToJSON,
    ApiPropertyUpdateSearchResultOrderInputFromJSON,
    ApiPropertyUpdateSearchResultOrderInputToJSON,
} from '../models';

export interface UpdatePropertiesSearchResultOrderRequest {
    apiPropertyUpdateSearchResultOrderInput: ApiPropertyUpdateSearchResultOrderInput;
}

export interface UpdatePropertyRequest {
    propertyId: string;
    apiPropertyUpdateInput: ApiPropertyUpdateInput;
}

/**
 * 
 */
export class PropertiesPrivateApi extends runtime.BaseAPI {

    /**
     * Update properties\' search result order
     * Update properties\' search result order
     */
    async updatePropertiesSearchResultOrderRaw(requestParameters: UpdatePropertiesSearchResultOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.apiPropertyUpdateSearchResultOrderInput === null || requestParameters.apiPropertyUpdateSearchResultOrderInput === undefined) {
            throw new runtime.RequiredError('apiPropertyUpdateSearchResultOrderInput','Required parameter requestParameters.apiPropertyUpdateSearchResultOrderInput was null or undefined when calling updatePropertiesSearchResultOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties-search-result-order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPropertyUpdateSearchResultOrderInputToJSON(requestParameters.apiPropertyUpdateSearchResultOrderInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update properties\' search result order
     * Update properties\' search result order
     */
    async updatePropertiesSearchResultOrder(requestParameters: UpdatePropertiesSearchResultOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePropertiesSearchResultOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Update property
     * Update property
     */
    async updatePropertyRaw(requestParameters: UpdatePropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiProperty>> {
        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling updateProperty.');
        }

        if (requestParameters.apiPropertyUpdateInput === null || requestParameters.apiPropertyUpdateInput === undefined) {
            throw new runtime.RequiredError('apiPropertyUpdateInput','Required parameter requestParameters.apiPropertyUpdateInput was null or undefined when calling updateProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/{propertyId}`.replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiPropertyUpdateInputToJSON(requestParameters.apiPropertyUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPropertyFromJSON(jsonValue));
    }

    /**
     * Update property
     * Update property
     */
    async updateProperty(requestParameters: UpdatePropertyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiProperty> {
        const response = await this.updatePropertyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
