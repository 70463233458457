import { useSidebar } from './useSidebar'
import ContactForm from '../components/ContactForm'
import React from 'react'
import { useSingleProperty } from './api/properties'
import { useSingleSpace } from './api/spaces'
import { IncludeBasicDataOnly } from '@antilooppi/helpers'

export const useContactForm = (propertyId?: string, spaceId?: string) => {
  const { showSidebar, hideSidebar, setSidebarContent, visible } = useSidebar()

  const { data: property } = useSingleProperty(propertyId as string, IncludeBasicDataOnly)
  const { data: space } = useSingleSpace(spaceId as string)

  const showContactForm = () => {
    setSidebarContent(<ContactForm property={property} space={space} onComplete={() => hideSidebar()} />)
    showSidebar()
  }

  const hideContactForm = () => {
    hideSidebar()
    setSidebarContent(null)
  }

  const toggleContactForm = () => {
    if (visible) {
      hideContactForm()
    } else {
      showContactForm()
    }
  }

  return { showContactForm, hideContactForm, toggleContactForm }
}
