/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAdminSettings
 */
export interface ApiAdminSettings {
    /**
     * 
     * @type {number}
     * @memberof ApiAdminSettings
     */
    reservationCancellableBeforeHours: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAdminSettings
     */
    invoiceRecipient: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAdminSettings
     */
    vatPercent: number;
}

/**
 * Check if a given object implements the ApiAdminSettings interface.
 */
export function instanceOfApiAdminSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reservationCancellableBeforeHours" in value;
    isInstance = isInstance && "invoiceRecipient" in value;
    isInstance = isInstance && "vatPercent" in value;

    return isInstance;
}

export function ApiAdminSettingsFromJSON(json: any): ApiAdminSettings {
    return ApiAdminSettingsFromJSONTyped(json, false);
}

export function ApiAdminSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAdminSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reservationCancellableBeforeHours': json['reservationCancellableBeforeHours'],
        'invoiceRecipient': json['invoiceRecipient'],
        'vatPercent': json['vatPercent'],
    };
}

export function ApiAdminSettingsToJSON(value?: ApiAdminSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reservationCancellableBeforeHours': value.reservationCancellableBeforeHours,
        'invoiceRecipient': value.invoiceRecipient,
        'vatPercent': value.vatPercent,
    };
}

