/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiCategoryUpdateInput } from './ApiCategoryUpdateInput';
import {
    ApiCategoryUpdateInputFromJSON,
    ApiCategoryUpdateInputFromJSONTyped,
    ApiCategoryUpdateInputToJSON,
} from './ApiCategoryUpdateInput';
import type { ApiEmployeeUpdateInput } from './ApiEmployeeUpdateInput';
import {
    ApiEmployeeUpdateInputFromJSON,
    ApiEmployeeUpdateInputFromJSONTyped,
    ApiEmployeeUpdateInputToJSON,
} from './ApiEmployeeUpdateInput';
import type { ApiProductDisplayTypeUpdateInput } from './ApiProductDisplayTypeUpdateInput';
import {
    ApiProductDisplayTypeUpdateInputFromJSON,
    ApiProductDisplayTypeUpdateInputFromJSONTyped,
    ApiProductDisplayTypeUpdateInputToJSON,
} from './ApiProductDisplayTypeUpdateInput';
import type { ApiProductType } from './ApiProductType';
import {
    ApiProductTypeFromJSON,
    ApiProductTypeFromJSONTyped,
    ApiProductTypeToJSON,
} from './ApiProductType';
import type { ApiResponsibility } from './ApiResponsibility';
import {
    ApiResponsibilityFromJSON,
    ApiResponsibilityFromJSONTyped,
    ApiResponsibilityToJSON,
} from './ApiResponsibility';
import type { ApiService } from './ApiService';
import {
    ApiServiceFromJSON,
    ApiServiceFromJSONTyped,
    ApiServiceToJSON,
} from './ApiService';
import type { ApiSpaceUpdateInputTranslations } from './ApiSpaceUpdateInputTranslations';
import {
    ApiSpaceUpdateInputTranslationsFromJSON,
    ApiSpaceUpdateInputTranslationsFromJSONTyped,
    ApiSpaceUpdateInputTranslationsToJSON,
} from './ApiSpaceUpdateInputTranslations';

/**
 * 
 * @export
 * @interface ApiSpaceUpdateInput
 */
export interface ApiSpaceUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    propertyId: string;
    /**
     * 
     * @type {number}
     * @memberof ApiSpaceUpdateInput
     */
    minCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpaceUpdateInput
     */
    maxCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpaceUpdateInput
     */
    roomArea?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpaceUpdateInput
     */
    tempReserved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpaceUpdateInput
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    publishedTimestamp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpaceUpdateInput
     */
    highlighted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    displayName?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiSpaceUpdateInput
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpaceUpdateInput
     */
    taxRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    priceDetails?: string;
    /**
     * 
     * @type {Array<ApiCategoryUpdateInput>}
     * @memberof ApiSpaceUpdateInput
     */
    categories: Array<ApiCategoryUpdateInput>;
    /**
     * 
     * @type {Array<ApiProductDisplayTypeUpdateInput>}
     * @memberof ApiSpaceUpdateInput
     */
    productDisplayTypes: Array<ApiProductDisplayTypeUpdateInput>;
    /**
     * 
     * @type {ApiSpaceUpdateInputTranslations}
     * @memberof ApiSpaceUpdateInput
     */
    translations?: ApiSpaceUpdateInputTranslations;
    /**
     * 
     * @type {ApiProductType}
     * @memberof ApiSpaceUpdateInput
     */
    productType?: ApiProductType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiSpaceUpdateInput
     */
    imageIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    defaultImageId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiSpaceUpdateInput
     */
    pdfIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    defaultBlueprintPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    defaultArrivalInstructionsPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpaceUpdateInput
     */
    defaultBrochurePdfId?: string;
    /**
     * 
     * @type {Array<ApiService>}
     * @memberof ApiSpaceUpdateInput
     */
    services?: Array<ApiService>;
    /**
     * 
     * @type {Array<ApiResponsibility>}
     * @memberof ApiSpaceUpdateInput
     */
    responsibilities?: Array<ApiResponsibility>;
    /**
     * 
     * @type {Array<ApiEmployeeUpdateInput>}
     * @memberof ApiSpaceUpdateInput
     */
    employees?: Array<ApiEmployeeUpdateInput>;
}

/**
 * Check if a given object implements the ApiSpaceUpdateInput interface.
 */
export function instanceOfApiSpaceUpdateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "tempReserved" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "highlighted" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "productDisplayTypes" in value;

    return isInstance;
}

export function ApiSpaceUpdateInputFromJSON(json: any): ApiSpaceUpdateInput {
    return ApiSpaceUpdateInputFromJSONTyped(json, false);
}

export function ApiSpaceUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiSpaceUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'propertyId': json['propertyId'],
        'minCapacity': !exists(json, 'minCapacity') ? undefined : json['minCapacity'],
        'maxCapacity': !exists(json, 'maxCapacity') ? undefined : json['maxCapacity'],
        'roomArea': !exists(json, 'roomArea') ? undefined : json['roomArea'],
        'tempReserved': json['tempReserved'],
        'published': json['published'],
        'publishedTimestamp': !exists(json, 'publishedTimestamp') ? undefined : json['publishedTimestamp'],
        'highlighted': json['highlighted'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'taxRate': !exists(json, 'taxRate') ? undefined : json['taxRate'],
        'priceDetails': !exists(json, 'priceDetails') ? undefined : json['priceDetails'],
        'categories': ((json['categories'] as Array<any>).map(ApiCategoryUpdateInputFromJSON)),
        'productDisplayTypes': ((json['productDisplayTypes'] as Array<any>).map(ApiProductDisplayTypeUpdateInputFromJSON)),
        'translations': !exists(json, 'translations') ? undefined : ApiSpaceUpdateInputTranslationsFromJSON(json['translations']),
        'productType': !exists(json, 'productType') ? undefined : ApiProductTypeFromJSON(json['productType']),
        'imageIds': !exists(json, 'imageIds') ? undefined : json['imageIds'],
        'defaultImageId': !exists(json, 'defaultImageId') ? undefined : json['defaultImageId'],
        'pdfIds': !exists(json, 'pdfIds') ? undefined : json['pdfIds'],
        'defaultBlueprintPdfId': !exists(json, 'defaultBlueprintPdfId') ? undefined : json['defaultBlueprintPdfId'],
        'defaultArrivalInstructionsPdfId': !exists(json, 'defaultArrivalInstructionsPdfId') ? undefined : json['defaultArrivalInstructionsPdfId'],
        'defaultBrochurePdfId': !exists(json, 'defaultBrochurePdfId') ? undefined : json['defaultBrochurePdfId'],
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(ApiServiceFromJSON)),
        'responsibilities': !exists(json, 'responsibilities') ? undefined : ((json['responsibilities'] as Array<any>).map(ApiResponsibilityFromJSON)),
        'employees': !exists(json, 'employees') ? undefined : ((json['employees'] as Array<any>).map(ApiEmployeeUpdateInputFromJSON)),
    };
}

export function ApiSpaceUpdateInputToJSON(value?: ApiSpaceUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'propertyId': value.propertyId,
        'minCapacity': value.minCapacity,
        'maxCapacity': value.maxCapacity,
        'roomArea': value.roomArea,
        'tempReserved': value.tempReserved,
        'published': value.published,
        'publishedTimestamp': value.publishedTimestamp,
        'highlighted': value.highlighted,
        'displayName': value.displayName,
        'price': value.price,
        'taxRate': value.taxRate,
        'priceDetails': value.priceDetails,
        'categories': ((value.categories as Array<any>).map(ApiCategoryUpdateInputToJSON)),
        'productDisplayTypes': ((value.productDisplayTypes as Array<any>).map(ApiProductDisplayTypeUpdateInputToJSON)),
        'translations': ApiSpaceUpdateInputTranslationsToJSON(value.translations),
        'productType': ApiProductTypeToJSON(value.productType),
        'imageIds': value.imageIds,
        'defaultImageId': value.defaultImageId,
        'pdfIds': value.pdfIds,
        'defaultBlueprintPdfId': value.defaultBlueprintPdfId,
        'defaultArrivalInstructionsPdfId': value.defaultArrivalInstructionsPdfId,
        'defaultBrochurePdfId': value.defaultBrochurePdfId,
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(ApiServiceToJSON)),
        'responsibilities': value.responsibilities === undefined ? undefined : ((value.responsibilities as Array<any>).map(ApiResponsibilityToJSON)),
        'employees': value.employees === undefined ? undefined : ((value.employees as Array<any>).map(ApiEmployeeUpdateInputToJSON)),
    };
}

