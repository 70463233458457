import { ILoggerCallback, LogLevel } from '@azure/msal-browser'

const logLevelSeverityOrder = [LogLevel.Error, LogLevel.Warning, LogLevel.Info, LogLevel.Verbose, LogLevel.Trace]

const hasHigherSeverity = (one: LogLevel, other: LogLevel): boolean => {
  return logLevelSeverityOrder.indexOf(one) < logLevelSeverityOrder.indexOf(other)
}

export const makeAuthLogger =
  (logLevel: LogLevel): ILoggerCallback =>
  (level, message, containsPii) => {
    if (containsPii || hasHigherSeverity(logLevel, level)) {
      return
    }
    switch (level) {
      case LogLevel.Error:
        console.error(message)
        return
      case LogLevel.Info:
        console.info(message)
        return
      case LogLevel.Verbose:
        console.debug(message)
        return
      case LogLevel.Warning:
        console.warn(message)
        return
    }
  }
