/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDistrict,
  ApiDistrictCreateInput,
  ApiDistrictUpdateInput,
} from '../models';
import {
    ApiDistrictFromJSON,
    ApiDistrictToJSON,
    ApiDistrictCreateInputFromJSON,
    ApiDistrictCreateInputToJSON,
    ApiDistrictUpdateInputFromJSON,
    ApiDistrictUpdateInputToJSON,
} from '../models';

export interface CreateDistrictRequest {
    apiDistrictCreateInput: ApiDistrictCreateInput;
}

export interface DeleteDistrictRequest {
    districtId: string;
}

export interface UpdateDistrictRequest {
    districtId: string;
    apiDistrictUpdateInput: ApiDistrictUpdateInput;
}

/**
 * 
 */
export class DistrictsPrivateApi extends runtime.BaseAPI {

    /**
     * Create district
     * Create district
     */
    async createDistrictRaw(requestParameters: CreateDistrictRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiDistrict>> {
        if (requestParameters.apiDistrictCreateInput === null || requestParameters.apiDistrictCreateInput === undefined) {
            throw new runtime.RequiredError('apiDistrictCreateInput','Required parameter requestParameters.apiDistrictCreateInput was null or undefined when calling createDistrict.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/districts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDistrictCreateInputToJSON(requestParameters.apiDistrictCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDistrictFromJSON(jsonValue));
    }

    /**
     * Create district
     * Create district
     */
    async createDistrict(requestParameters: CreateDistrictRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiDistrict> {
        const response = await this.createDistrictRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete district
     * Delete district
     */
    async deleteDistrictRaw(requestParameters: DeleteDistrictRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.districtId === null || requestParameters.districtId === undefined) {
            throw new runtime.RequiredError('districtId','Required parameter requestParameters.districtId was null or undefined when calling deleteDistrict.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/districts/{districtId}`.replace(`{${"districtId"}}`, encodeURIComponent(String(requestParameters.districtId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete district
     * Delete district
     */
    async deleteDistrict(requestParameters: DeleteDistrictRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDistrictRaw(requestParameters, initOverrides);
    }

    /**
     * Update district
     * Update district
     */
    async updateDistrictRaw(requestParameters: UpdateDistrictRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiDistrict>> {
        if (requestParameters.districtId === null || requestParameters.districtId === undefined) {
            throw new runtime.RequiredError('districtId','Required parameter requestParameters.districtId was null or undefined when calling updateDistrict.');
        }

        if (requestParameters.apiDistrictUpdateInput === null || requestParameters.apiDistrictUpdateInput === undefined) {
            throw new runtime.RequiredError('apiDistrictUpdateInput','Required parameter requestParameters.apiDistrictUpdateInput was null or undefined when calling updateDistrict.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/districts/{districtId}`.replace(`{${"districtId"}}`, encodeURIComponent(String(requestParameters.districtId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiDistrictUpdateInputToJSON(requestParameters.apiDistrictUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDistrictFromJSON(jsonValue));
    }

    /**
     * Update district
     * Update district
     */
    async updateDistrict(requestParameters: UpdateDistrictRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiDistrict> {
        const response = await this.updateDistrictRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
