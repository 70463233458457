import { z } from 'zod'
import { ApiPropertyUpdateInput } from '../../generated/private-api'
import { ApiPropertyUpdateSearchResultOrderInput } from '../../generated/private-api'

const SpaceUpdateTranslationsInputSchema = z.object({
  description: z.string().optional(),
  serviceDescription: z.string().optional(),
  displayName: z.string().optional(),
  sustainabilityDescription: z.string().optional(),
  lobbyDescription: z.string().optional(),
})

export const PropertyUpdateInputSchema: z.ZodType<ApiPropertyUpdateInput> = z.object({
  id: z.string(),
  translations: z
    .object({
      fi: SpaceUpdateTranslationsInputSchema,
      en: SpaceUpdateTranslationsInputSchema.optional(),
    })
    .optional(),
  categories: z.array(z.object({ id: z.string(), nameEn: z.string(), nameFi: z.string() })),
  districts: z.array(z.object({ id: z.string(), name: z.string() })),
  services: z.array(z.object({ id: z.string(), nameFi: z.string(), nameEn: z.string(), iconUrl: z.string() })),
  responsibilities: z.array(z.object({ id: z.string(), nameFi: z.string(), nameEn: z.string(), iconUrl: z.string() })),
  distances: z.array(
    z.object({
      id: z.string(),
      nameFi: z.string(),
      nameEn: z.string(),
      iconUrl: z.string(),
      value: z.string().optional(),
    })
  ),
  imageIds: z.array(z.string()),
  defaultImageId: z.string().optional(),
  pdfIds: z.array(z.string()),
  defaultBlueprintPdfId: z.string().optional(),
  defaultArrivalInstructionsPdfId: z.string().optional(),
  defaultBrochurePdfId: z.string().optional(),
})

// Admin: Search result order
export const PropertyUpdateSearchResultOrderInputSchema: z.ZodType<ApiPropertyUpdateSearchResultOrderInput> = z.object({
  searchResultOrder: z.array(z.object({ propertyId: z.string(), searchResultOrder: z.number(), hidden: z.number() })),
})
