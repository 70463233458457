import { useEffect, useRef } from 'react'
import { WidgetInstance } from 'friendly-challenge'
import { environment } from '../environment'
import { useTranslation } from 'react-i18next'
import { getSupportedLanguageOrDefault } from '../i18n/i18next'

const Captcha = ({
  doneCallback,
  errorCallback,
}: {
  doneCallback: (solution: string) => void
  errorCallback: (error: Error) => void
}) => {
  const container = useRef<HTMLDivElement>(null)
  const widget = useRef<WidgetInstance>()

  const { i18n } = useTranslation()

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: 'focus',
        language: getSupportedLanguageOrDefault(i18n.language),
        doneCallback,
        errorCallback,
      })
    }
    return () => {
      if (widget.current) {
        widget.current.reset()
      }
    }
  }, [doneCallback, errorCallback, i18n.language])

  return <div ref={container} className='frc-captcha' data-sitekey={environment.getFriendlyCaptchaSiteKey()} />
}

export default Captcha
