import { AntilooppiConfig } from '../antilooppi-config'

export const antilooppiConfigDev: AntilooppiConfig = {
  hostname: 'https://dev.spaces.antilooppi.fi',
  b2cClientId: '4199f445-1a4c-46ea-84e5-bffd1a9ce211',
  b2cTenantName: 'antilooppib2ctest',
  b2cApiAudience: '4d38a257-96de-40f5-8846-359d88bbc2d4',
  b2cHostName: 'test.login.antilooppi.fi',
  privateApiUrl: 'https://dev.private-api.antilooppi.fi/api',
  privateApiKey: 'f47d2e980f2a4f80a646d8d066457f0c',
  publicApiUrl: 'https://dev.api.antilooppi.fi/api',
  publicApiKey: '34dbc27cf049426da9f3b4c062bff093',
  googleMapsApiKey: 'AIzaSyDO3YpYD1Dn4t0zxhLsg5vjAbHnq7Kg8CU',
  stripePublicKey:
    'pk_test_51Ld5nFGavmKkMUZajLgRHd1AKqV7sTadTd4pt2iQdp22iWVcLCf8FUi2fq8PMd3Udywvf3VuuY9MalZEYSqA6gAO0085RmqEXH',
  friendlyCaptchaSiteKey: 'FCMJ275GALGSAB76',
  jsonContentCredentials: 'antilooppi:jsoncontent',
}
