import React, { Children, isValidElement, ReactNode } from 'react'

const PageContent = ({ children = null, className = '' }: { children?: ReactNode; className?: string }) => {
  return (
    <div
      className={`bg-offwhite mb-4 overflow-x-hidden px-0 pt-[56px] pb-10 md:relative md:mx-auto md:mt-[128px] md:max-w-6xl md:overflow-x-visible md:px-5 ${className}`}>
      <div className='mt-6'>
        {Children.map(children, (child) => {
          return isValidElement(child) ? child : null
        })}
      </div>
    </div>
  )
}

export default PageContent
