/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiInvoiceCustomerDomainCreateInput
 */
export interface ApiInvoiceCustomerDomainCreateInput {
    /**
     * 
     * @type {string}
     * @memberof ApiInvoiceCustomerDomainCreateInput
     */
    domain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoiceCustomerDomainCreateInput
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the ApiInvoiceCustomerDomainCreateInput interface.
 */
export function instanceOfApiInvoiceCustomerDomainCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "domain" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function ApiInvoiceCustomerDomainCreateInputFromJSON(json: any): ApiInvoiceCustomerDomainCreateInput {
    return ApiInvoiceCustomerDomainCreateInputFromJSONTyped(json, false);
}

export function ApiInvoiceCustomerDomainCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvoiceCustomerDomainCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
        'enabled': json['enabled'],
    };
}

export function ApiInvoiceCustomerDomainCreateInputToJSON(value?: ApiInvoiceCustomerDomainCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
        'enabled': value.enabled,
    };
}

