/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiSpace,
  ApiSpaceUpdateInput,
} from '../models';
import {
    ApiSpaceFromJSON,
    ApiSpaceToJSON,
    ApiSpaceUpdateInputFromJSON,
    ApiSpaceUpdateInputToJSON,
} from '../models';

export interface UpdateSpaceRequest {
    spaceId: string;
    apiSpaceUpdateInput: ApiSpaceUpdateInput;
}

/**
 * 
 */
export class SpacesPrivateApi extends runtime.BaseAPI {

    /**
     * Update space
     * Update space
     */
    async updateSpaceRaw(requestParameters: UpdateSpaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiSpace>> {
        if (requestParameters.spaceId === null || requestParameters.spaceId === undefined) {
            throw new runtime.RequiredError('spaceId','Required parameter requestParameters.spaceId was null or undefined when calling updateSpace.');
        }

        if (requestParameters.apiSpaceUpdateInput === null || requestParameters.apiSpaceUpdateInput === undefined) {
            throw new runtime.RequiredError('apiSpaceUpdateInput','Required parameter requestParameters.apiSpaceUpdateInput was null or undefined when calling updateSpace.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/spaces/{spaceId}`.replace(`{${"spaceId"}}`, encodeURIComponent(String(requestParameters.spaceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiSpaceUpdateInputToJSON(requestParameters.apiSpaceUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiSpaceFromJSON(jsonValue));
    }

    /**
     * Update space
     * Update space
     */
    async updateSpace(requestParameters: UpdateSpaceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiSpace> {
        const response = await this.updateSpaceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
