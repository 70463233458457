import { z } from 'zod'
import { ApiLeadInput } from '../../generated/private-api'

// Space-specific fields are optional because they may not be known from context.
// E.g. if a user is sending a message when not looking at a space.
export const LeadInputSchema: z.ZodType<ApiLeadInput> = z.object({
  businessId: z.string().regex(/\d{6,7}-\d/, 'validation:invalidBusinessId'),
  companyName: z.string().optional(),
  description: z.string().optional(),
  email: z.string().min(1, 'validation:emailRequired').email('validation:invalidEmail'),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  jobTitle: z.string().optional(),
  phoneNumber: z.string().optional(),
  productDisplayTypes: z.array(z.string()).optional(),
  productType: z.enum(['pool_meet', 'pool_work', 'always_ready', 'tailor_made']).optional(),
  propertyId: z.string().optional(),
  propertyName: z.string().optional(),
  solution: z.string().min(1),
  spaceId: z.string().optional(),
  spaceName: z.string().optional(),
})
