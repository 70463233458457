export const getFormattedTimeslot = (start: Date, end: Date): string => {
  const timezone = 'Europe/Helsinki'
  const weekdayFormatter = new Intl.DateTimeFormat('fi-FI', { weekday: 'short' })
  const dateFormatter = new Intl.DateTimeFormat('fi-FI', { dateStyle: 'medium' })
  const timeFormatter = new Intl.DateTimeFormat('fi-FI', { timeStyle: 'short', timeZone: timezone })
  const formattedWeekday = weekdayFormatter.format(start)
  const formattedDate = dateFormatter.format(start)
  const formattedStart = timeFormatter.format(start)
  const formattedEnd = timeFormatter.format(end)
  return `${formattedWeekday} ${formattedDate}, klo ${formattedStart}-${formattedEnd}`
}
