import { useTranslation } from 'react-i18next'
import FilterRange from '../FilterRange/FilterRange'
import { FilterMinMaxItem } from '../../lib/filter-types/filter-types'
import { FilterInputField } from './FilterInputField'
import Button from '../Button'

const CapacityAndArea = ({
  selectedCapacity,
  selectedArea,
  selectCapacity,
  selectArea,
  maxCapacity,
  maxArea,
  isReserving,
}: {
  selectedCapacity: FilterMinMaxItem
  selectedArea: FilterMinMaxItem
  selectCapacity: (capacity: FilterMinMaxItem) => void
  selectArea: (area: FilterMinMaxItem) => void
  maxCapacity: number
  maxArea: number
  isReserving: boolean
}) => {
  const ensureNumericValue = (value: string, minAllowed?: number, maxAllowed?: number): number | undefined => {
    const numericValue = parseInt(value)
    if (minAllowed && numericValue < minAllowed) {
      return undefined
    } else if (maxAllowed && numericValue > maxAllowed) {
      return undefined
    }
    return !isNaN(numericValue) ? numericValue : undefined
  }

  const handleEnterMinCapacity = (value: string) => {
    selectCapacity({
      min: ensureNumericValue(value, 0, selectedCapacity.max) ?? undefined,
      max: selectedCapacity.max,
    })
  }

  const handleEnterMaxCapacity = (value: string) => {
    selectCapacity({
      min: selectedCapacity.min,
      max: ensureNumericValue(value, 0, maxCapacity) ?? undefined,
    })
  }

  const handleEnterMinArea = (value: string) => {
    selectArea({
      min: ensureNumericValue(value, 0, selectedArea.max) ?? undefined,
      max: selectedArea.max,
    })
  }

  const handleEnterMaxArea = (value: string) => {
    selectArea({
      min: selectedArea.min,
      max: ensureNumericValue(value, 0, maxArea) ?? undefined,
    })
  }

  const ensureValidRanges = () => {
    // Ensure both capacity and area ranges do not overlap.
    if (selectedCapacity.min && selectedCapacity.max && selectedCapacity.min > selectedCapacity.max) {
      selectCapacity({ min: selectedCapacity.max, max: selectedCapacity.min })
    }
    if (selectedArea.min && selectedArea.max && selectedArea.min > selectedArea.max) {
      selectArea({ min: selectedArea.max, max: selectedArea.min })
    }
  }

  // Depending on whether user is renting or reserving (isReserving parameter), show capacity and area in a specific order:
  // - Renting: Area first, capacity second
  // - Reserving: Capacity first, area second
  return (
    <div className='relative z-10 md:ml-[1.5rem] md:w-[38.95rem]'>
      <div className='filterExpanded md:absolute'>
        <div className='flex flex-col space-y-3 md:flex-row md:space-x-3 md:space-y-0'>
          {isReserving ? (
            <Capacity
              selectedCapacity={selectedCapacity}
              maxCapacity={maxCapacity}
              handleEnterMinCapacity={handleEnterMinCapacity}
              handleEnterMaxCapacity={handleEnterMaxCapacity}
              ensureValidRanges={ensureValidRanges}
              selectCapacity={selectCapacity}
            />
          ) : (
            <Area
              selectedArea={selectedArea}
              maxArea={maxArea}
              handleEnterMinArea={handleEnterMinArea}
              handleEnterMaxArea={handleEnterMaxArea}
              ensureValidRanges={ensureValidRanges}
              selectArea={selectArea}
            />
          )}
          <div className='bg-darkblue h-[2px] w-full md:mt-1 md:h-[8rem] md:w-[1.5px]'></div>
          {isReserving ? (
            <Area
              selectedArea={selectedArea}
              maxArea={maxArea}
              handleEnterMinArea={handleEnterMinArea}
              handleEnterMaxArea={handleEnterMaxArea}
              ensureValidRanges={ensureValidRanges}
              selectArea={selectArea}
            />
          ) : (
            <Capacity
              selectedCapacity={selectedCapacity}
              maxCapacity={maxCapacity}
              handleEnterMinCapacity={handleEnterMinCapacity}
              handleEnterMaxCapacity={handleEnterMaxCapacity}
              ensureValidRanges={ensureValidRanges}
              selectCapacity={selectCapacity}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const Capacity = ({
  selectedCapacity,
  maxCapacity,
  handleEnterMinCapacity,
  handleEnterMaxCapacity,
  ensureValidRanges,
  selectCapacity,
}: {
  selectedCapacity: FilterMinMaxItem
  maxCapacity: number
  handleEnterMinCapacity: (value: string) => void
  handleEnterMaxCapacity: (value: string) => void
  ensureValidRanges: () => void
  selectCapacity: (capacity: FilterMinMaxItem) => void
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='text-xl'>{t('capacityPeople')}</div>
      <div className='flex flex-col'>
        <div className='mt-2 flex flex-row'>
          <FilterInputField
            onChange={(event) => handleEnterMinCapacity(event.target.value)}
            value={selectedCapacity.min?.toString() ?? '0'}
            ensureValidRanges={ensureValidRanges}
          />
          <div className='h-0 w-[4rem] self-center border'></div>
          <FilterInputField
            onChange={(event) => handleEnterMaxCapacity(event.target.value)}
            value={selectedCapacity.max?.toString() ?? '∞'}
            ensureValidRanges={ensureValidRanges}
          />
        </div>
        <FilterRange
          min={0}
          max={maxCapacity}
          step={5}
          values={[selectedCapacity.min, selectedCapacity.max]}
          showLabels={false}
          onValuesChanged={(values) => {
            selectCapacity({ min: values[0], max: values[1] })
          }}
        />
        {(selectedCapacity.min || selectedCapacity.max) && (
          <div className='mr-4 mt-3 mb-1 flex w-full flex-grow flex-row justify-end'>
            <Button
              text={t('empty')}
              size='empty'
              theme='blue'
              onClick={() => selectCapacity({ min: undefined, max: undefined })}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const Area = ({
  selectedArea,
  maxArea,
  handleEnterMinArea,
  handleEnterMaxArea,
  ensureValidRanges,
  selectArea,
}: {
  selectedArea: FilterMinMaxItem
  maxArea: number
  handleEnterMinArea: (value: string) => void
  handleEnterMaxArea: (value: string) => void
  ensureValidRanges: () => void
  selectArea: (area: FilterMinMaxItem) => void
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='text-xl'>{t('roomArea')}</div>
      <div className='mt-2 flex flex-row'>
        <FilterInputField
          onChange={(event) => handleEnterMinArea(event.target.value)}
          value={selectedArea.min?.toString() ?? '0'}
          ensureValidRanges={ensureValidRanges}
        />
        <div className='h-0 w-[4rem] self-center border'></div>
        <FilterInputField
          onChange={(event) => handleEnterMaxArea(event.target.value)}
          value={selectedArea.max?.toString() ?? '∞'}
          ensureValidRanges={ensureValidRanges}
        />
      </div>
      <FilterRange
        min={0}
        max={maxArea}
        step={5}
        values={[selectedArea.min, selectedArea.max]}
        showLabels={false}
        onValuesChanged={(values) => {
          selectArea({ min: values[0], max: values[1] })
        }}
      />
      {(selectedArea.min || selectedArea.max) && (
        <div className='mr-4 mt-3 mb-1 flex w-full flex-grow flex-row justify-end'>
          <Button
            text={t('empty')}
            size='empty'
            theme='blue'
            onClick={() => selectArea({ min: undefined, max: undefined })}
          />
        </div>
      )}
    </div>
  )
}

export default CapacityAndArea
