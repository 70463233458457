import React, { InputHTMLAttributes } from 'react'
import { FieldPath, FieldValues, UseFormRegister } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

export type InputProps<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
  fieldName: FieldPath<T>
  label: string
  register: UseFormRegister<T>
  registerOptions?: RegisterOptions<T>
}

const Input = <T extends FieldValues>({
  fieldName,
  label,
  type = 'text',
  required,
  register,
  registerOptions,
  ...props
}: InputProps<T>) => {
  const requiredClass = required ? "after:content-['_*'] after:text-red-500" : ''
  return (
    <>
      <label htmlFor={label} className={`absolute top-4 left-4 mb-1 text-lg leading-none ${requiredClass}`}>
        {label}
      </label>
      <input
        id={fieldName}
        type={type}
        className='h-20 w-full px-4 pt-8 pb-2 text-xl'
        {...props}
        {...register(fieldName, registerOptions)}
      />
    </>
  )
}

export default Input
