/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiPing,
} from '../models';
import {
    ApiPingFromJSON,
    ApiPingToJSON,
} from '../models';

/**
 * 
 */
export class SystemPrivateApi extends runtime.BaseAPI {

    /**
     * A health check for the API
     * Ping
     */
    async pingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPing>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPingFromJSON(jsonValue));
    }

    /**
     * A health check for the API
     * Ping
     */
    async ping(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPing> {
        const response = await this.pingRaw(initOverrides);
        return await response.value();
    }

}
