/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiCategory,
  ApiCategoryCreateInput,
  ApiCategoryUpdateInput,
} from '../models';
import {
    ApiCategoryFromJSON,
    ApiCategoryToJSON,
    ApiCategoryCreateInputFromJSON,
    ApiCategoryCreateInputToJSON,
    ApiCategoryUpdateInputFromJSON,
    ApiCategoryUpdateInputToJSON,
} from '../models';

export interface CreateCategoryRequest {
    apiCategoryCreateInput: ApiCategoryCreateInput;
}

export interface DeleteCategoryRequest {
    categoryId: string;
}

export interface UpdateCategoryRequest {
    categoryId: string;
    apiCategoryUpdateInput: ApiCategoryUpdateInput;
}

/**
 * 
 */
export class CategoriesPrivateApi extends runtime.BaseAPI {

    /**
     * Create category
     * Create category
     */
    async createCategoryRaw(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiCategory>> {
        if (requestParameters.apiCategoryCreateInput === null || requestParameters.apiCategoryCreateInput === undefined) {
            throw new runtime.RequiredError('apiCategoryCreateInput','Required parameter requestParameters.apiCategoryCreateInput was null or undefined when calling createCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCategoryCreateInputToJSON(requestParameters.apiCategoryCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCategoryFromJSON(jsonValue));
    }

    /**
     * Create category
     * Create category
     */
    async createCategory(requestParameters: CreateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiCategory> {
        const response = await this.createCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete category
     * Delete category
     */
    async deleteCategoryRaw(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling deleteCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete category
     * Delete category
     */
    async deleteCategory(requestParameters: DeleteCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCategoryRaw(requestParameters, initOverrides);
    }

    /**
     * Update category
     * Update category
     */
    async updateCategoryRaw(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiCategory>> {
        if (requestParameters.categoryId === null || requestParameters.categoryId === undefined) {
            throw new runtime.RequiredError('categoryId','Required parameter requestParameters.categoryId was null or undefined when calling updateCategory.');
        }

        if (requestParameters.apiCategoryUpdateInput === null || requestParameters.apiCategoryUpdateInput === undefined) {
            throw new runtime.RequiredError('apiCategoryUpdateInput','Required parameter requestParameters.apiCategoryUpdateInput was null or undefined when calling updateCategory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/categories/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters.categoryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiCategoryUpdateInputToJSON(requestParameters.apiCategoryUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiCategoryFromJSON(jsonValue));
    }

    /**
     * Update category
     * Update category
     */
    async updateCategory(requestParameters: UpdateCategoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiCategory> {
        const response = await this.updateCategoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
