import { z } from 'zod'
import { ApiSpaceUpdateInput } from '../../generated/private-api'

const SpaceUpdateTranslationsInputSchema = z.object({
  description: z.string().optional(),
  serviceDescription: z.string().optional(),
  displayName: z.string().optional(),
  qualities: z.string().optional(),
  areaDetails: z.string().optional(),
})

export const SpaceUpdateInputSchema: z.ZodType<ApiSpaceUpdateInput> = z
  .object({
    id: z.string(),
    propertyId: z.string(),
    roomArea: z.coerce.number().nonnegative().optional(),
    minCapacity: z.coerce.number().nonnegative().optional(),
    maxCapacity: z.coerce.number().nonnegative().optional(),
    translations: z
      .object({
        fi: SpaceUpdateTranslationsInputSchema,
        en: SpaceUpdateTranslationsInputSchema.optional(),
      })
      .optional(),
    categories: z.array(z.object({ id: z.string(), nameEn: z.string(), nameFi: z.string() })),
    productDisplayTypes: z.array(
      z.object({
        id: z.string(),
        productTypeId: z.string(),
        nameEn: z.string(),
        nameFi: z.string(),
        reservable: z.boolean(),
      })
    ),
    tempReserved: z.boolean(),
    published: z.boolean(),
    publishedTimestamp: z.string().optional(),
    highlighted: z.boolean(),
    displayName: z.string().optional(),
    price: z.coerce.number().nonnegative().optional(),
    taxRate: z.coerce.number().nonnegative().max(100).optional(),
    priceDetails: z.string().optional(),
    productType: z.object({
      id: z.string(),
      nameFi: z.string(),
      nameEn: z.string(),
      value: z.string(),
      pricingType: z.string(),
    }),
    imageIds: z.array(z.string()), // database ids
    defaultImageId: z.string().optional(),
    pdfIds: z.array(z.string()), // database ids
    defaultBlueprintPdfId: z.string().optional(),
    defaultArrivalInstructionsPdfId: z.string().optional(),
    defaultBrochurePdfId: z.string().optional(),
    services: z
      .array(z.object({ id: z.string(), nameFi: z.string(), nameEn: z.string(), iconUrl: z.string() }))
      .optional(),
    responsibilities: z
      .array(z.object({ id: z.string(), nameFi: z.string(), nameEn: z.string(), iconUrl: z.string() }))
      .optional(),
    employees: z.array(z.object({ id: z.string(), firstName: z.string(), lastName: z.string() })),
  })
  .refine(
    (schema) => (schema.maxCapacity && schema.minCapacity != null ? schema.maxCapacity >= schema.minCapacity : true),
    {
      message: 'maxCapacity should be greater than or equal to minCapacity',
    }
  )
