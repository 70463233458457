import { useTranslation } from 'react-i18next'
import { DateTimeSelectionState } from '../../lib/filter-types/filter-types'
import Calendar from 'react-calendar'
import SelectFilterTime from './SelectFilterTime'
import { iconCalendarArrowLeft, iconCalendarArrowRight } from '../../lib/icons/icons'
import Icon from '../Icon'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { add, isToday } from 'date-fns'

const getFormattedDateStr = (language: string, date: Date) => {
  const str = date.toLocaleDateString(language, { weekday: 'long', day: 'numeric', month: 'long' })
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const DateTimeTab = ({
  selectedDate,
  selectedStartTime,
  selectedEndTime,
  dateTimeSelectionState,
  toggleDateTimeSelectionState,
  onDateSelected,
  setStartTime,
  setEndTime,
}: {
  selectedDate: Date
  selectedStartTime: Date
  selectedEndTime: Date
  dateTimeSelectionState: DateTimeSelectionState
  toggleDateTimeSelectionState: (dateTimeSelectionState: DateTimeSelectionState) => void
  onDateSelected: (date: Date) => void
  setStartTime: (startTime: Date) => void
  setEndTime: (endTime: Date) => void
}) => {
  const { i18n } = useTranslation()

  type Timeslot = { hour: number; minute: number; enabled: boolean }

  const [startTimes] = useState<Timeslot[]>([
    { hour: 8, minute: 0, enabled: true },
    { hour: 8, minute: 30, enabled: true },
    { hour: 9, minute: 0, enabled: true },
    { hour: 9, minute: 30, enabled: true },
    { hour: 10, minute: 0, enabled: true },
    { hour: 10, minute: 30, enabled: true },
    { hour: 11, minute: 0, enabled: true },
    { hour: 11, minute: 30, enabled: true },
    { hour: 12, minute: 0, enabled: true },
    { hour: 12, minute: 30, enabled: true },
    { hour: 13, minute: 0, enabled: true },
    { hour: 13, minute: 30, enabled: true },
    { hour: 14, minute: 0, enabled: true },
    { hour: 14, minute: 30, enabled: true },
    { hour: 15, minute: 0, enabled: true },
    { hour: 15, minute: 30, enabled: true },
  ])

  const [endTimes] = useState(() => {
    const endTimes = _.cloneDeep(startTimes)
    delete endTimes[0]
    const lastStartTime = startTimes[startTimes.length - 2]
    endTimes.push({ hour: (lastStartTime?.hour ?? 0) + 1, minute: lastStartTime?.minute ?? 0, enabled: true })
    return endTimes
  })

  const isEndTimeslotAvailable = (time: Timeslot): boolean => {
    const now = new Date()

    const e1 = time.hour > now.getHours() || (time.hour === now.getHours() && time.minute > now.getMinutes())

    const earliestPossibleEndTime = add(selectedStartTime, { minutes: 30 })

    const e2 =
      time.hour > earliestPossibleEndTime.getHours() ||
      (time.hour === earliestPossibleEndTime.getHours() && time.minute >= earliestPossibleEndTime.getMinutes())

    return e1 && e2
  }

  useEffect(() => {
    // If current calendar date is selected, adjust start time so that it will always be valid.
    // This is necessary in case the state is refreshed by React (without completely reloading
    // the page) For example, if the page is loaded at 12:29, the first timeslot is 12:30. Then
    // when the time is 12:31, the first available timeslot needs to be adjusted to 13:00,
    // otherwise the call to Taitori to check availability will have a start time that's in the
    // past, which will result in an error.
    if (isToday(selectedDate)) {
      const now = new Date()
      if (
        now.getHours() > selectedStartTime.getHours() ||
        (now.getHours() === selectedStartTime.getHours() && now.getMinutes() > selectedStartTime.getMinutes())
      ) {
        let newDate = new Date()
        const nextStartTime = startTimes.find(
          (startTime) =>
            (startTime.hour === now.getHours() && startTime.minute > now.getMinutes()) ||
            now.getHours() < startTime.hour
        )
        if (nextStartTime === undefined) {
          // No matching start time found (might too early or too late)
          const tomorrow = new Date(selectedDate)
          tomorrow.setDate(selectedDate.getDate() + 1)
          const firstTimeslot = startTimes[0]
          tomorrow.setHours(firstTimeslot.hour, firstTimeslot.minute, 0, 0)
          newDate = tomorrow
        } else {
          newDate.setHours(nextStartTime.hour, nextStartTime.minute, 0, 0)
        }
        setStartTime(newDate)
      }
    }
  }, [selectedDate, selectedStartTime, setStartTime, startTimes])

  return (
    <div className='relative z-10 flex flex-col'>
      <div className='filterExpanded pr-0 md:absolute md:ml-[1.5rem] md:w-[38.95rem]'>
        <div className='flex flex-row'>
          <div
            className={`${
              dateTimeSelectionState === DateTimeSelectionState.SelectingDate
                ? 'underline decoration-[1.0px] underline-offset-[1.5px]'
                : ''
            } w-[20rem] cursor-pointer select-none truncate whitespace-nowrap text-2xl md:w-[32rem] md:pl-1`}
            onClick={() => {
              toggleDateTimeSelectionState(DateTimeSelectionState.SelectingDate)
            }}>
            {getFormattedDateStr(i18n.language, selectedDate)}
          </div>
          <div className='hidden w-full md:flex'></div>
          <div className='align-end flex flex-row justify-end md:pr-[0.7rem]'>
            <div
              onClick={() => {
                toggleDateTimeSelectionState(DateTimeSelectionState.SelectingStartTime)
              }}
              className={`${
                dateTimeSelectionState === DateTimeSelectionState.SelectingStartTime
                  ? 'underline decoration-[1.0px] underline-offset-[1.5px]'
                  : ''
              } cursor-pointer select-none truncate whitespace-nowrap text-2xl`}>
              {selectedStartTime.toLocaleTimeString(i18n.language, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
            <div className='select-none truncate whitespace-nowrap px-1 text-2xl md:px-3'>&ndash;</div>
            <div
              onClick={() => {
                toggleDateTimeSelectionState(DateTimeSelectionState.SelectingEndTime)
              }}
              className={`${
                dateTimeSelectionState === DateTimeSelectionState.SelectingEndTime
                  ? 'underline decoration-[1.0px] underline-offset-[1.5px]'
                  : ''
              } cursor-pointer select-none truncate whitespace-nowrap text-2xl md:pr-3`}>
              {selectedEndTime.toLocaleTimeString(i18n.language, {
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Date selector */}
      <div
        className={`${
          dateTimeSelectionState === DateTimeSelectionState.SelectingDate ? '' : 'hidden'
        } flex h-[21rem] cursor-pointer select-none overflow-x-hidden bg-white text-2xl md:absolute md:top-[2.75rem] md:ml-[1.5rem] md:w-[20rem] md:border-[1.5px] md:border-t-0 md:px-4`}>
        <Calendar
          minDetail='year'
          value={selectedDate}
          minDate={new Date()}
          prevLabel={<Icon icon={iconCalendarArrowLeft} className='h-[2.2rem] w-[2.2rem]' />}
          prev2Label={null}
          nextLabel={<Icon icon={iconCalendarArrowRight} className='h-[2.2rem] w-[2.2rem]' />}
          next2Label={null}
          onChange={onDateSelected}
          locale={i18n.language}
        />
      </div>

      {/* Start time selector */}
      <div className={`${dateTimeSelectionState === DateTimeSelectionState.SelectingStartTime ? '' : 'hidden'}`}>
        <div
          id='selectTime'
          className='absolute right-[1.65rem] top-[3.25rem] flex h-[12.5rem] w-[6rem] cursor-pointer select-none flex-col justify-items-center overflow-x-hidden border-[2px] border-t-0 bg-white text-center text-2xl md:top-[2.75rem] md:right-[5.95rem] md:border-[1.5px] md:border-t-0'>
          {startTimes.map((time) => {
            const now = new Date()
            return (
              <SelectFilterTime
                key={`${time.hour}.${time.minute}`}
                hour={time.hour}
                minute={time.minute}
                enabled={
                  isToday(selectedDate)
                    ? time.hour > now.getHours() || (time.hour === now.getHours() && time.minute > now.getMinutes())
                    : true
                }
                selected={time.hour === selectedStartTime.getHours() && time.minute === selectedStartTime.getMinutes()}
                handleSelection={setStartTime}
              />
            )
          })}
        </div>
        <div className='border-darkblue absolute top-[3.125rem] right-[1.575rem] h-[12.625rem] w-[0.2rem] border-[2px] border-l-0 bg-white md:right-[5.875rem] md:top-[2.75rem] md:h-[12.5rem] md:border-[1.5px] md:border-l-0 md:border-t-0' />
        <div className='absolute top-[3.125rem] right-[1.675rem] flex h-[0px] w-[5.87rem] border-[1px] border-white md:hidden' />
      </div>

      {/* End time selector */}
      <div className={`${dateTimeSelectionState === DateTimeSelectionState.SelectingEndTime ? '' : 'hidden'}`}>
        <div
          id='selectTime'
          className={`absolute top-[3.25rem] right-[-1.275rem] flex h-[12.5rem] w-[6rem] cursor-pointer select-none flex-col justify-items-center overflow-x-hidden border-[2px] border-t-0 bg-white text-center text-2xl md:right-[1.68rem] md:top-[2.75rem] md:border-[1.5px] md:border-t-0`}>
          {endTimes.map((time) => {
            return (
              <SelectFilterTime
                key={`${time.hour}.${time.minute}`}
                hour={time.hour}
                minute={time.minute}
                enabled={isToday(selectedDate) ? isEndTimeslotAvailable(time) : true}
                selected={time.hour === selectedEndTime.getHours() && time.minute === selectedEndTime.getMinutes()}
                handleSelection={setEndTime}
              />
            )
          })}
        </div>
        <div className='border-darkblue absolute right-[-1.375rem] top-[3.25rem] h-[12.5rem] w-[0.225rem] border-[2px] border-t-0 border-l-0 bg-white md:right-[1.535rem] md:top-[2.75rem] md:border-[1.5px] md:border-l-0 md:border-t-0' />
        <div className='absolute top-[3.125rem] right-[-1.25rem] flex h-[0px] w-[5.85rem] border-[1px] border-white md:hidden' />
      </div>
    </div>
  )
}
