import NextImage, { ImageLoader, ImageProps } from 'next/legacy/image'

// This component is a wrapper over next/image that uses a custom image loader to skip image optimization NextJS
// static export. This current unoptimized approach is adapted from https://github.com/vercel/next.js/discussions/19065.
//
// TODO: Consider using a static image optimization library like
// https://next-export-optimize-images.vercel.app/ to optimize images at build time.
//
const customLoader: ImageLoader = ({ src }) => {
  return src
}

const Image = (props: ImageProps) => {
  return <NextImage loader={customLoader} unoptimized {...props} />
}

export default Image
