import { z } from 'zod'
import { ApiProductDisplayTypeCreateInput, ApiProductDisplayTypeUpdateInput } from '../../generated/private-api'

export const ProductDisplayTypeCreateInputSchema: z.ZodType<ApiProductDisplayTypeCreateInput> = z.object({
  productTypeId: z.string(),
  nameFi: z.string(),
  nameEn: z.string(),
  reservable: z.boolean(),
})

export const ProductDisplayTypeUpdateInputSchema: z.ZodType<ApiProductDisplayTypeUpdateInput> = z.object({
  id: z.string(),
  productTypeId: z.string(),
  nameFi: z.string(),
  nameEn: z.string(),
  reservable: z.boolean(),
})
