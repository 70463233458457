/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiAdminSettingsInput
 */
export interface ApiAdminSettingsInput {
    /**
     * 
     * @type {number}
     * @memberof ApiAdminSettingsInput
     */
    reservationCancellableBeforeHours: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAdminSettingsInput
     */
    invoiceRecipient: string;
    /**
     * 
     * @type {number}
     * @memberof ApiAdminSettingsInput
     */
    vatPercent: number;
}

/**
 * Check if a given object implements the ApiAdminSettingsInput interface.
 */
export function instanceOfApiAdminSettingsInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reservationCancellableBeforeHours" in value;
    isInstance = isInstance && "invoiceRecipient" in value;
    isInstance = isInstance && "vatPercent" in value;

    return isInstance;
}

export function ApiAdminSettingsInputFromJSON(json: any): ApiAdminSettingsInput {
    return ApiAdminSettingsInputFromJSONTyped(json, false);
}

export function ApiAdminSettingsInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiAdminSettingsInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reservationCancellableBeforeHours': json['reservationCancellableBeforeHours'],
        'invoiceRecipient': json['invoiceRecipient'],
        'vatPercent': json['vatPercent'],
    };
}

export function ApiAdminSettingsInputToJSON(value?: ApiAdminSettingsInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reservationCancellableBeforeHours': value.reservationCancellableBeforeHours,
        'invoiceRecipient': value.invoiceRecipient,
        'vatPercent': value.vatPercent,
    };
}

