import { z } from 'zod'
import { ApiEmployeeCreateInput, ApiEmployeeUpdateInput } from '../../generated/private-api'

export const EmployeeCreateInputSchema: z.ZodType<ApiEmployeeCreateInput> = z.object({
  firstName: z.string(),
  lastName: z.string(),
  roleName: z.string().optional(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
})

export const EmployeeUpdateInputSchema: z.ZodType<ApiEmployeeUpdateInput> = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  roleName: z.string().optional(),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
})
