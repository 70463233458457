import { ApiCategory, ApiProductDisplayType } from '@antilooppi/public-api'
import { FilterSelectedItem } from '../../lib/filter-types/filter-types'
import { useTranslation } from 'react-i18next'
import { keywordTranslation } from '../../lib/categories'
import Button from '../Button'

const ProductTypesAndKeywords = ({
  productDisplayTypes,
  selectedProductDisplayTypes,
  keywords,
  selectedKeywords,
  selectProductDisplayTypes,
  selectKeywords,
}: {
  productDisplayTypes: ApiProductDisplayType[]
  selectedProductDisplayTypes: FilterSelectedItem[]
  keywords: ApiCategory[]
  selectedKeywords: FilterSelectedItem[]
  selectProductDisplayTypes: (productDisplayTypes: FilterSelectedItem[]) => void
  selectKeywords: (keywords: FilterSelectedItem[]) => void
}) => {
  const { i18n, t } = useTranslation()

  return (
    <div className='relative z-10 md:ml-[1.5rem] md:w-[38.95rem]'>
      <div className='filterExpanded md:absolute'>
        <div className='select-none text-xl'>{t('spaceType')}</div>
        <div className='flex flex-wrap gap-2'>
          {productDisplayTypes?.map((productDisplayType) => (
            <div
              className={`w-fit whitespace-nowrap py-0 px-4 text-2xl ${
                selectedProductDisplayTypes?.find((pdt) => pdt.id === productDisplayType.id)
                  ? 'filterSelectionLocationItemSelected'
                  : 'filterSelectionLocationItem'
              }`}
              key={productDisplayType.id}
              onClick={() => {
                if (selectedProductDisplayTypes?.find((pdt) => pdt.id === productDisplayType.id)) {
                  // Selected item found from array, remove it
                  selectProductDisplayTypes(
                    selectedProductDisplayTypes.filter((pdt) => pdt.id !== productDisplayType.id)
                  )
                } else {
                  // Selected item not found from array, add it
                  selectProductDisplayTypes([
                    ...(selectedProductDisplayTypes || []),
                    {
                      id: productDisplayType.id,
                      name: i18n.language === 'fi' ? productDisplayType.nameFi : productDisplayType.nameEn,
                      reservable: productDisplayType.reservable,
                    },
                  ])
                }
              }}>
              {keywordTranslation(productDisplayType)}
            </div>
          ))}
          {selectedProductDisplayTypes.length > 1 && (
            <div className='mr-4 flex w-fit flex-grow flex-row justify-end'>
              <Button text={t('empty')} size='empty' theme='blue' onClick={() => selectProductDisplayTypes([])} />
            </div>
          )}
        </div>
        <div className='mt-4 select-none text-xl'>{t('keywords')}</div>
        <div className='flex flex-wrap gap-2'>
          {keywords?.map((keyword) => (
            <div
              className={`w-fit whitespace-nowrap py-0 px-4 text-2xl ${
                selectedKeywords?.find((word) => word.id === keyword.id)
                  ? 'filterSelectionLocationItemSelected'
                  : 'filterSelectionLocationItem'
              }`}
              key={keyword.id}
              onClick={() => {
                if (selectedKeywords?.find((word) => word.id === keyword.id)) {
                  // Selected item found from array, remove it
                  selectKeywords(selectedKeywords.filter((word) => word.id !== keyword.id))
                } else {
                  // Selected item not found from array, add it
                  selectKeywords([
                    ...(selectedKeywords || []),
                    {
                      id: keyword.id,
                      name: i18n.language === 'fi' ? keyword.nameFi : keyword.nameEn,
                    },
                  ])
                }
              }}>
              {keywordTranslation(keyword)}
            </div>
          ))}
          {selectedKeywords.length > 1 && (
            <div className='mr-4 flex w-fit flex-grow flex-row justify-end'>
              <Button text={t('empty')} size='empty' theme='blue' onClick={() => selectKeywords([])} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductTypesAndKeywords
