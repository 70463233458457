/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiCategory } from './ApiCategory';
import {
    ApiCategoryFromJSON,
    ApiCategoryFromJSONTyped,
    ApiCategoryToJSON,
} from './ApiCategory';
import type { ApiDistance } from './ApiDistance';
import {
    ApiDistanceFromJSON,
    ApiDistanceFromJSONTyped,
    ApiDistanceToJSON,
} from './ApiDistance';
import type { ApiDistrict } from './ApiDistrict';
import {
    ApiDistrictFromJSON,
    ApiDistrictFromJSONTyped,
    ApiDistrictToJSON,
} from './ApiDistrict';
import type { ApiPropertyLobbyInfos } from './ApiPropertyLobbyInfos';
import {
    ApiPropertyLobbyInfosFromJSON,
    ApiPropertyLobbyInfosFromJSONTyped,
    ApiPropertyLobbyInfosToJSON,
} from './ApiPropertyLobbyInfos';
import type { ApiResponsibility } from './ApiResponsibility';
import {
    ApiResponsibilityFromJSON,
    ApiResponsibilityFromJSONTyped,
    ApiResponsibilityToJSON,
} from './ApiResponsibility';
import type { ApiService } from './ApiService';
import {
    ApiServiceFromJSON,
    ApiServiceFromJSONTyped,
    ApiServiceToJSON,
} from './ApiService';

/**
 * 
 * @export
 * @interface ApiPropertyUpdateInput
 */
export interface ApiPropertyUpdateInput {
    /**
     * 
     * @type {string}
     * @memberof ApiPropertyUpdateInput
     */
    id: string;
    /**
     * 
     * @type {ApiPropertyLobbyInfos}
     * @memberof ApiPropertyUpdateInput
     */
    translations?: ApiPropertyLobbyInfos;
    /**
     * 
     * @type {Array<ApiCategory>}
     * @memberof ApiPropertyUpdateInput
     */
    categories: Array<ApiCategory>;
    /**
     * 
     * @type {Array<ApiDistrict>}
     * @memberof ApiPropertyUpdateInput
     */
    districts: Array<ApiDistrict>;
    /**
     * 
     * @type {Array<ApiService>}
     * @memberof ApiPropertyUpdateInput
     */
    services: Array<ApiService>;
    /**
     * 
     * @type {Array<ApiResponsibility>}
     * @memberof ApiPropertyUpdateInput
     */
    responsibilities: Array<ApiResponsibility>;
    /**
     * 
     * @type {Array<ApiDistance>}
     * @memberof ApiPropertyUpdateInput
     */
    distances: Array<ApiDistance>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPropertyUpdateInput
     */
    imageIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPropertyUpdateInput
     */
    defaultImageId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiPropertyUpdateInput
     */
    pdfIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiPropertyUpdateInput
     */
    defaultBlueprintPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPropertyUpdateInput
     */
    defaultArrivalInstructionsPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPropertyUpdateInput
     */
    defaultBrochurePdfId?: string;
}

/**
 * Check if a given object implements the ApiPropertyUpdateInput interface.
 */
export function instanceOfApiPropertyUpdateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "districts" in value;
    isInstance = isInstance && "services" in value;
    isInstance = isInstance && "responsibilities" in value;
    isInstance = isInstance && "distances" in value;

    return isInstance;
}

export function ApiPropertyUpdateInputFromJSON(json: any): ApiPropertyUpdateInput {
    return ApiPropertyUpdateInputFromJSONTyped(json, false);
}

export function ApiPropertyUpdateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPropertyUpdateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'translations': !exists(json, 'translations') ? undefined : ApiPropertyLobbyInfosFromJSON(json['translations']),
        'categories': ((json['categories'] as Array<any>).map(ApiCategoryFromJSON)),
        'districts': ((json['districts'] as Array<any>).map(ApiDistrictFromJSON)),
        'services': ((json['services'] as Array<any>).map(ApiServiceFromJSON)),
        'responsibilities': ((json['responsibilities'] as Array<any>).map(ApiResponsibilityFromJSON)),
        'distances': ((json['distances'] as Array<any>).map(ApiDistanceFromJSON)),
        'imageIds': !exists(json, 'imageIds') ? undefined : json['imageIds'],
        'defaultImageId': !exists(json, 'defaultImageId') ? undefined : json['defaultImageId'],
        'pdfIds': !exists(json, 'pdfIds') ? undefined : json['pdfIds'],
        'defaultBlueprintPdfId': !exists(json, 'defaultBlueprintPdfId') ? undefined : json['defaultBlueprintPdfId'],
        'defaultArrivalInstructionsPdfId': !exists(json, 'defaultArrivalInstructionsPdfId') ? undefined : json['defaultArrivalInstructionsPdfId'],
        'defaultBrochurePdfId': !exists(json, 'defaultBrochurePdfId') ? undefined : json['defaultBrochurePdfId'],
    };
}

export function ApiPropertyUpdateInputToJSON(value?: ApiPropertyUpdateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'translations': ApiPropertyLobbyInfosToJSON(value.translations),
        'categories': ((value.categories as Array<any>).map(ApiCategoryToJSON)),
        'districts': ((value.districts as Array<any>).map(ApiDistrictToJSON)),
        'services': ((value.services as Array<any>).map(ApiServiceToJSON)),
        'responsibilities': ((value.responsibilities as Array<any>).map(ApiResponsibilityToJSON)),
        'distances': ((value.distances as Array<any>).map(ApiDistanceToJSON)),
        'imageIds': value.imageIds,
        'defaultImageId': value.defaultImageId,
        'pdfIds': value.pdfIds,
        'defaultBlueprintPdfId': value.defaultBlueprintPdfId,
        'defaultArrivalInstructionsPdfId': value.defaultArrivalInstructionsPdfId,
        'defaultBrochurePdfId': value.defaultBrochurePdfId,
    };
}

