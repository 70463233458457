/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiEmployee,
  ApiEmployeeCreateInput,
  ApiEmployeeUpdateInput,
} from '../models';
import {
    ApiEmployeeFromJSON,
    ApiEmployeeToJSON,
    ApiEmployeeCreateInputFromJSON,
    ApiEmployeeCreateInputToJSON,
    ApiEmployeeUpdateInputFromJSON,
    ApiEmployeeUpdateInputToJSON,
} from '../models';

export interface CreateEmployeeRequest {
    apiEmployeeCreateInput: ApiEmployeeCreateInput;
}

export interface DeleteEmployeeRequest {
    employeeId: string;
}

export interface GetEmployeeRequest {
    employeeId: string;
}

export interface UpdateEmployeeRequest {
    employeeId: string;
    apiEmployeeUpdateInput: ApiEmployeeUpdateInput;
}

/**
 * 
 */
export class EmployeesPrivateApi extends runtime.BaseAPI {

    /**
     * Create employee
     * Create employee
     */
    async createEmployeeRaw(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiEmployee>> {
        if (requestParameters.apiEmployeeCreateInput === null || requestParameters.apiEmployeeCreateInput === undefined) {
            throw new runtime.RequiredError('apiEmployeeCreateInput','Required parameter requestParameters.apiEmployeeCreateInput was null or undefined when calling createEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiEmployeeCreateInputToJSON(requestParameters.apiEmployeeCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiEmployeeFromJSON(jsonValue));
    }

    /**
     * Create employee
     * Create employee
     */
    async createEmployee(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiEmployee> {
        const response = await this.createEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete employee
     * Delete employee
     */
    async deleteEmployeeRaw(requestParameters: DeleteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling deleteEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete employee
     * Delete employee
     */
    async deleteEmployee(requestParameters: DeleteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmployeeRaw(requestParameters, initOverrides);
    }

    /**
     * Get an employee
     * Employee
     */
    async getEmployeeRaw(requestParameters: GetEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiEmployee>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling getEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiEmployeeFromJSON(jsonValue));
    }

    /**
     * Get an employee
     * Employee
     */
    async getEmployee(requestParameters: GetEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiEmployee> {
        const response = await this.getEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update employee
     * Update employee
     */
    async updateEmployeeRaw(requestParameters: UpdateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiEmployee>> {
        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling updateEmployee.');
        }

        if (requestParameters.apiEmployeeUpdateInput === null || requestParameters.apiEmployeeUpdateInput === undefined) {
            throw new runtime.RequiredError('apiEmployeeUpdateInput','Required parameter requestParameters.apiEmployeeUpdateInput was null or undefined when calling updateEmployee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/{employeeId}`.replace(`{${"employeeId"}}`, encodeURIComponent(String(requestParameters.employeeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiEmployeeUpdateInputToJSON(requestParameters.apiEmployeeUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiEmployeeFromJSON(jsonValue));
    }

    /**
     * Update employee
     * Update employee
     */
    async updateEmployee(requestParameters: UpdateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiEmployee> {
        const response = await this.updateEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
