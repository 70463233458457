import { BrowserAuthOptions, LogLevel, RedirectRequest } from '@azure/msal-browser'
import { makeAuthLogger } from './authLogger'
import { environment } from '../../environment'

const hostname = environment.getHostname()
const b2cClientId = environment.getB2CClientId()
const b2cTenantName = environment.getB2CTenantName()
const b2cApiAudience = environment.getB2CApiAudience()
const b2cHostName = environment.getB2CHostName()

const apiNamespace = `https://${b2cTenantName}.onmicrosoft.com/${b2cApiAudience}`
const apiScopes = [`${apiNamespace}/Api.Call`, 'offline_access', 'openid']

const makeAuthOptions = (): BrowserAuthOptions => {
  return {
    clientId: b2cClientId,
    authority: policies.signupsignin.authority,
    knownAuthorities: [b2cHostName],
    redirectUri: `${hostname}/auth/callback`,
    postLogoutRedirectUri: hostname,
    navigateToLoginRequestUrl: true,
  }
}

export const getAuthConfig = () => {
  return {
    auth: makeAuthOptions(),
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: makeAuthLogger(LogLevel.Warning),
      },
    },
  }
}

export type PolicyName = 'signupsignin' | 'signup' | 'antilooppisignupsignin'

export const policies: Record<PolicyName, RedirectRequest> = {
  signupsignin: {
    scopes: apiScopes,
    authority: `https://${b2cHostName}/${b2cTenantName}.onmicrosoft.com/B2C_1_signupsignin`,
  },
  signup: {
    scopes: apiScopes,
    authority: `https://${b2cHostName}/${b2cTenantName}.onmicrosoft.com/B2C_1_signup`,
  },
  antilooppisignupsignin: {
    scopes: apiScopes,
    authority: `https://${b2cHostName}/${b2cTenantName}.onmicrosoft.com/B2C_1_antilooppisignupsignin`,
    prompt: 'select_account',
  },
}
