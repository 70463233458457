import { usePublicApi } from '../usePublicApi'
import { useMutation } from '@tanstack/react-query'
import { ApiError, ApiLeadInput } from '@antilooppi/public-api'
import { useAuth } from '../auth/useAuth'

export const useCreateLead = () => {
  const { auth } = useAuth()
  const { crmApi } = usePublicApi(auth.user)
  return useMutation<unknown, ApiError, ApiLeadInput>((input) => crmApi.sendLead({ apiLeadInput: input }))
}
