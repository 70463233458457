import { Portal } from './Portal'
import React, { Children, isValidElement, ReactNode, Ref, useEffect } from 'react'
import { useSidebar } from '../lib/useSidebar'
import { useRouter } from 'next/router'

type ModalDialogWithRefProps = {
  visible: boolean
  onHide: () => void
  children?: ReactNode
  forwardedRef?: Ref<HTMLDivElement> | null
}

// A modal dialog window to be used e.g. in mobile views. This uses the existing SideBar
// implementation, which could be further refactored to accept the <Portal> content.
const ModalDialogWithRef = ({ visible, onHide, children, forwardedRef }: ModalDialogWithRefProps) => {
  const router = useRouter()

  const { sidebarContent, hideSidebar } = useSidebar()

  useEffect(() => {
    const onRouteChangeStart = () => {
      hideSidebar()
    }
    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [hideSidebar, router.events])

  useEffect(() => {
    if (visible) {
      document.querySelector('body')?.classList.add('sidebar-open')
    } else {
      document.querySelector('body')?.classList.remove('sidebar-open')
    }
  }, [visible])

  return visible ? (
    <Portal wrapperId='sidebar'>
      <div className='border-darkblue absolute right-4 left-4 top-1/4 z-40 min-h-fit border-2'>
        <div className='bg-offwhite h-full w-full overflow-y-auto px-2.5 py-2.5' ref={forwardedRef}>
          {sidebarContent ||
            Children.map(children, (child) => {
              return isValidElement(child) ? child : null
            })}
          {/* <Button text={t('cancel')} className='mt-4 mb-4 w-full md:hidden' onClick={onHide} /> */}
        </div>
      </div>
      <div className='bg-darkblue fixed left-0 top-0 z-30 h-screen w-screen opacity-[50%]' onClick={onHide} />
    </Portal>
  ) : null
}

export type SidebarProps = Omit<ModalDialogWithRefProps, 'forwardedRef'>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ModalDialog = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => (
  <ModalDialogWithRef forwardedRef={ref} visible={props.visible} onHide={props.onHide}>
    {props.children}
  </ModalDialogWithRef>
))
ModalDialog.displayName = 'ModalDialog'

export default ModalDialog
