import {
  AdminSettingsPrivateApi,
  CategoriesPrivateApi,
  Configuration,
  ImagesPrivateApi,
  InvoicingPrivateApi,
  PdfsPrivateApi,
  PropertiesPrivateApi,
  SpacesPrivateApi,
  UserReservationsPrivateApi,
  UsersPrivateApi,
  DistrictsPrivateApi,
  ProductDisplayTypesPrivateApi,
  ServicesPrivateApi,
  ResponsibilitiesPrivateApi,
  DistancesPrivateApi,
  IconsPrivateApi,
  EmployeesPrivateApi,
} from '@antilooppi/private-api'
import { environment } from '../environment'
import { UseAuthUser } from './auth/useAuth'

export interface PrivateApi {
  categoriesApi: CategoriesPrivateApi
  imagesApi: ImagesPrivateApi
  pdfsApi: PdfsPrivateApi
  propertiesApi: PropertiesPrivateApi
  spacesApi: SpacesPrivateApi
  usersApi: UsersPrivateApi
  adminSettingsApi: AdminSettingsPrivateApi
  invoicingApi: InvoicingPrivateApi
  userReservationsApi: UserReservationsPrivateApi
  districtsApi: DistrictsPrivateApi
  productDisplayTypesApi: ProductDisplayTypesPrivateApi
  servicesApi: ServicesPrivateApi
  responsibilitiesApi: ResponsibilitiesPrivateApi
  distancesApi: DistancesPrivateApi
  iconsApi: IconsPrivateApi
  employeesApi: EmployeesPrivateApi
}

const createClientConfiguration = (user: UseAuthUser | null): Configuration => {
  return new Configuration({
    basePath: environment.getPrivateApiUrl(),
    apiKey: environment.getPrivateApiKey(),
    accessToken: user?.accessToken,
  })
}

const createApis = (configuration: Configuration): PrivateApi => {
  return {
    categoriesApi: new CategoriesPrivateApi(configuration),
    imagesApi: new ImagesPrivateApi(configuration),
    pdfsApi: new PdfsPrivateApi(configuration),
    propertiesApi: new PropertiesPrivateApi(configuration),
    spacesApi: new SpacesPrivateApi(configuration),
    usersApi: new UsersPrivateApi(configuration),
    adminSettingsApi: new AdminSettingsPrivateApi(configuration),
    invoicingApi: new InvoicingPrivateApi(configuration),
    userReservationsApi: new UserReservationsPrivateApi(configuration),
    districtsApi: new DistrictsPrivateApi(configuration),
    productDisplayTypesApi: new ProductDisplayTypesPrivateApi(configuration),
    servicesApi: new ServicesPrivateApi(configuration),
    responsibilitiesApi: new ResponsibilitiesPrivateApi(configuration),
    distancesApi: new DistancesPrivateApi(configuration),
    iconsApi: new IconsPrivateApi(configuration),
    employeesApi: new EmployeesPrivateApi(configuration),
  }
}

export const usePrivateApi = (user: UseAuthUser | null): PrivateApi => {
  return createApis(createClientConfiguration(user))
}
