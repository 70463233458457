import { z } from 'zod'
import { ApiAgentMaterialCreateInput, ApiAgentMaterialDeleteInput } from '../../generated/private-api'

export const AgentMaterialCreateInputSchema: z.ZodType<ApiAgentMaterialCreateInput> = z.object({
  cutoffDate: z.string(),
})

export const AgentMaterialDeleteInputSchema: z.ZodType<ApiAgentMaterialDeleteInput> = z.object({
  filename: z.string(),
})
