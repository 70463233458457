import React, { useRef } from 'react'
import { getTrackBackground, Range } from 'react-range'
import FilterRangeLabel from './FilterRangeLabel'

const FilterRange = ({
  min,
  max,
  step,
  values,
  showLabels = true,
  disabled = false,
  onValuesChanged,
}: {
  min: number
  max: number
  step: number
  values: (number | undefined)[]
  showLabels?: boolean
  disabled?: boolean
  onValuesChanged?: (values: (number | undefined)[]) => void
}) => {
  const mapInputValues = (values: (number | undefined)[]): number[] => {
    return [values[0] ?? min, values[1] ?? max]
  }
  const mapOutputValues = (values: number[]): (number | undefined)[] => {
    return [values[0] === min ? undefined : values[0], values[1] === max ? undefined : values[1]]
  }

  const rangeRef = useRef<Range>(null)

  return (
    <>
      <div className='px-[22px] pt-5 pb-4'>
        <Range
          ref={rangeRef}
          step={step}
          min={min}
          max={max}
          values={mapInputValues(values)}
          onChange={(values) => {
            if (onValuesChanged) {
              onValuesChanged(mapOutputValues(values))
            }
          }}
          renderThumb={({ props, index }) => (
            <div
              {...props}
              className={`border-blue bg-green flex h-[38px] w-[38px] items-center justify-center rounded-full py-1 text-center text-lg`}>
              {showLabels && (
                <FilterRangeLabel
                  rangeRef={rangeRef.current}
                  values={mapInputValues(values)}
                  index={index}
                  step={step}
                  max={max}
                />
              )}
            </div>
          )}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={props.style}
              className='flex h-[19px] w-full'>
              <div
                ref={props.ref}
                className='bg-gray flex h-[2px] w-full self-center rounded-md'
                style={
                  disabled
                    ? {}
                    : {
                        background: getTrackBackground({
                          values: mapInputValues(values),
                          colors: ['#1C1C41', '#BADFA4', '#1C1C41'],
                          min: min,
                          max: max,
                        }),
                      }
                }>
                {children}
              </div>
              <div
                className={`${
                  disabled ? 'bg-gray' : 'bg-blue'
                } absolute -left-[19px] -z-10 h-[2px] w-5 self-center`}></div>
              <div
                className={`${
                  disabled ? 'bg-gray' : 'bg-blue'
                } absolute -right-[19px] -z-10 h-[2px] w-5 self-center`}></div>
            </div>
          )}
        />
      </div>
    </>
  )
}

export default FilterRange
