import { z } from 'zod'
import { ApiCategoryCreateInput, ApiCategoryUpdateInput } from '../../generated/private-api'

export const CategoryCreateInputSchema: z.ZodType<ApiCategoryCreateInput> = z.object({
  nameEn: z.string(),
  nameFi: z.string(),
})

export const CategoryUpdateInputSchema: z.ZodType<ApiCategoryUpdateInput> = z.object({
  id: z.string(),
  nameEn: z.string(),
  nameFi: z.string(),
})
