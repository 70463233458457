import { z } from 'zod'
import { ApiDistrictCreateInput, ApiDistrictUpdateInput } from '../../generated/private-api'

export const DistrictCreateInputSchema: z.ZodType<ApiDistrictCreateInput> = z.object({
  name: z.string(),
})

export const DistrictUpdateInputSchema: z.ZodType<ApiDistrictUpdateInput> = z.object({
  id: z.string(),
  name: z.string(),
})
