import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { useAuth } from '../auth/useAuth'
import { usePrivateApi } from '../usePrivateApi'
import { ApiError, ApiProductDisplayType } from '@antilooppi/public-api'
import { ApiProductDisplayTypeCreateInput, ApiProductDisplayTypeUpdateInput } from '@antilooppi/private-api'
import { usePublicApi } from '../usePublicApi'

// Product display types, public to the users (as opposed to product types)
export const useProductDisplayTypes = () => {
  const { auth } = useAuth()
  const { productDisplayTypesApi } = usePublicApi(auth.user)
  return useQuery<ApiProductDisplayType[], ApiError>(
    [auth?.user?.username, 'productDisplayTypes'],
    () => productDisplayTypesApi.listProductDisplayTypes(),
    {
      staleTime: 60000,
      useErrorBoundary: true, // Throw an error on error, show the 'Something went wrong' page
    }
  )
}

export const useCreateProductDisplayType = () => {
  const { auth } = useAuth()
  const { productDisplayTypesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiProductDisplayType, ApiError, ApiProductDisplayTypeCreateInput>(
    (input) => productDisplayTypesApi.createProductDisplayType({ apiProductDisplayTypeCreateInput: input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'productDisplayTypes'])
      },
    }
  )
}

export const useUpdateProductDisplayType = () => {
  const { auth } = useAuth()
  const { productDisplayTypesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiProductDisplayType, ApiError, ApiProductDisplayTypeUpdateInput>(
    (input) =>
      productDisplayTypesApi.updateProductDisplayType({
        productDisplayTypeId: input.id,
        apiProductDisplayTypeUpdateInput: input,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'productDisplayTypes'])
      },
    }
  )
}

export const useDeleteProductDisplayType = () => {
  const { auth } = useAuth()
  const { productDisplayTypesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<unknown, ApiError, { id: string }>(
    (input) => productDisplayTypesApi.deleteProductDisplayType({ productDisplayTypeId: input.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'productDisplayTypes'])
      },
    }
  )
}
