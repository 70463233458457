import { ReactNode, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

function createWrapperAndAppendToBody(wrapperId: string) {
  const wrapperElement = document.createElement('div')
  wrapperElement.setAttribute('id', wrapperId)
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

export function Portal({ children, wrapperId = 'wrapper' }: { children: ReactNode; wrapperId: string }) {
  const [element, setElement] = useState<Element | DocumentFragment>()

  useEffect(() => {
    let welement = document.getElementById(wrapperId)
    let created = false

    if (!welement) {
      created = true
      welement = createWrapperAndAppendToBody(wrapperId)
    }

    setElement(welement)

    return () => {
      if (created && element?.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }, [element, wrapperId])

  // wrapperElement state will be null on the very first render.
  if (!element) return null

  return createPortal(children, element)
}
