/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiTaitoriAvailableSpace,
  ApiTaitoriAvailableSpaceInput,
} from '../models';
import {
    ApiTaitoriAvailableSpaceFromJSON,
    ApiTaitoriAvailableSpaceToJSON,
    ApiTaitoriAvailableSpaceInputFromJSON,
    ApiTaitoriAvailableSpaceInputToJSON,
} from '../models';

export interface ListAvailableSpacesRequest {
    apiTaitoriAvailableSpaceInput: ApiTaitoriAvailableSpaceInput;
}

/**
 * 
 */
export class TaitoriAvailableSpacesPublicApi extends runtime.BaseAPI {

    /**
     * Taitori available spaces
     * Taitori available spaces
     */
    async listAvailableSpacesRaw(requestParameters: ListAvailableSpacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiTaitoriAvailableSpace>>> {
        if (requestParameters.apiTaitoriAvailableSpaceInput === null || requestParameters.apiTaitoriAvailableSpaceInput === undefined) {
            throw new runtime.RequiredError('apiTaitoriAvailableSpaceInput','Required parameter requestParameters.apiTaitoriAvailableSpaceInput was null or undefined when calling listAvailableSpaces.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/taitori-available-spaces`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiTaitoriAvailableSpaceInputToJSON(requestParameters.apiTaitoriAvailableSpaceInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiTaitoriAvailableSpaceFromJSON));
    }

    /**
     * Taitori available spaces
     * Taitori available spaces
     */
    async listAvailableSpaces(requestParameters: ListAvailableSpacesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiTaitoriAvailableSpace>> {
        const response = await this.listAvailableSpacesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
