import { z } from 'zod'
import { ApiOppoInput } from '../../generated/public-api'

export const OppoInputSchema: z.ZodType<ApiOppoInput> = z.object({
  businessId: z.string(),
  companyName: z.string().optional(),
  description: z.string().optional(),
  email: z.string().min(1).email(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  phoneNumber: z.string().optional(),
  productDisplayTypes: z.array(z.string()),
  productType: z.enum(['pool_meet', 'pool_work', 'always_ready', 'tailor_made']).optional(),
  propertyId: z.string().optional(),
  propertyName: z.string().optional(),
  spaceId: z.string().optional(),
  spaceName: z.string().optional(),
})
