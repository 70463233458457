import { useIsAuthenticated } from '@azure/msal-react'
import { useRouter } from 'next/router'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useCurrentUser } from '../api/current-user'
import usePrevious from '../usePrevious'
import { useAuth } from './useAuth'

export const ADMIN_PATH = '/admin'
export const ADMIN_READ_ROLE = 'ADMIN_READ'

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = useIsAuthenticated()

  const { auth, isLoading } = useAuth()
  const prevIsLoading = usePrevious(isLoading)

  const router = useRouter()
  const isProtectedPath = router.pathname.toLowerCase().startsWith(ADMIN_PATH)
  const [isAuthenticating, setIsAuthenticating] = useState(isProtectedPath)

  const { data: currentUser } = useCurrentUser()

  // Wait until MSAL has started and then finished its interaction.
  useEffect(() => {
    if (prevIsLoading === true && !isLoading) {
      setIsAuthenticating(false)
    }
  }, [isLoading, prevIsLoading])

  // Handle access to protected path
  useEffect(() => {
    if (isProtectedPath) {
      if (isAuthenticated) {
        // Prevent unauthorized access to admin
        if (currentUser && !currentUser.roles.includes(ADMIN_READ_ROLE) && isProtectedPath) {
          router.replace('/')
        }
      } else {
        // Automatically redirect to admin login if user is accessing admin
        if (!isAuthenticating && isProtectedPath) {
          auth.instance.login('antilooppisignupsignin')
        }
      }
    }
  }, [auth.instance, currentUser, isAuthenticated, isAuthenticating, isProtectedPath, router])

  if (!isProtectedPath || (currentUser && currentUser.roles.includes(ADMIN_READ_ROLE))) {
    return <div>{children}</div>
  }
  return null
}
