import Spinner from '../Spinner'

const FilterLoadingSpinner = () => {
  return (
    <div className='bg-offwhite animate-fadeIn flex h-fit w-full flex-row justify-center'>
      <Spinner />
    </div>
  )
}

export default FilterLoadingSpinner
