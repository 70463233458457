export type EnvironmentType = 'local' | 'dev' | 'test' | 'prod'

export abstract class Environment {
  protected constructor(public value: EnvironmentType) {}

  protected static resolveEnvironment(deployEnv?: string): EnvironmentType {
    const appEnv = this.resolveEnvironmentFromValue(deployEnv)
    if (process.env.NODE_ENV === 'development') {
      return 'local'
    }
    if (!appEnv) {
      throw new Error(`Invalid deployment environment: ${deployEnv}`)
    }
    return appEnv
  }

  protected static resolveEnvironmentFromValue(value: unknown): EnvironmentType | null {
    if (typeof value === 'string' && this.isValidEnv(value)) {
      return value
    }
    return null
  }

  protected getRequiredPropertyFromEnv(propertyName: string): string {
    if (process.env && process.env[propertyName]) {
      return process.env[propertyName] as string
    }
    throw new Error(`Required property ${propertyName} is missing`)
  }

  protected static isValidEnv(value: string): value is EnvironmentType {
    return ['local', 'dev', 'test', 'prod'].includes(value)
  }

  public isLocalEnvironment(): boolean {
    return !this.isDeploymentEnvironment()
  }

  public isDeploymentEnvironment(): boolean {
    return ['dev', 'test', 'prod'].includes(this.value)
  }
}
