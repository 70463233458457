import { ChangeEventHandler } from 'react'

export const FilterInputField = ({
  value,
  onChange,
  ensureValidRanges,
}: {
  value: string | undefined
  onChange: ChangeEventHandler<HTMLInputElement>
  ensureValidRanges: () => void
}) => {
  return (
    <div className='flex h-[2.1rem] w-[7rem] justify-center rounded-full border-[2px] py-0 px-4 pb-[0.15rem] text-center text-xl md:border-[1.5px]'>
      <input
        tabIndex={1}
        className='mt-[0.05rem] flex h-full w-20 flex-row border-0 text-center text-xl focus:border-none focus:outline-none focus:ring-0'
        type='text'
        value={value ?? '0'}
        onBlur={ensureValidRanges}
        onChange={onChange}
      />
    </div>
  )
}
