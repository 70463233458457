/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiInvoiceCustomerDomain,
  ApiInvoiceCustomerDomainCreateInput,
  ApiInvoiceCustomerDomainUpdateInput,
} from '../models';
import {
    ApiInvoiceCustomerDomainFromJSON,
    ApiInvoiceCustomerDomainToJSON,
    ApiInvoiceCustomerDomainCreateInputFromJSON,
    ApiInvoiceCustomerDomainCreateInputToJSON,
    ApiInvoiceCustomerDomainUpdateInputFromJSON,
    ApiInvoiceCustomerDomainUpdateInputToJSON,
} from '../models';

export interface CreateInvoiceCustomerDomainRequest {
    apiInvoiceCustomerDomainCreateInput: ApiInvoiceCustomerDomainCreateInput;
}

export interface DeleteInvoiceCustomerDomainRequest {
    domainId: string;
}

export interface UpdateInvoiceCustomerDomainRequest {
    domainId: string;
    apiInvoiceCustomerDomainUpdateInput: ApiInvoiceCustomerDomainUpdateInput;
}

/**
 * 
 */
export class InvoicingPrivateApi extends runtime.BaseAPI {

    /**
     * Create invoice customer domain
     * Create invoice customer domain
     */
    async createInvoiceCustomerDomainRaw(requestParameters: CreateInvoiceCustomerDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiInvoiceCustomerDomain>> {
        if (requestParameters.apiInvoiceCustomerDomainCreateInput === null || requestParameters.apiInvoiceCustomerDomainCreateInput === undefined) {
            throw new runtime.RequiredError('apiInvoiceCustomerDomainCreateInput','Required parameter requestParameters.apiInvoiceCustomerDomainCreateInput was null or undefined when calling createInvoiceCustomerDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice-customer-domains`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiInvoiceCustomerDomainCreateInputToJSON(requestParameters.apiInvoiceCustomerDomainCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvoiceCustomerDomainFromJSON(jsonValue));
    }

    /**
     * Create invoice customer domain
     * Create invoice customer domain
     */
    async createInvoiceCustomerDomain(requestParameters: CreateInvoiceCustomerDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiInvoiceCustomerDomain> {
        const response = await this.createInvoiceCustomerDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete invoice customer domain
     * Delete invoice customer domain
     */
    async deleteInvoiceCustomerDomainRaw(requestParameters: DeleteInvoiceCustomerDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
            throw new runtime.RequiredError('domainId','Required parameter requestParameters.domainId was null or undefined when calling deleteInvoiceCustomerDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice-customer-domains/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters.domainId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete invoice customer domain
     * Delete invoice customer domain
     */
    async deleteInvoiceCustomerDomain(requestParameters: DeleteInvoiceCustomerDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInvoiceCustomerDomainRaw(requestParameters, initOverrides);
    }

    /**
     * List invoice customer domains
     * List invoice customer domains
     */
    async listInvoiceCustomerDomainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiInvoiceCustomerDomain>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice-customer-domains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiInvoiceCustomerDomainFromJSON));
    }

    /**
     * List invoice customer domains
     * List invoice customer domains
     */
    async listInvoiceCustomerDomains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiInvoiceCustomerDomain>> {
        const response = await this.listInvoiceCustomerDomainsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update invoice customer domain
     * Update invoice customer domain
     */
    async updateInvoiceCustomerDomainRaw(requestParameters: UpdateInvoiceCustomerDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiInvoiceCustomerDomain>> {
        if (requestParameters.domainId === null || requestParameters.domainId === undefined) {
            throw new runtime.RequiredError('domainId','Required parameter requestParameters.domainId was null or undefined when calling updateInvoiceCustomerDomain.');
        }

        if (requestParameters.apiInvoiceCustomerDomainUpdateInput === null || requestParameters.apiInvoiceCustomerDomainUpdateInput === undefined) {
            throw new runtime.RequiredError('apiInvoiceCustomerDomainUpdateInput','Required parameter requestParameters.apiInvoiceCustomerDomainUpdateInput was null or undefined when calling updateInvoiceCustomerDomain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice-customer-domains/{domainId}`.replace(`{${"domainId"}}`, encodeURIComponent(String(requestParameters.domainId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiInvoiceCustomerDomainUpdateInputToJSON(requestParameters.apiInvoiceCustomerDomainUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvoiceCustomerDomainFromJSON(jsonValue));
    }

    /**
     * Update invoice customer domain
     * Update invoice customer domain
     */
    async updateInvoiceCustomerDomain(requestParameters: UpdateInvoiceCustomerDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiInvoiceCustomerDomain> {
        const response = await this.updateInvoiceCustomerDomainRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
