/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiDeleteImage,
  ApiImage,
  ApiImageInput,
} from '../models';
import {
    ApiDeleteImageFromJSON,
    ApiDeleteImageToJSON,
    ApiImageFromJSON,
    ApiImageToJSON,
    ApiImageInputFromJSON,
    ApiImageInputToJSON,
} from '../models';

export interface DeleteImageRequest {
    imageId: string;
}

export interface PostImageRequest {
    apiImageInput: ApiImageInput;
}

/**
 * 
 */
export class ImagesPrivateApi extends runtime.BaseAPI {

    /**
     * Delete image from database and storage
     * Delete image
     */
    async deleteImageRaw(requestParameters: DeleteImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiDeleteImage>> {
        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling deleteImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/images/{imageId}`.replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiDeleteImageFromJSON(jsonValue));
    }

    /**
     * Delete image from database and storage
     * Delete image
     */
    async deleteImage(requestParameters: DeleteImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiDeleteImage> {
        const response = await this.deleteImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create image to storage account and metadata to database
     * Create image
     */
    async postImageRaw(requestParameters: PostImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiImage>> {
        if (requestParameters.apiImageInput === null || requestParameters.apiImageInput === undefined) {
            throw new runtime.RequiredError('apiImageInput','Required parameter requestParameters.apiImageInput was null or undefined when calling postImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiImageInputToJSON(requestParameters.apiImageInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiImageFromJSON(jsonValue));
    }

    /**
     * Create image to storage account and metadata to database
     * Create image
     */
    async postImage(requestParameters: PostImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiImage> {
        const response = await this.postImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
