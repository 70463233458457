import { useEffect, useRef } from 'react'

// Close the signed-in user menu (top-right) when user clicks anywhere else on the page
export const useOutsideClick = (callback: () => void, profileOpen: boolean) => {
  const ref = useRef<HTMLDivElement>(null)

  // TODO: Use Typescript types
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    if (profileOpen) {
      // Only listen to click events when the profile menu is open
      document.addEventListener('mousedown', handleClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [callback, profileOpen])

  return ref
}
