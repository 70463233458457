import { Environment, EnvironmentType } from '@antilooppi/environment'
import { AntilooppiConfig, getAppConfig } from './antilooppi-config'
import { getPublicRuntimeConfig } from './antilooppi-runtime-config'

export class AntilooppiEnvironment extends Environment {
  private constructor(value: EnvironmentType) {
    super(value)
  }

  public static resolve(deployEnv: string): AntilooppiEnvironment {
    const env = this.resolveEnvironment(deployEnv)
    return new AntilooppiEnvironment(env)
  }

  protected getPropertyFromConfig<T extends keyof AntilooppiConfig>(propertyName: T): AntilooppiConfig[T] {
    return getAppConfig(this.value)[propertyName]
  }

  public getHostname(): string {
    return this.getPropertyFromConfig('hostname')
  }

  public getB2CClientId(): string {
    return this.getPropertyFromConfig('b2cClientId')
  }

  public getB2CTenantName(): string {
    return this.getPropertyFromConfig('b2cTenantName')
  }

  public getB2CApiAudience(): string {
    return this.getPropertyFromConfig('b2cApiAudience')
  }

  public getB2CHostName(): string {
    return this.getPropertyFromConfig('b2cHostName')
  }

  public getPrivateApiUrl(): string {
    return this.getPropertyFromConfig('privateApiUrl')
  }

  public getPrivateApiKey(): string {
    return this.getPropertyFromConfig('privateApiKey')
  }

  public getPublicApiUrl(): string {
    return this.getPropertyFromConfig('publicApiUrl')
  }

  public getPublicApiKey(): string {
    return this.getPropertyFromConfig('publicApiKey')
  }

  public getGoogleMapsApiKey(): string {
    return this.getPropertyFromConfig('googleMapsApiKey')
  }

  public getStripePublicKey(): string {
    return this.getPropertyFromConfig('stripePublicKey')
  }

  public getFriendlyCaptchaSiteKey(): string {
    return this.getPropertyFromConfig('friendlyCaptchaSiteKey')
  }

  public getJsonContentCredentials(): string {
    return this.getPropertyFromConfig('jsonContentCredentials')
  }
}

const publicRuntimeConfig = getPublicRuntimeConfig()

export const environment = AntilooppiEnvironment.resolve(publicRuntimeConfig.deployEnv)
