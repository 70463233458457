/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiReservationTimeslot
 */
export interface ApiReservationTimeslot {
    /**
     * 
     * @type {Date}
     * @memberof ApiReservationTimeslot
     */
    start: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApiReservationTimeslot
     */
    end: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiReservationTimeslot
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReservationTimeslot
     */
    bookingReference: string;
}

/**
 * Check if a given object implements the ApiReservationTimeslot interface.
 */
export function instanceOfApiReservationTimeslot(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "timezone" in value;
    isInstance = isInstance && "bookingReference" in value;

    return isInstance;
}

export function ApiReservationTimeslotFromJSON(json: any): ApiReservationTimeslot {
    return ApiReservationTimeslotFromJSONTyped(json, false);
}

export function ApiReservationTimeslotFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiReservationTimeslot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': (new Date(json['start'])),
        'end': (new Date(json['end'])),
        'timezone': json['timezone'],
        'bookingReference': json['bookingReference'],
    };
}

export function ApiReservationTimeslotToJSON(value?: ApiReservationTimeslot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': (value.start.toISOString()),
        'end': (value.end.toISOString()),
        'timezone': value.timezone,
        'bookingReference': value.bookingReference,
    };
}

