/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiInvoice,
  ApiInvoiceAllowed,
  ApiInvoiceInput,
} from '../models';
import {
    ApiInvoiceFromJSON,
    ApiInvoiceToJSON,
    ApiInvoiceAllowedFromJSON,
    ApiInvoiceAllowedToJSON,
    ApiInvoiceInputFromJSON,
    ApiInvoiceInputToJSON,
} from '../models';

export interface CreateInvoiceRequest {
    invoiceId: string;
    apiInvoiceInput: ApiInvoiceInput;
    lang?: string;
}

export interface FindInvoiceRequest {
    invoiceId: string;
    lang?: string;
}

/**
 * 
 */
export class InvoicingPublicApi extends runtime.BaseAPI {

    /**
     * Create invoice
     * Create invoice
     */
    async createInvoiceRaw(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiInvoice>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling createInvoice.');
        }

        if (requestParameters.apiInvoiceInput === null || requestParameters.apiInvoiceInput === undefined) {
            throw new runtime.RequiredError('apiInvoiceInput','Required parameter requestParameters.apiInvoiceInput was null or undefined when calling createInvoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiInvoiceInputToJSON(requestParameters.apiInvoiceInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvoiceFromJSON(jsonValue));
    }

    /**
     * Create invoice
     * Create invoice
     */
    async createInvoice(requestParameters: CreateInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiInvoice> {
        const response = await this.createInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get invoices for invoiceId
     * Get invoices for invoiceId
     */
    async findInvoiceRaw(requestParameters: FindInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiInvoice>> {
        if (requestParameters.invoiceId === null || requestParameters.invoiceId === undefined) {
            throw new runtime.RequiredError('invoiceId','Required parameter requestParameters.invoiceId was null or undefined when calling findInvoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/{invoiceId}`.replace(`{${"invoiceId"}}`, encodeURIComponent(String(requestParameters.invoiceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvoiceFromJSON(jsonValue));
    }

    /**
     * Get invoices for invoiceId
     * Get invoices for invoiceId
     */
    async findInvoice(requestParameters: FindInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiInvoice> {
        const response = await this.findInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Determine if invoice is allowed as payment method for the signed-in user
     * Is invoice allowed for user
     */
    async invoiceAllowedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiInvoiceAllowed>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice-allowed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvoiceAllowedFromJSON(jsonValue));
    }

    /**
     * Determine if invoice is allowed as payment method for the signed-in user
     * Is invoice allowed for user
     */
    async invoiceAllowed(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiInvoiceAllowed> {
        const response = await this.invoiceAllowedRaw(initOverrides);
        return await response.value();
    }

}
