import React from 'react'
import AdminNavBar from './NavBar/AdminNavBar'
import PageContent from './PageContent'
import { Trans, useTranslation } from 'react-i18next'

const ErrorFallback = ({ onTryAgainClick = () => undefined }: { onTryAgainClick?: () => void }) => {
  const { t } = useTranslation()

  return (
    <>
      <AdminNavBar />
      <PageContent className='mx-4'>
        <div className='flex flex-col items-center'>
          <h1 className='pt-6 pb-2'>{t('somethingWentWrong')}</h1>
          <h4>
            <Trans i18nKey='pleaseClickHereToTryAgain'>
              Ole hyvä ja{' '}
              <span className='cursor-pointer underline' onClick={onTryAgainClick}>
                napsauta tästä
              </span>{' '}
              yrittääksesi uudelleen.
            </Trans>
          </h4>
        </div>
      </PageContent>
    </>
  )
}

export default ErrorFallback
