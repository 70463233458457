import {
  ApiError,
  ApiImage,
  ApiProperty,
  ApiPropertyUpdateInput,
  ApiPropertyUpdateSearchResultOrderInput,
} from '@antilooppi/public-api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../auth/useAuth'
import { makeListPropertiesRequest, PropertyFilter, PropertyInclude } from '../filters/property-filters'
import { usePrivateApi } from '../usePrivateApi'
import { usePublicApi } from '../usePublicApi'

export const useProperties = (filter: PropertyFilter = {}, include: PropertyInclude[] = []) => {
  const { auth } = useAuth()
  const { propertiesApi } = usePublicApi(auth.user)
  const params = makeListPropertiesRequest(filter, include)
  return useQuery<ApiProperty[], ApiError>(['properties', params], () => propertiesApi.listProperties(params), {
    staleTime: 60000,
    useErrorBoundary: true,
  })
}

export const useSingleProperty = (propertyId: string, include: PropertyInclude[] = []) => {
  if (include.length === 0) {
    throw new Error('useSingleProperty(); include.length === 0')
  }

  const { auth } = useAuth()
  const { propertiesApi } = usePublicApi(auth.user)
  return useQuery<ApiProperty, ApiError>(
    ['property', include, propertyId],
    () => propertiesApi.getProperty({ propertyId, include: include.join(',') }),
    {
      enabled: propertyId != null,
    }
  )
}

export const usePropertyImages = (propertyId: string) => {
  const { auth } = useAuth()
  const { propertiesApi } = usePublicApi(auth.user)
  return useQuery<ApiImage[], ApiError>(
    ['property-images', propertyId],
    () => propertiesApi.listPropertyImages({ propertyId }),
    {
      enabled: propertyId != null,
    }
  )
}

export const useMutateProperty = () => {
  const { auth } = useAuth()
  const { propertiesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiProperty, ApiError, ApiPropertyUpdateInput>(
    (input) => propertiesApi.updateProperty({ propertyId: input.id, apiPropertyUpdateInput: input }),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([auth?.user?.username, 'properties', variables.id])
        queryClient.setQueryData([auth?.user?.username, 'property', variables.id], data)
      },
    }
  )
}

export const useMutatePropertySearchResultOrder = () => {
  const { auth } = useAuth()
  const { propertiesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<unknown, ApiError, ApiPropertyUpdateSearchResultOrderInput>(
    (input) => propertiesApi.updatePropertiesSearchResultOrder({ apiPropertyUpdateSearchResultOrderInput: input }),
    {
      onSuccess: async (data) => {
        await queryClient.invalidateQueries([auth?.user?.username, 'properties'])
        queryClient.setQueryData([auth?.user?.username, 'property'], data)
      },
    }
  )
}
