import React, { createContext, ReactNode, useContext, useState } from 'react'
import Toast, { ToastTheme } from '../components/Toast/Toast'
import { uniqueId } from 'lodash'
import ToastContainer from '../components/Toast/ToastContainer'

export type ToastContextValue = { showToast: (message: string, options?: ToastOptions) => void }
export type ToastProviderProps = { children: ReactNode }

export type ToastOptions = { theme?: ToastTheme; duration?: number }
export type ToastData = {
  message: string
  theme?: ToastTheme
  visible: boolean
}

const TOAST_ID_PREFIX = 'toast-'

const ToastContext = createContext<ToastContextValue | undefined>(undefined)

const sleep = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration))

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toasts, setToasts] = useState<Record<string, ToastData>>({})

  const showToast = (message: string, options?: ToastOptions): void => {
    const newToastId = uniqueId(TOAST_ID_PREFIX)
    const newToast: ToastData = {
      message,
      theme: options?.theme,
      visible: true,
    }

    // add toast as initially visible
    setToasts((prevToasts) => ({ ...prevToasts, [newToastId]: newToast }))
    sleep(options?.duration || 1500)
      .then(() => {
        // hide toast to animate disappearance
        setToasts((prevToasts) => ({ ...prevToasts, [newToastId]: { ...newToast, visible: false } }))
        return sleep(options?.duration || 1500)
      })
      .then(() => {
        // remove toast from dom
        setToasts((prevToasts) => {
          const { [newToastId]: removedToast, ...toastsWithoutRemoved } = prevToasts
          return { ...toastsWithoutRemoved }
        })
      })
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer>
        {Object.entries(toasts).map(([toastId, toast]) => (
          <Toast key={toastId} id={toastId} message={toast.message} visible={toast.visible} theme={toast?.theme} />
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
