import { z } from 'zod'
import { ApiImageInput } from '../../generated/private-api'

export const ImageInputSchema: z.ZodType<ApiImageInput> = z.object({
  id: z.string().optional(),
  spaceId: z.string().optional(),
  propertyId: z.string(),
  filename: z.string(),
  image: z.string(),
  description: z.string().optional(),
  type: z.string().optional(),
  order: z.number().optional(),
})
