import { ApiDistrict, ApiDistrictCreateInput, ApiDistrictUpdateInput, ApiError } from '@antilooppi/public-api'
import { UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../auth/useAuth'
import { usePublicApi } from '../usePublicApi'
import { usePrivateApi } from '../usePrivateApi'

export const useDistricts = () => {
  const { auth } = useAuth()
  const { districtsApi } = usePublicApi(auth.user)
  return useQuery<ApiDistrict[], ApiError>([auth?.user?.username, 'districts'], () => districtsApi.listDistricts(), {
    staleTime: 60000,
    useErrorBoundary: true,
  })
}

export const useCreateDistrict = () => {
  const { auth } = useAuth()
  const { districtsApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiDistrict, ApiError, ApiDistrictCreateInput>(
    (input) => districtsApi.createDistrict({ apiDistrictCreateInput: input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'districts'])
      },
    }
  )
}

export const useUpdateDistrict = () => {
  const { auth } = useAuth()
  const { districtsApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiDistrict, ApiError, ApiDistrictUpdateInput>(
    (input) => districtsApi.updateDistrict({ districtId: input.id, apiDistrictUpdateInput: input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'districts'])
      },
    }
  )
}

export const useDeleteDistrict = (): UseMutationResult<unknown, ApiError, { id: string }, unknown> => {
  const { auth } = useAuth()
  const { districtsApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<unknown, ApiError, { id: string }>(
    (input) => districtsApi.deleteDistrict({ districtId: input.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'districts'])
      },
    }
  )
}

export const useDeleteEmployee = (): UseMutationResult<unknown, ApiError, { id: string }, unknown> => {
  const { auth } = useAuth()
  const { employeesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<unknown, ApiError, { id: string }>(
    (input) => employeesApi.deleteEmployee({ employeeId: input.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['employees'])
      },
    }
  )
}
