/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiPropertyTranslationMap } from './ApiPropertyTranslationMap';
import {
    ApiPropertyTranslationMapFromJSON,
    ApiPropertyTranslationMapFromJSONTyped,
    ApiPropertyTranslationMapToJSON,
} from './ApiPropertyTranslationMap';
import type { ApiSpaceTranslationMap } from './ApiSpaceTranslationMap';
import {
    ApiSpaceTranslationMapFromJSON,
    ApiSpaceTranslationMapFromJSONTyped,
    ApiSpaceTranslationMapToJSON,
} from './ApiSpaceTranslationMap';

/**
 * 
 * @export
 * @interface ApiPropertyTranslations
 */
export interface ApiPropertyTranslations {
    [key: string]: ApiSpaceTranslationMap | any;
    /**
     * 
     * @type {ApiPropertyTranslationMap}
     * @memberof ApiPropertyTranslations
     */
    fi: ApiPropertyTranslationMap;
    /**
     * 
     * @type {ApiPropertyTranslationMap}
     * @memberof ApiPropertyTranslations
     */
    en?: ApiPropertyTranslationMap;
}

/**
 * Check if a given object implements the ApiPropertyTranslations interface.
 */
export function instanceOfApiPropertyTranslations(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fi" in value;

    return isInstance;
}

export function ApiPropertyTranslationsFromJSON(json: any): ApiPropertyTranslations {
    return ApiPropertyTranslationsFromJSONTyped(json, false);
}

export function ApiPropertyTranslationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPropertyTranslations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'fi': ApiPropertyTranslationMapFromJSON(json['fi']),
        'en': !exists(json, 'en') ? undefined : ApiPropertyTranslationMapFromJSON(json['en']),
    };
}

export function ApiPropertyTranslationsToJSON(value?: ApiPropertyTranslations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'fi': ApiPropertyTranslationMapToJSON(value.fi),
        'en': ApiPropertyTranslationMapToJSON(value.en),
    };
}

