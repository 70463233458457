/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiPropertySearchResultOrder } from './ApiPropertySearchResultOrder';
import {
    ApiPropertySearchResultOrderFromJSON,
    ApiPropertySearchResultOrderFromJSONTyped,
    ApiPropertySearchResultOrderToJSON,
} from './ApiPropertySearchResultOrder';

/**
 * 
 * @export
 * @interface ApiPropertyUpdateSearchResultOrderInput
 */
export interface ApiPropertyUpdateSearchResultOrderInput {
    /**
     * 
     * @type {Array<ApiPropertySearchResultOrder>}
     * @memberof ApiPropertyUpdateSearchResultOrderInput
     */
    searchResultOrder: Array<ApiPropertySearchResultOrder>;
}

/**
 * Check if a given object implements the ApiPropertyUpdateSearchResultOrderInput interface.
 */
export function instanceOfApiPropertyUpdateSearchResultOrderInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchResultOrder" in value;

    return isInstance;
}

export function ApiPropertyUpdateSearchResultOrderInputFromJSON(json: any): ApiPropertyUpdateSearchResultOrderInput {
    return ApiPropertyUpdateSearchResultOrderInputFromJSONTyped(json, false);
}

export function ApiPropertyUpdateSearchResultOrderInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPropertyUpdateSearchResultOrderInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchResultOrder': ((json['searchResultOrder'] as Array<any>).map(ApiPropertySearchResultOrderFromJSON)),
    };
}

export function ApiPropertyUpdateSearchResultOrderInputToJSON(value?: ApiPropertyUpdateSearchResultOrderInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'searchResultOrder': ((value.searchResultOrder as Array<any>).map(ApiPropertySearchResultOrderToJSON)),
    };
}

