/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiPaymentRow } from './ApiPaymentRow';
import {
    ApiPaymentRowFromJSON,
    ApiPaymentRowFromJSONTyped,
    ApiPaymentRowToJSON,
} from './ApiPaymentRow';

/**
 * 
 * @export
 * @interface ApiPayment
 */
export interface ApiPayment {
    /**
     * 
     * @type {string}
     * @memberof ApiPayment
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiPayment
     */
    termAccepted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiPayment
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPayment
     */
    propertyId: string;
    /**
     * 
     * @type {number}
     * @memberof ApiPayment
     */
    reservedHours: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPayment
     */
    vatIncluded: number;
    /**
     * 
     * @type {number}
     * @memberof ApiPayment
     */
    vatExcluded: number;
    /**
     * 
     * @type {Array<ApiPaymentRow>}
     * @memberof ApiPayment
     */
    paymentRows?: Array<ApiPaymentRow>;
    /**
     * recipients email
     * @type {string}
     * @memberof ApiPayment
     */
    email: string;
}

/**
 * Check if a given object implements the ApiPayment interface.
 */
export function instanceOfApiPayment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "termAccepted" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "reservedHours" in value;
    isInstance = isInstance && "vatIncluded" in value;
    isInstance = isInstance && "vatExcluded" in value;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ApiPaymentFromJSON(json: any): ApiPayment {
    return ApiPaymentFromJSONTyped(json, false);
}

export function ApiPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPayment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'termAccepted': json['termAccepted'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'reservedHours': json['reservedHours'],
        'vatIncluded': json['vatIncluded'],
        'vatExcluded': json['vatExcluded'],
        'paymentRows': !exists(json, 'paymentRows') ? undefined : ((json['paymentRows'] as Array<any>).map(ApiPaymentRowFromJSON)),
        'email': json['email'],
    };
}

export function ApiPaymentToJSON(value?: ApiPayment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'termAccepted': value.termAccepted,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'reservedHours': value.reservedHours,
        'vatIncluded': value.vatIncluded,
        'vatExcluded': value.vatExcluded,
        'paymentRows': value.paymentRows === undefined ? undefined : ((value.paymentRows as Array<any>).map(ApiPaymentRowToJSON)),
        'email': value.email,
    };
}

