import { z } from 'zod'
import { ApiTaitoriPaymentInput } from '../../generated/private-api'

export const TaitoriPaymentInputSchema: z.ZodType<ApiTaitoriPaymentInput> = z.object({
  bookingReferences: z.string(),
  stripeCheckoutSessionId: z.string(),
  paymentIntent: z.string(),
  paymentSecret: z.string(),
  email: z.string(),
  spaceId: z.string(),
  propertyId: z.string(),
  paymentId: z.string(),
})
