import { isEqual } from 'lodash'
import { PropertySpace } from '../components/SpaceGrid/SpaceGridOld'
import { FilterMinMaxItem, FilterSelectedItem } from './filter-types/filter-types'
import { ApiTaitoriAvailableSpace } from '@antilooppi/public-api'
import _ from 'lodash'

export const filterByReservable = (
  propSpace: PropertySpace,
  state: string | string[] | undefined,
  isReserving: boolean
): boolean => {
  if (state === undefined) {
    return true
  }
  return !isReserving
    ? !propSpace.space.productDisplayTypes[0]?.reservable
    : propSpace.space.productDisplayTypes[0]?.reservable
}

export const filterByDistricts = (propSpace: PropertySpace, selectedDistricts: FilterSelectedItem[]): boolean => {
  if (selectedDistricts?.length === 0) {
    // No districts selected for filtering
    return true
  }

  // At least one district is selected, check if the property's district is among the selected districts
  const propertyDistrict = _.first(propSpace.property.districts)

  const result = selectedDistricts?.find((selectedDistrict) => {
    return selectedDistrict.id === propertyDistrict?.id
  })

  return result !== undefined
}

export const filterByProperties = (propSpace: PropertySpace, selectedProperties: FilterSelectedItem[]): boolean => {
  if (selectedProperties?.length === 0) {
    // No properties selected for filtering
    return true
  }

  // At least one property is selected, check if the property is among the selected properties
  const result = selectedProperties?.find((selectedProperty) => {
    return propSpace.property.id === selectedProperty.id
  })

  return result !== undefined
}

export const filterByKeywords = (
  propSpace: PropertySpace,
  selectedKeywords: FilterSelectedItem[],
  language: string
): boolean => {
  if (selectedKeywords?.length === 0) {
    // No keywords selected for filtering
    return true
  }

  // At least one keyword is selected
  const spaceKeywords: FilterSelectedItem[] = propSpace.space.categories.map((cat) => {
    const keyword: FilterSelectedItem = { id: cat.id, name: language === 'fi' ? cat.nameFi : cat.nameEn }
    return keyword
  })

  return _.intersectionWith(selectedKeywords, spaceKeywords, isEqual).length > 0
}

export const filterByArea = (propSpace: PropertySpace, selectedArea: FilterMinMaxItem): boolean => {
  const area = propSpace.space.roomArea

  if (selectedArea.min && selectedArea.max) {
    return area >= selectedArea.min && area <= selectedArea.max
  }

  if (!selectedArea.min && selectedArea.max) {
    return area <= selectedArea.max
  }

  if (selectedArea.min && !selectedArea.max) {
    return area >= selectedArea.min
  }

  if (selectedArea.max === 0) {
    return false
  }

  return true
}

export const filterByCapacity = (propSpace: PropertySpace, selectedCapacity: FilterMinMaxItem): boolean => {
  const capacity = propSpace.space.maxCapacity

  if (!capacity) {
    return true
  }

  if (selectedCapacity.min && selectedCapacity.max) {
    return capacity >= selectedCapacity.min && capacity <= selectedCapacity.max
  }

  if (!selectedCapacity.min && selectedCapacity.max) {
    return capacity <= selectedCapacity.max
  }

  if (selectedCapacity.min && !selectedCapacity.max) {
    return capacity >= selectedCapacity.min
  }

  if (selectedCapacity.max === 0) {
    return false
  }

  return true
}

export const filterByProductDisplayType = (
  propSpace: PropertySpace,
  selectedProductDisplayTypes: FilterSelectedItem[],
  language: string
): boolean => {
  if (selectedProductDisplayTypes?.length === 0) {
    // No product display types selected for filtering
    return true
  }

  // At least one product display type is selected
  const spaceProductDisplayTypes: FilterSelectedItem[] = propSpace.space.productDisplayTypes.map((pdt) => {
    const productDisplayType: FilterSelectedItem = {
      id: pdt.id,
      name: language === 'fi' ? pdt.nameFi : pdt.nameEn,
    }
    return productDisplayType
  })

  return _.intersectionWith(selectedProductDisplayTypes, spaceProductDisplayTypes, isEqual).length > 0
}

export const filterByTaitoriAvailableSpace = (
  propSpace: PropertySpace,
  isTaitoriLoading: boolean,
  isTaitoriError: boolean,
  taitoriAvailableSpaces?: ApiTaitoriAvailableSpace[]
): boolean => {
  if (!isTaitoriLoading && !isTaitoriError) {
    return (
      taitoriAvailableSpaces?.find(
        (taitoriAvailableSpace) => taitoriAvailableSpace.publicId === propSpace.space.taitoriId
      ) !== undefined
    )
  }

  return false
}
