/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiEmployee,
} from '../models';
import {
    ApiEmployeeFromJSON,
    ApiEmployeeToJSON,
} from '../models';

export interface ListSpaceEmployeesRequest {
    spaceId: string;
}

/**
 * 
 */
export class EmployeesPublicApi extends runtime.BaseAPI {

    /**
     * Get all employees
     * Employees
     */
    async listEmployeesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiEmployee>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiEmployeeFromJSON));
    }

    /**
     * Get all employees
     * Employees
     */
    async listEmployees(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiEmployee>> {
        const response = await this.listEmployeesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List employees for space
     * List employees for space
     */
    async listSpaceEmployeesRaw(requestParameters: ListSpaceEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiEmployee>>> {
        if (requestParameters.spaceId === null || requestParameters.spaceId === undefined) {
            throw new runtime.RequiredError('spaceId','Required parameter requestParameters.spaceId was null or undefined when calling listSpaceEmployees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/space-employees/{spaceId}`.replace(`{${"spaceId"}}`, encodeURIComponent(String(requestParameters.spaceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiEmployeeFromJSON));
    }

    /**
     * List employees for space
     * List employees for space
     */
    async listSpaceEmployees(requestParameters: ListSpaceEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiEmployee>> {
        const response = await this.listSpaceEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
