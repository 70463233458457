import { ApiProperty, ApiSpace } from '@antilooppi/public-api'
import Image from '../Image'
import { useTranslation } from 'react-i18next'
import { getSpacePromoImagePath } from '../../lib/image'
import imgSpaceDefaultBackground from '../../assets/admin-space-background.png'
import { formatAmountForDisplay } from '../../lib/stripe/stripe-helpers'

const SpaceHeaderRow = ({
  space,
  property,
  showPrice,
}: {
  space: ApiSpace
  property: ApiProperty
  showPrice?: boolean
}) => {
  const { i18n } = useTranslation()

  return (
    <div className='border-b pb-2'>
      <div className='grid grid-flow-col grid-cols-4 gap-4'>
        <div className='relative my-auto flex h-16 w-full place-content-center items-center md:h-20'>
          <Image
            src={getSpacePromoImagePath(space) ?? imgSpaceDefaultBackground}
            alt=''
            layout='fill'
            objectFit='cover'
          />
        </div>
        <div className='col-span-3 my-auto'>
          <h2 className='text-2xl'>{space.displayName}</h2>
          <h6>{property.address}</h6>
        </div>
        {(showPrice || showPrice === undefined) && (
          <div className='my-auto'>
            <h6>
              {space.price ? formatAmountForDisplay(space.price, 'eur', i18n.t, space.productType?.pricingType) : null}
            </h6>
          </div>
        )}
      </div>
    </div>
  )
}

export default SpaceHeaderRow
