import React, { TextareaHTMLAttributes } from 'react'
import { FieldPath, FieldValues, UseFormRegister } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'

export type TextAreaProps<T extends FieldValues> = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  fieldName: FieldPath<T>
  label: string
  register: UseFormRegister<T>
  registerOptions?: RegisterOptions<T>
}

const TextArea = <T extends FieldValues>({
  fieldName,
  label,
  required,
  register,
  registerOptions,
  ...props
}: TextAreaProps<T>) => {
  const requiredClass = required ? "after:content-['*'] after:text-red-500" : ''
  return (
    <>
      <label htmlFor={label} className={`absolute top-4 left-4 mb-1 text-lg leading-none ${requiredClass}`}>
        {label}
      </label>
      <textarea
        id={fieldName}
        className='w-full px-4 pt-12 pb-2 text-xl leading-none'
        {...props}
        {...register(fieldName, registerOptions)}
      />
    </>
  )
}

export default TextArea
