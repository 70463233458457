import { makeListSpacesRequest, SpaceFilter } from '../filters/space-filters'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ApiError, ApiSpace, ApiSpaceUpdateInput } from '@antilooppi/public-api'
import { usePrivateApi } from '../usePrivateApi'
import { usePublicApi } from '../usePublicApi'
import { useAuth } from '../auth/useAuth'
import { PropertyInclude } from '../filters/property-filters'

export const useSpacesForProperty = (propertyId: string, filter: SpaceFilter, include: PropertyInclude[] = []) => {
  const { auth } = useAuth()
  const { spacesApi } = usePublicApi(auth.user)
  const params = makeListSpacesRequest(filter, include)
  return useQuery<ApiSpace[], ApiError>(
    ['spaces', propertyId, params],
    () => spacesApi.listSpaces({ propertyId: propertyId, ...params }),
    { enabled: propertyId != null && filter != null }
  )
}

export const useSpaces = (filter: SpaceFilter, include: PropertyInclude[] = []) => {
  const { auth } = useAuth()
  const { spacesApi } = usePublicApi(auth.user)
  const params = makeListSpacesRequest(filter, include)
  return useQuery<ApiSpace[], ApiError>(['spaces', params], () => spacesApi.listSpaces({ ...params }), {
    enabled: filter != null,
  })
}

export const useSingleSpace = (spaceId: string) => {
  const { auth } = useAuth()
  const { spacesApi } = usePublicApi(auth.user)
  return useQuery<ApiSpace, ApiError>(['space', spaceId], () =>
    spacesApi.getSpace({ spaceId }).then((space: ApiSpace) => {
      space?.images.sort((image1, image2) => {
        return image1.order !== undefined && image2.order !== undefined ? (image1.order < image2.order ? -1 : 1) : 0
      })
      return space
    })
  )
}

export const useMutateSpace = () => {
  const { auth } = useAuth()
  const { spacesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiSpace, ApiError, ApiSpaceUpdateInput>(
    (input) => spacesApi.updateSpace({ spaceId: input.id, apiSpaceUpdateInput: input }),
    {
      onSuccess: async (data, variables) => {
        await queryClient.invalidateQueries([auth?.user?.username, 'spaces', variables.propertyId])
      },
    }
  )
}
