import React from 'react'
import { Range, useThumbOverlap } from 'react-range'

const FilterRangeLabel = ({
  rangeRef,
  values,
  index,
  step,
  max,
}: {
  rangeRef: Range | null
  values: number[]
  index: number
  step: number
  max: number
}) => {
  const valueToLabel = (value: string): string => {
    return value === `${max}` ? '∞' : value
  }
  const [labelValue, style] = useThumbOverlap(rangeRef, values, index, step, ' - ', valueToLabel)

  return (
    <div
      data-label={index}
      className='absolute -top-8 block whitespace-nowrap bg-transparent text-center text-lg'
      style={{ ...(style as React.CSSProperties) }}>
      {labelValue as string}
    </div>
  )
}

export default FilterRangeLabel
