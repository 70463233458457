import { EnvironmentType } from '@antilooppi/environment'
import { antilooppiConfigDev, antilooppiConfigLocal, antilooppiConfigProd, antilooppiConfigTest } from './config'

export type AntilooppiConfig = {
  hostname: string
  b2cClientId: string
  b2cTenantName: string
  b2cApiAudience: string
  b2cHostName: string
  privateApiUrl: string
  privateApiKey: string
  publicApiUrl: string
  publicApiKey: string
  googleMapsApiKey: string
  stripePublicKey: string
  friendlyCaptchaSiteKey: string
  jsonContentCredentials: string
}

const environments: Record<EnvironmentType, AntilooppiConfig> = {
  local: antilooppiConfigLocal,
  dev: antilooppiConfigDev,
  test: antilooppiConfigTest,
  prod: antilooppiConfigProd,
}

export const getAppConfig = (envType: EnvironmentType): AntilooppiConfig => {
  if (environments[envType] != null) {
    return environments[envType]
  }
  return environments.local
}
