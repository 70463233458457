import { useTranslation } from 'react-i18next'
import Image from '../Image'
import removeFilterGroup from '../../assets/remove-filter-group.png'

const ActiveFilterItem = ({
  title,
  noWrap = false,
  onDelete,
}: {
  title: string
  noWrap?: boolean
  onDelete?: () => void
}) => {
  const { t: t } = useTranslation()

  return (
    <div className={`mr-3 flex flex-row`}>
      <div className={`mr-1 ${noWrap ? 'whitespace-nowrap' : ''} cursor-default`}>{title}</div>
      {onDelete && (
        <div className='cursor-pointer p-2' onClick={onDelete}>
          <div className='relative mt-[-0.075rem] h-3 w-3 shrink-0 p-2'>
            <Image
              src={removeFilterGroup}
              layout='fill'
              objectFit='cover'
              alt={t('removeFilterItem', { filterItem: title })}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ActiveFilterItem
