/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiResponsibilityCreateInput
 */
export interface ApiResponsibilityCreateInput {
    /**
     * 
     * @type {string}
     * @memberof ApiResponsibilityCreateInput
     */
    nameFi: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsibilityCreateInput
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResponsibilityCreateInput
     */
    iconUrl: string;
}

/**
 * Check if a given object implements the ApiResponsibilityCreateInput interface.
 */
export function instanceOfApiResponsibilityCreateInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nameFi" in value;
    isInstance = isInstance && "nameEn" in value;
    isInstance = isInstance && "iconUrl" in value;

    return isInstance;
}

export function ApiResponsibilityCreateInputFromJSON(json: any): ApiResponsibilityCreateInput {
    return ApiResponsibilityCreateInputFromJSONTyped(json, false);
}

export function ApiResponsibilityCreateInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResponsibilityCreateInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nameFi': json['nameFi'],
        'nameEn': json['nameEn'],
        'iconUrl': json['iconUrl'],
    };
}

export function ApiResponsibilityCreateInputToJSON(value?: ApiResponsibilityCreateInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nameFi': value.nameFi,
        'nameEn': value.nameEn,
        'iconUrl': value.iconUrl,
    };
}

