import { AntilooppiConfig } from '../antilooppi-config'

export const antilooppiConfigProd: AntilooppiConfig = {
  hostname: 'https://spaces.antilooppi.fi',
  b2cClientId: '5e54a2ce-29ea-407d-b0d2-b80ee1db1050',
  b2cTenantName: 'antilooppib2cprod',
  b2cApiAudience: '93c5de4e-f919-4bea-b3e4-e38a8ca9c3bb',
  b2cHostName: 'login.antilooppi.fi',
  privateApiUrl: 'https://private-api.antilooppi.fi/api',
  privateApiKey: 'df3c58f0824140d6a41b28ea837defd9',
  publicApiUrl: 'https://api.antilooppi.fi/api',
  publicApiKey: 'b66c4ea83ebc40329478437ba49ed291',
  googleMapsApiKey: 'AIzaSyDUDdRyGKV8bt_l1rpHHl2TSq6bN7uxuDI',
  stripePublicKey:
    'pk_live_51LkNgGHmaz9xYeeXdFVDfZ3h9cynBWDyE3mtxBmzryfJg7ZGO378ZlD3HkchQRTcvNaifZWzR5kWKIuV5dYrHWLj003N78U3O5',
  friendlyCaptchaSiteKey: 'FCMJ275GALGSAB76',
  jsonContentCredentials: 'antilooppi:U51VAgXCbGgUTyekNDJG',
}
