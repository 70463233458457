import { ApiDistrict, ApiProperty } from '@antilooppi/public-api'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { FilterSelectedItem } from '../../lib/filter-types/filter-types'
import Button from '../Button'

const DistrictsAndProperties = ({
  districts,
  selectedDistricts,
  properties,
  selectedProperties,
  selectDistricts,
  selectProperties,
}: {
  districts: ApiDistrict[]
  selectedDistricts: FilterSelectedItem[]
  properties: ApiProperty[]
  selectedProperties: FilterSelectedItem[]
  selectDistricts: (districts: FilterSelectedItem[]) => void
  selectProperties: (properties: FilterSelectedItem[]) => void
}) => {
  const { i18n, t } = useTranslation()

  return (
    <div className='relative z-10 md:ml-[1.5rem] md:w-[38.95rem]'>
      <div className='filterExpanded md:absolute'>
        <div className='select-none text-xl'>{t('area')}</div>
        <div className='flex flex-wrap gap-2'>
          {districts
            ?.sort((a, b) => (a.name < b.name ? -1 : 1))
            .map((district) => {
              const editedSelection = _.cloneDeep(selectedDistricts)
              return (
                <div
                  key={district.id}
                  className={`${
                    editedSelection?.find((selectedDistrict) => selectedDistrict.id === district.id)
                      ? 'filterSelectionLocationItemSelected'
                      : 'filterSelectionLocationItem'
                  }`}
                  onClick={() => {
                    if (selectedDistricts?.find((dist) => dist.id === district.id)) {
                      // Selected item found from array, remove it
                      selectDistricts(selectedDistricts.filter((dist) => dist.id !== district.id))
                    } else {
                      // Selected item not found from array, add it
                      selectDistricts([...(selectedDistricts || []), { id: district.id, name: district.name }])
                    }
                  }}>
                  {district.name}
                </div>
              )
            })}
          {selectedDistricts.length > 1 && (
            <div className='mr-4 flex w-fit flex-grow flex-row justify-end'>
              <Button text={t('empty')} size='empty' theme='blue' onClick={() => selectDistricts([])} />
            </div>
          )}
        </div>
        <div className='mt-4 select-none text-xl'>{t('property')}</div>
        <div className='mb-1 flex flex-wrap gap-2'>
          {properties
            .sort((a, b) => {
              if (i18n.language === 'fi') {
                const nameA = a.translations.fi.displayName ?? a.name
                const nameB = b.translations.fi.displayName ?? b.name
                return nameA < nameB ? -1 : 1
              } else {
                const nameA = a.translations.en?.displayName ?? a.name
                const nameB = b.translations.en?.displayName ?? b.name
                return nameA < nameB ? -1 : 1
              }
            })
            ?.map((property) => {
              return (
                <div
                  key={property.id}
                  className={`${
                    selectedProperties?.find((selectedProperty) => selectedProperty.id === property.id)
                      ? 'filterSelectionLocationItemSelected'
                      : 'filterSelectionLocationItem'
                  }`}
                  onClick={() => {
                    if (selectedProperties?.find((prop) => prop.id === property.id)) {
                      // Selected item found from array, remove it
                      selectProperties(selectedProperties.filter((prop) => prop.id !== property.id))
                    } else {
                      // Selected item not found from array, add it
                      selectProperties([...(selectedProperties || []), { id: property.id, name: property.displayName }])
                    }
                  }}>
                  {property.displayName}
                </div>
              )
            })}
          {selectedProperties.length > 1 && (
            <div className='mr-4 flex w-fit flex-grow flex-row justify-end'>
              <Button text={t('empty')} size='empty' theme='blue' onClick={() => selectProperties([])} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DistrictsAndProperties
