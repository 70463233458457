/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiBookingTimeslotInput } from './ApiBookingTimeslotInput';
import {
    ApiBookingTimeslotInputFromJSON,
    ApiBookingTimeslotInputFromJSONTyped,
    ApiBookingTimeslotInputToJSON,
} from './ApiBookingTimeslotInput';
import type { ApiCustomerDetails } from './ApiCustomerDetails';
import {
    ApiCustomerDetailsFromJSON,
    ApiCustomerDetailsFromJSONTyped,
    ApiCustomerDetailsToJSON,
} from './ApiCustomerDetails';

/**
 * 
 * @export
 * @interface ApiReservationConfirmationInput
 */
export interface ApiReservationConfirmationInput {
    /**
     * 
     * @type {string}
     * @memberof ApiReservationConfirmationInput
     */
    spaceId: string;
    /**
     * 
     * @type {Array<ApiBookingTimeslotInput>}
     * @memberof ApiReservationConfirmationInput
     */
    timeslots: Array<ApiBookingTimeslotInput>;
    /**
     * 
     * @type {ApiCustomerDetails}
     * @memberof ApiReservationConfirmationInput
     */
    customerDetails?: ApiCustomerDetails;
}

/**
 * Check if a given object implements the ApiReservationConfirmationInput interface.
 */
export function instanceOfApiReservationConfirmationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "timeslots" in value;

    return isInstance;
}

export function ApiReservationConfirmationInputFromJSON(json: any): ApiReservationConfirmationInput {
    return ApiReservationConfirmationInputFromJSONTyped(json, false);
}

export function ApiReservationConfirmationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiReservationConfirmationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spaceId': json['spaceId'],
        'timeslots': ((json['timeslots'] as Array<any>).map(ApiBookingTimeslotInputFromJSON)),
        'customerDetails': !exists(json, 'customerDetails') ? undefined : ApiCustomerDetailsFromJSON(json['customerDetails']),
    };
}

export function ApiReservationConfirmationInputToJSON(value?: ApiReservationConfirmationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spaceId': value.spaceId,
        'timeslots': ((value.timeslots as Array<any>).map(ApiBookingTimeslotInputToJSON)),
        'customerDetails': ApiCustomerDetailsToJSON(value.customerDetails),
    };
}

