/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiCategory
 */
export interface ApiCategory {
    /**
     * 
     * @type {string}
     * @memberof ApiCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCategory
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof ApiCategory
     */
    nameFi: string;
    /**
     * 
     * @type {number}
     * @memberof ApiCategory
     */
    spacesCount?: number;
}

/**
 * Check if a given object implements the ApiCategory interface.
 */
export function instanceOfApiCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "nameEn" in value;
    isInstance = isInstance && "nameFi" in value;

    return isInstance;
}

export function ApiCategoryFromJSON(json: any): ApiCategory {
    return ApiCategoryFromJSONTyped(json, false);
}

export function ApiCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nameEn': json['nameEn'],
        'nameFi': json['nameFi'],
        'spacesCount': !exists(json, 'spacesCount') ? undefined : json['spacesCount'],
    };
}

export function ApiCategoryToJSON(value?: ApiCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameEn': value.nameEn,
        'nameFi': value.nameFi,
        'spacesCount': value.spacesCount,
    };
}

