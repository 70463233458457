import { useTranslation } from 'react-i18next'

const ErrorLabel = ({ message, className = '' }: { message?: string; className?: string }) => {
  const { t } = useTranslation()

  return message ? (
    <div className={`mt-2 mb-4 ${className}`}>
      <span className='text-red-500'>{t(message as never)}</span>
    </div>
  ) : null
}

export default ErrorLabel
