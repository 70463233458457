/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiPayment,
} from '../models';
import {
    ApiPaymentFromJSON,
    ApiPaymentToJSON,
} from '../models';

export interface GetPaymentRequest {
    paymentId: string;
    bookingReferences: string;
    lang?: string;
}

/**
 * 
 */
export class PaymentsPublicApi extends runtime.BaseAPI {

    /**
     * Get payment
     * Get payment
     */
    async getPaymentRaw(requestParameters: GetPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiPayment>> {
        if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
            throw new runtime.RequiredError('paymentId','Required parameter requestParameters.paymentId was null or undefined when calling getPayment.');
        }

        if (requestParameters.bookingReferences === null || requestParameters.bookingReferences === undefined) {
            throw new runtime.RequiredError('bookingReferences','Required parameter requestParameters.bookingReferences was null or undefined when calling getPayment.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookingReferences !== undefined) {
            queryParameters['bookingReferences'] = requestParameters.bookingReferences;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/{paymentId}`.replace(`{${"paymentId"}}`, encodeURIComponent(String(requestParameters.paymentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiPaymentFromJSON(jsonValue));
    }

    /**
     * Get payment
     * Get payment
     */
    async getPayment(requestParameters: GetPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiPayment> {
        const response = await this.getPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
