import { ApiPaymentRow } from '../../generated/public-api'
import { z } from 'zod'
import { parseISO } from 'date-fns'

export const PaymentRowSchema: z.ZodType<ApiPaymentRow> = z.object({
  id: z.string().optional(),
  payment_id: z.string().optional(),
  startDate: z.string().refine((val) => parseISO(val)),
  endDate: z.string().refine((val) => parseISO(val)),
})
