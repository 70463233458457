/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiImage
 */
export interface ApiImage {
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    baseurl: string;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ApiImage
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiImage
     */
    thumbnailPath?: string;
}

/**
 * Check if a given object implements the ApiImage interface.
 */
export function instanceOfApiImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "baseurl" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ApiImageFromJSON(json: any): ApiImage {
    return ApiImageFromJSONTyped(json, false);
}

export function ApiImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'baseurl': json['baseurl'],
        'path': json['path'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'type': json['type'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'thumbnailPath': !exists(json, 'thumbnailPath') ? undefined : json['thumbnailPath'],
    };
}

export function ApiImageToJSON(value?: ApiImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'baseurl': value.baseurl,
        'path': value.path,
        'description': value.description,
        'type': value.type,
        'order': value.order,
        'thumbnailPath': value.thumbnailPath,
    };
}

