/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiInvoiceReservation } from './ApiInvoiceReservation';
import {
    ApiInvoiceReservationFromJSON,
    ApiInvoiceReservationFromJSONTyped,
    ApiInvoiceReservationToJSON,
} from './ApiInvoiceReservation';

/**
 * 
 * @export
 * @interface ApiInvoice
 */
export interface ApiInvoice {
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    propertyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    property: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    communityname: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    businessid: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    target: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    host: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    unit: string;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoice
     */
    exvatprice: number;
    /**
     * 
     * @type {number}
     * @memberof ApiInvoice
     */
    vatpercent: number;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    invoiceReference: string;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoice
     */
    emailSent: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiInvoice
     */
    cancelSecret: string;
    /**
     * 
     * @type {Array<ApiInvoiceReservation>}
     * @memberof ApiInvoice
     */
    reservations: Array<ApiInvoiceReservation>;
    /**
     * 
     * @type {boolean}
     * @memberof ApiInvoice
     */
    internal: boolean;
}

/**
 * Check if a given object implements the ApiInvoice interface.
 */
export function instanceOfApiInvoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "property" in value;
    isInstance = isInstance && "communityname" in value;
    isInstance = isInstance && "businessid" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "unit" in value;
    isInstance = isInstance && "exvatprice" in value;
    isInstance = isInstance && "vatpercent" in value;
    isInstance = isInstance && "invoiceReference" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "emailSent" in value;
    isInstance = isInstance && "cancelSecret" in value;
    isInstance = isInstance && "reservations" in value;
    isInstance = isInstance && "internal" in value;

    return isInstance;
}

export function ApiInvoiceFromJSON(json: any): ApiInvoice {
    return ApiInvoiceFromJSONTyped(json, false);
}

export function ApiInvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiInvoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'created': json['created'],
        'property': json['property'],
        'communityname': json['communityname'],
        'businessid': json['businessid'],
        'target': json['target'],
        'host': json['host'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'unit': json['unit'],
        'exvatprice': json['exvatprice'],
        'vatpercent': json['vatpercent'],
        'invoiceReference': json['invoiceReference'],
        'email': json['email'],
        'emailSent': json['emailSent'],
        'cancelSecret': json['cancelSecret'],
        'reservations': ((json['reservations'] as Array<any>).map(ApiInvoiceReservationFromJSON)),
        'internal': json['internal'],
    };
}

export function ApiInvoiceToJSON(value?: ApiInvoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'created': value.created,
        'property': value.property,
        'communityname': value.communityname,
        'businessid': value.businessid,
        'target': value.target,
        'host': value.host,
        'description': value.description,
        'unit': value.unit,
        'exvatprice': value.exvatprice,
        'vatpercent': value.vatpercent,
        'invoiceReference': value.invoiceReference,
        'email': value.email,
        'emailSent': value.emailSent,
        'cancelSecret': value.cancelSecret,
        'reservations': ((value.reservations as Array<any>).map(ApiInvoiceReservationToJSON)),
        'internal': value.internal,
    };
}

