/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiService,
  ApiServiceCreateInput,
  ApiServiceUpdateInput,
} from '../models';
import {
    ApiServiceFromJSON,
    ApiServiceToJSON,
    ApiServiceCreateInputFromJSON,
    ApiServiceCreateInputToJSON,
    ApiServiceUpdateInputFromJSON,
    ApiServiceUpdateInputToJSON,
} from '../models';

export interface CreateServiceRequest {
    apiServiceCreateInput: ApiServiceCreateInput;
}

export interface DeleteServiceRequest {
    serviceId: string;
}

export interface UpdateServiceRequest {
    serviceId: string;
    apiServiceUpdateInput: ApiServiceUpdateInput;
}

/**
 * 
 */
export class ServicesPrivateApi extends runtime.BaseAPI {

    /**
     * Create service
     * Create service
     */
    async createServiceRaw(requestParameters: CreateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.apiServiceCreateInput === null || requestParameters.apiServiceCreateInput === undefined) {
            throw new runtime.RequiredError('apiServiceCreateInput','Required parameter requestParameters.apiServiceCreateInput was null or undefined when calling createService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiServiceCreateInputToJSON(requestParameters.apiServiceCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * Create service
     * Create service
     */
    async createService(requestParameters: CreateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiService> {
        const response = await this.createServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete service
     * Delete service
     */
    async deleteServiceRaw(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling deleteService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/{serviceId}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete service
     * Delete service
     */
    async deleteService(requestParameters: DeleteServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Services
     * Services
     */
    async listServicesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiService>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiServiceFromJSON));
    }

    /**
     * Services
     * Services
     */
    async listServices(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiService>> {
        const response = await this.listServicesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update service
     * Update service
     */
    async updateServiceRaw(requestParameters: UpdateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiService>> {
        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling updateService.');
        }

        if (requestParameters.apiServiceUpdateInput === null || requestParameters.apiServiceUpdateInput === undefined) {
            throw new runtime.RequiredError('apiServiceUpdateInput','Required parameter requestParameters.apiServiceUpdateInput was null or undefined when calling updateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/services/{serviceId}`.replace(`{${"serviceId"}}`, encodeURIComponent(String(requestParameters.serviceId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiServiceUpdateInputToJSON(requestParameters.apiServiceUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiServiceFromJSON(jsonValue));
    }

    /**
     * Update service
     * Update service
     */
    async updateService(requestParameters: UpdateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiService> {
        const response = await this.updateServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
