import { z } from 'zod'
import { ApiResponsibilityCreateInput, ApiResponsibilityUpdateInput } from '../../generated/private-api'

export const ResponsibilityCreateInputSchema: z.ZodType<ApiResponsibilityCreateInput> = z.object({
  nameFi: z.string(),
  nameEn: z.string(),
  iconUrl: z.string(),
})

export const ResponsibilityUpdateInputSchema: z.ZodType<ApiResponsibilityUpdateInput> = z.object({
  id: z.string(),
  nameFi: z.string(),
  nameEn: z.string(),
  iconUrl: z.string(),
})
