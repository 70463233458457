import { ApiCategory, ApiCategoryCreateInput, ApiCategoryUpdateInput, ApiError } from '@antilooppi/public-api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth } from '../auth/useAuth'
import { usePublicApi } from '../usePublicApi'
import { usePrivateApi } from '../usePrivateApi'

export const useCategories = () => {
  const { auth } = useAuth()
  const { categoriesApi } = usePublicApi(auth.user)
  return useQuery<ApiCategory[], ApiError>([auth?.user?.username, 'categories'], () => categoriesApi.listCategories(), {
    staleTime: 60000,
    useErrorBoundary: true,
  })
}

export const useCreateCategory = () => {
  const { auth } = useAuth()
  const { categoriesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiCategory, ApiError, ApiCategoryCreateInput>(
    (input) => categoriesApi.createCategory({ apiCategoryCreateInput: input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'categories'])
      },
    }
  )
}

export const useUpdateCategory = () => {
  const { auth } = useAuth()
  const { categoriesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<ApiCategory, ApiError, ApiCategoryUpdateInput>(
    (input) => categoriesApi.updateCategory({ categoryId: input.id, apiCategoryUpdateInput: input }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'categories'])
      },
    }
  )
}

export const useDeleteCategory = () => {
  const { auth } = useAuth()
  const { categoriesApi } = usePrivateApi(auth.user)
  const queryClient = useQueryClient()
  return useMutation<unknown, ApiError, { id: string }>(
    (input) => categoriesApi.deleteCategory({ categoryId: input.id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([auth?.user?.username, 'categories'])
      },
    }
  )
}
