import React from 'react'
import { useTranslation } from 'react-i18next'
import { setInLocalStorage } from '../../lib/storage/localStorage'
import { LocalStorageKey } from '../../lib/storage/localStorageKey'
import NavBar, { NavBarLinkConfig } from './NavBar'

const AdminNavBar = () => {
  const { t, i18n } = useTranslation()
  const { t: ta } = useTranslation('admin')

  // Fix for react hydration error
  // https://nextjs.org/docs/messages/react-hydration-error

  const changeLanguage = (lang: string) => {
    setInLocalStorage(LocalStorageKey.LANGUAGE, lang)
    i18n.changeLanguage(lang)
  }

  const changeLanguageLink =
    i18n.language === 'en'
      ? { title: 'Suomeksi', onClick: () => changeLanguage('fi') }
      : { title: 'In English', onClick: () => changeLanguage('en') }

  const primaryLinks = [
    { title: ta('spaces'), url: '/admin' },
    { title: ta('searchResultOrder'), url: '/admin/search-result-order' },
    { title: ta('services'), url: '/admin/services' },
    { title: t('productDisplayTypes'), url: '/admin/product-display-types' },
    { title: t('districts'), url: '/admin/districts' },
    { title: t('keywords'), url: '/admin/keywords' },
    { title: t('employees'), url: '/admin/employees' },
    { title: ta('invoiceCustomers'), url: '/admin/invoice-customers' },
    { title: ta('settings'), url: '/admin/settings' },
  ]

  const secondaryLinks: NavBarLinkConfig[] = [changeLanguageLink]

  return <NavBar home='/admin' primaryLinks={primaryLinks} secondaryLinks={secondaryLinks} />
}

export default AdminNavBar
