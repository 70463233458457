const SelectFilterTime = ({
  hour,
  minute,
  selected,
  enabled,
  handleSelection,
}: {
  hour: number
  minute: number
  selected: boolean
  enabled: boolean
  handleSelection: (date: Date) => void
}) => {
  const date = new Date()
  date.setHours(hour, minute, 0, 0)
  return (
    <div
      className={`${selected ? 'mx-2 rounded-full border-[1.5px]' : ''} ${!enabled ? 'text-gray' : ''}`}
      onClick={() => {
        if (enabled) {
          handleSelection(date)
        }
      }}>
      {date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
    </div>
  )
}

export default SelectFilterTime
