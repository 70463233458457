/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiBookingTimeslotInput } from './ApiBookingTimeslotInput';
import {
    ApiBookingTimeslotInputFromJSON,
    ApiBookingTimeslotInputFromJSONTyped,
    ApiBookingTimeslotInputToJSON,
} from './ApiBookingTimeslotInput';
import type { ApiCustomerDetails } from './ApiCustomerDetails';
import {
    ApiCustomerDetailsFromJSON,
    ApiCustomerDetailsFromJSONTyped,
    ApiCustomerDetailsToJSON,
} from './ApiCustomerDetails';
import type { ApiPaymentRow } from './ApiPaymentRow';
import {
    ApiPaymentRowFromJSON,
    ApiPaymentRowFromJSONTyped,
    ApiPaymentRowToJSON,
} from './ApiPaymentRow';

/**
 * 
 * @export
 * @interface ApiReservationInput
 */
export interface ApiReservationInput {
    /**
     * 
     * @type {string}
     * @memberof ApiReservationInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReservationInput
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiReservationInput
     */
    propertyId: string;
    /**
     * 
     * @type {number}
     * @memberof ApiReservationInput
     */
    reservedHours: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiReservationInput
     */
    termsAccepted: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApiReservationInput
     */
    vatIncluded: number;
    /**
     * 
     * @type {number}
     * @memberof ApiReservationInput
     */
    vatExcluded: number;
    /**
     * 
     * @type {ApiCustomerDetails}
     * @memberof ApiReservationInput
     */
    customerDetails: ApiCustomerDetails;
    /**
     * 
     * @type {Array<ApiPaymentRow>}
     * @memberof ApiReservationInput
     */
    paymentRows?: Array<ApiPaymentRow>;
    /**
     * 
     * @type {Array<ApiBookingTimeslotInput>}
     * @memberof ApiReservationInput
     */
    timeslots: Array<ApiBookingTimeslotInput>;
    /**
     * 
     * @type {string}
     * @memberof ApiReservationInput
     */
    paymentMethod: ApiReservationInputPaymentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof ApiReservationInput
     */
    invoiceReference?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiReservationInput
     */
    internal: boolean;
}


/**
 * @export
 */
export const ApiReservationInputPaymentMethodEnum = {
    CreditCard: 'creditCard',
    Invoice: 'invoice',
    InternalFree: 'internalFree'
} as const;
export type ApiReservationInputPaymentMethodEnum = typeof ApiReservationInputPaymentMethodEnum[keyof typeof ApiReservationInputPaymentMethodEnum];


/**
 * Check if a given object implements the ApiReservationInput interface.
 */
export function instanceOfApiReservationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "reservedHours" in value;
    isInstance = isInstance && "termsAccepted" in value;
    isInstance = isInstance && "vatIncluded" in value;
    isInstance = isInstance && "vatExcluded" in value;
    isInstance = isInstance && "customerDetails" in value;
    isInstance = isInstance && "timeslots" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "internal" in value;

    return isInstance;
}

export function ApiReservationInputFromJSON(json: any): ApiReservationInput {
    return ApiReservationInputFromJSONTyped(json, false);
}

export function ApiReservationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiReservationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'reservedHours': json['reservedHours'],
        'termsAccepted': json['termsAccepted'],
        'vatIncluded': json['vatIncluded'],
        'vatExcluded': json['vatExcluded'],
        'customerDetails': ApiCustomerDetailsFromJSON(json['customerDetails']),
        'paymentRows': !exists(json, 'paymentRows') ? undefined : ((json['paymentRows'] as Array<any>).map(ApiPaymentRowFromJSON)),
        'timeslots': ((json['timeslots'] as Array<any>).map(ApiBookingTimeslotInputFromJSON)),
        'paymentMethod': json['paymentMethod'],
        'invoiceReference': !exists(json, 'invoiceReference') ? undefined : json['invoiceReference'],
        'internal': json['internal'],
    };
}

export function ApiReservationInputToJSON(value?: ApiReservationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'reservedHours': value.reservedHours,
        'termsAccepted': value.termsAccepted,
        'vatIncluded': value.vatIncluded,
        'vatExcluded': value.vatExcluded,
        'customerDetails': ApiCustomerDetailsToJSON(value.customerDetails),
        'paymentRows': value.paymentRows === undefined ? undefined : ((value.paymentRows as Array<any>).map(ApiPaymentRowToJSON)),
        'timeslots': ((value.timeslots as Array<any>).map(ApiBookingTimeslotInputToJSON)),
        'paymentMethod': value.paymentMethod,
        'invoiceReference': value.invoiceReference,
        'internal': value.internal,
    };
}

