/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApiCategory } from './ApiCategory';
import {
    ApiCategoryFromJSON,
    ApiCategoryFromJSONTyped,
    ApiCategoryToJSON,
} from './ApiCategory';
import type { ApiEmployee } from './ApiEmployee';
import {
    ApiEmployeeFromJSON,
    ApiEmployeeFromJSONTyped,
    ApiEmployeeToJSON,
} from './ApiEmployee';
import type { ApiImage } from './ApiImage';
import {
    ApiImageFromJSON,
    ApiImageFromJSONTyped,
    ApiImageToJSON,
} from './ApiImage';
import type { ApiPdf } from './ApiPdf';
import {
    ApiPdfFromJSON,
    ApiPdfFromJSONTyped,
    ApiPdfToJSON,
} from './ApiPdf';
import type { ApiProductDisplayType } from './ApiProductDisplayType';
import {
    ApiProductDisplayTypeFromJSON,
    ApiProductDisplayTypeFromJSONTyped,
    ApiProductDisplayTypeToJSON,
} from './ApiProductDisplayType';
import type { ApiProductType } from './ApiProductType';
import {
    ApiProductTypeFromJSON,
    ApiProductTypeFromJSONTyped,
    ApiProductTypeToJSON,
} from './ApiProductType';
import type { ApiResponsibility } from './ApiResponsibility';
import {
    ApiResponsibilityFromJSON,
    ApiResponsibilityFromJSONTyped,
    ApiResponsibilityToJSON,
} from './ApiResponsibility';
import type { ApiService } from './ApiService';
import {
    ApiServiceFromJSON,
    ApiServiceFromJSONTyped,
    ApiServiceToJSON,
} from './ApiService';
import type { ApiSpaceTranslations } from './ApiSpaceTranslations';
import {
    ApiSpaceTranslationsFromJSON,
    ApiSpaceTranslationsFromJSONTyped,
    ApiSpaceTranslationsToJSON,
} from './ApiSpaceTranslations';

/**
 * 
 * @export
 * @interface ApiSpace
 */
export interface ApiSpace {
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    propertyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    roomNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    floorNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiSpace
     */
    roomArea: number;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    roomType?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    serviceDescription?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiSpace
     */
    contractStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ApiSpace
     */
    contractEndDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    reservationStatus?: ApiSpaceReservationStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ApiSpace
     */
    minCapacity?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpace
     */
    maxCapacity?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpace
     */
    tempReserved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpace
     */
    published: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    publishedTimestamp?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiSpace
     */
    highlighted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    displayName: string;
    /**
     * 
     * @type {number}
     * @memberof ApiSpace
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiSpace
     */
    taxRate?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    priceDetails?: string;
    /**
     * 
     * @type {Array<ApiCategory>}
     * @memberof ApiSpace
     */
    categories: Array<ApiCategory>;
    /**
     * 
     * @type {Array<ApiProductDisplayType>}
     * @memberof ApiSpace
     */
    productDisplayTypes: Array<ApiProductDisplayType>;
    /**
     * 
     * @type {Array<ApiImage>}
     * @memberof ApiSpace
     */
    images: Array<ApiImage>;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    defaultImageId?: string;
    /**
     * 
     * @type {Array<ApiPdf>}
     * @memberof ApiSpace
     */
    pdfs: Array<ApiPdf>;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    defaultBlueprintPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    defaultArrivalInstructionsPdfId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    defaultBrochurePdfId?: string;
    /**
     * 
     * @type {ApiSpaceTranslations}
     * @memberof ApiSpace
     */
    translations: ApiSpaceTranslations;
    /**
     * 
     * @type {ApiProductType}
     * @memberof ApiSpace
     */
    productType?: ApiProductType;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    availableFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    availableUntil?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    haltiaId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiSpace
     */
    taitoriId?: string;
    /**
     * 
     * @type {Array<ApiService>}
     * @memberof ApiSpace
     */
    services?: Array<ApiService>;
    /**
     * 
     * @type {Array<ApiResponsibility>}
     * @memberof ApiSpace
     */
    responsibilities?: Array<ApiResponsibility>;
    /**
     * 
     * @type {Array<ApiEmployee>}
     * @memberof ApiSpace
     */
    employees?: Array<ApiEmployee>;
}


/**
 * @export
 */
export const ApiSpaceReservationStatusEnum = {
    Reserved: 'reserved',
    Free: 'free'
} as const;
export type ApiSpaceReservationStatusEnum = typeof ApiSpaceReservationStatusEnum[keyof typeof ApiSpaceReservationStatusEnum];


/**
 * Check if a given object implements the ApiSpace interface.
 */
export function instanceOfApiSpace(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "roomArea" in value;
    isInstance = isInstance && "tempReserved" in value;
    isInstance = isInstance && "published" in value;
    isInstance = isInstance && "highlighted" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "productDisplayTypes" in value;
    isInstance = isInstance && "images" in value;
    isInstance = isInstance && "pdfs" in value;
    isInstance = isInstance && "translations" in value;

    return isInstance;
}

export function ApiSpaceFromJSON(json: any): ApiSpace {
    return ApiSpaceFromJSONTyped(json, false);
}

export function ApiSpaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiSpace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'propertyId': json['propertyId'],
        'roomNumber': !exists(json, 'roomNumber') ? undefined : json['roomNumber'],
        'floorNumber': !exists(json, 'floorNumber') ? undefined : json['floorNumber'],
        'roomArea': json['roomArea'],
        'roomType': !exists(json, 'roomType') ? undefined : json['roomType'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'serviceDescription': !exists(json, 'serviceDescription') ? undefined : json['serviceDescription'],
        'contractStartDate': !exists(json, 'contractStartDate') ? undefined : (json['contractStartDate'] === null ? null : new Date(json['contractStartDate'])),
        'contractEndDate': !exists(json, 'contractEndDate') ? undefined : (json['contractEndDate'] === null ? null : new Date(json['contractEndDate'])),
        'reservationStatus': !exists(json, 'reservationStatus') ? undefined : json['reservationStatus'],
        'minCapacity': !exists(json, 'minCapacity') ? undefined : json['minCapacity'],
        'maxCapacity': !exists(json, 'maxCapacity') ? undefined : json['maxCapacity'],
        'tempReserved': json['tempReserved'],
        'published': json['published'],
        'publishedTimestamp': !exists(json, 'publishedTimestamp') ? undefined : json['publishedTimestamp'],
        'highlighted': json['highlighted'],
        'displayName': json['displayName'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'taxRate': !exists(json, 'taxRate') ? undefined : json['taxRate'],
        'priceDetails': !exists(json, 'priceDetails') ? undefined : json['priceDetails'],
        'categories': ((json['categories'] as Array<any>).map(ApiCategoryFromJSON)),
        'productDisplayTypes': ((json['productDisplayTypes'] as Array<any>).map(ApiProductDisplayTypeFromJSON)),
        'images': ((json['images'] as Array<any>).map(ApiImageFromJSON)),
        'defaultImageId': !exists(json, 'defaultImageId') ? undefined : json['defaultImageId'],
        'pdfs': ((json['pdfs'] as Array<any>).map(ApiPdfFromJSON)),
        'defaultBlueprintPdfId': !exists(json, 'defaultBlueprintPdfId') ? undefined : json['defaultBlueprintPdfId'],
        'defaultArrivalInstructionsPdfId': !exists(json, 'defaultArrivalInstructionsPdfId') ? undefined : json['defaultArrivalInstructionsPdfId'],
        'defaultBrochurePdfId': !exists(json, 'defaultBrochurePdfId') ? undefined : json['defaultBrochurePdfId'],
        'translations': ApiSpaceTranslationsFromJSON(json['translations']),
        'productType': !exists(json, 'productType') ? undefined : ApiProductTypeFromJSON(json['productType']),
        'availableFrom': !exists(json, 'availableFrom') ? undefined : json['availableFrom'],
        'availableUntil': !exists(json, 'availableUntil') ? undefined : json['availableUntil'],
        'haltiaId': !exists(json, 'haltiaId') ? undefined : json['haltiaId'],
        'taitoriId': !exists(json, 'taitoriId') ? undefined : json['taitoriId'],
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(ApiServiceFromJSON)),
        'responsibilities': !exists(json, 'responsibilities') ? undefined : ((json['responsibilities'] as Array<any>).map(ApiResponsibilityFromJSON)),
        'employees': !exists(json, 'employees') ? undefined : ((json['employees'] as Array<any>).map(ApiEmployeeFromJSON)),
    };
}

export function ApiSpaceToJSON(value?: ApiSpace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'propertyId': value.propertyId,
        'roomNumber': value.roomNumber,
        'floorNumber': value.floorNumber,
        'roomArea': value.roomArea,
        'roomType': value.roomType,
        'description': value.description,
        'serviceDescription': value.serviceDescription,
        'contractStartDate': value.contractStartDate === undefined ? undefined : (value.contractStartDate === null ? null : value.contractStartDate.toISOString()),
        'contractEndDate': value.contractEndDate === undefined ? undefined : (value.contractEndDate === null ? null : value.contractEndDate.toISOString()),
        'reservationStatus': value.reservationStatus,
        'minCapacity': value.minCapacity,
        'maxCapacity': value.maxCapacity,
        'tempReserved': value.tempReserved,
        'published': value.published,
        'publishedTimestamp': value.publishedTimestamp,
        'highlighted': value.highlighted,
        'displayName': value.displayName,
        'price': value.price,
        'taxRate': value.taxRate,
        'priceDetails': value.priceDetails,
        'categories': ((value.categories as Array<any>).map(ApiCategoryToJSON)),
        'productDisplayTypes': ((value.productDisplayTypes as Array<any>).map(ApiProductDisplayTypeToJSON)),
        'images': ((value.images as Array<any>).map(ApiImageToJSON)),
        'defaultImageId': value.defaultImageId,
        'pdfs': ((value.pdfs as Array<any>).map(ApiPdfToJSON)),
        'defaultBlueprintPdfId': value.defaultBlueprintPdfId,
        'defaultArrivalInstructionsPdfId': value.defaultArrivalInstructionsPdfId,
        'defaultBrochurePdfId': value.defaultBrochurePdfId,
        'translations': ApiSpaceTranslationsToJSON(value.translations),
        'productType': ApiProductTypeToJSON(value.productType),
        'availableFrom': value.availableFrom,
        'availableUntil': value.availableUntil,
        'haltiaId': value.haltiaId,
        'taitoriId': value.taitoriId,
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(ApiServiceToJSON)),
        'responsibilities': value.responsibilities === undefined ? undefined : ((value.responsibilities as Array<any>).map(ApiResponsibilityToJSON)),
        'employees': value.employees === undefined ? undefined : ((value.employees as Array<any>).map(ApiEmployeeToJSON)),
    };
}

