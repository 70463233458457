import {
  AdminSettingsPublicApi,
  CalendarBookingsPublicApi,
  CategoriesPublicApi,
  Configuration,
  CRMPublicApi,
  InvoicingPublicApi,
  PaymentsPublicApi,
  ProductTypesPublicApi,
  PropertiesPublicApi,
  ReservationsPublicApi,
  SpacesPublicApi,
  UserReservationsPublicApi,
  UsersPublicApi,
  JsonContentPublicApi,
  DistrictsPublicApi,
  ProductDisplayTypesPublicApi,
  TaitoriAvailableSpacesPublicApi,
  EmployeesPublicApi,
  VatPercentPublicApi,
} from '@antilooppi/public-api'
import { environment } from '../environment'
import { UseAuthUser } from './auth/useAuth'

export interface PublicApi {
  bookingsApi: CalendarBookingsPublicApi
  categoriesApi: CategoriesPublicApi
  productTypesApi: ProductTypesPublicApi
  propertiesApi: PropertiesPublicApi
  spacesApi: SpacesPublicApi
  crmApi: CRMPublicApi
  usersApi: UsersPublicApi
  paymentsApi: PaymentsPublicApi
  reservationsApi: ReservationsPublicApi
  adminSettingsApi: AdminSettingsPublicApi
  invoicingApi: InvoicingPublicApi
  userReservationsApi: UserReservationsPublicApi
  jsonContentApi: JsonContentPublicApi
  districtsApi: DistrictsPublicApi
  productDisplayTypesApi: ProductDisplayTypesPublicApi
  taitoriAvailableSpacesApi: TaitoriAvailableSpacesPublicApi
  employeesApi: EmployeesPublicApi
  vatPercentApi: VatPercentPublicApi
}

const createClientConfiguration = (user: UseAuthUser | null): Configuration => {
  return new Configuration({
    basePath: environment.getPublicApiUrl(),
    apiKey: environment.getPublicApiKey(),
    accessToken: user?.accessToken,
  })
}

const createApis = (configuration: Configuration): PublicApi => {
  return {
    bookingsApi: new CalendarBookingsPublicApi(configuration),
    categoriesApi: new CategoriesPublicApi(configuration),
    productTypesApi: new ProductTypesPublicApi(configuration),
    propertiesApi: new PropertiesPublicApi(configuration),
    spacesApi: new SpacesPublicApi(configuration),
    crmApi: new CRMPublicApi(configuration),
    usersApi: new UsersPublicApi(configuration),
    paymentsApi: new PaymentsPublicApi(configuration),
    reservationsApi: new ReservationsPublicApi(configuration),
    adminSettingsApi: new AdminSettingsPublicApi(configuration),
    invoicingApi: new InvoicingPublicApi(configuration),
    userReservationsApi: new UserReservationsPublicApi(configuration),
    jsonContentApi: new JsonContentPublicApi(configuration),
    districtsApi: new DistrictsPublicApi(configuration),
    productDisplayTypesApi: new ProductDisplayTypesPublicApi(configuration),
    taitoriAvailableSpacesApi: new TaitoriAvailableSpacesPublicApi(configuration),
    employeesApi: new EmployeesPublicApi(configuration),
    vatPercentApi: new VatPercentPublicApi(configuration),
  }
}

export const usePublicApi = (user: UseAuthUser | null): PublicApi => {
  return createApis(createClientConfiguration(user))
}
