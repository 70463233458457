/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiTaitoriPaymentInput
 */
export interface ApiTaitoriPaymentInput {
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    bookingReferences: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    stripeCheckoutSessionId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    paymentIntent: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    paymentSecret: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    propertyId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriPaymentInput
     */
    paymentId: string;
}

/**
 * Check if a given object implements the ApiTaitoriPaymentInput interface.
 */
export function instanceOfApiTaitoriPaymentInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bookingReferences" in value;
    isInstance = isInstance && "stripeCheckoutSessionId" in value;
    isInstance = isInstance && "paymentIntent" in value;
    isInstance = isInstance && "paymentSecret" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "paymentId" in value;

    return isInstance;
}

export function ApiTaitoriPaymentInputFromJSON(json: any): ApiTaitoriPaymentInput {
    return ApiTaitoriPaymentInputFromJSONTyped(json, false);
}

export function ApiTaitoriPaymentInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiTaitoriPaymentInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bookingReferences': json['bookingReferences'],
        'stripeCheckoutSessionId': json['stripeCheckoutSessionId'],
        'paymentIntent': json['paymentIntent'],
        'paymentSecret': json['paymentSecret'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'email': json['email'],
        'paymentId': json['paymentId'],
    };
}

export function ApiTaitoriPaymentInputToJSON(value?: ApiTaitoriPaymentInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bookingReferences': value.bookingReferences,
        'stripeCheckoutSessionId': value.stripeCheckoutSessionId,
        'paymentIntent': value.paymentIntent,
        'paymentSecret': value.paymentSecret,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'email': value.email,
        'paymentId': value.paymentId,
    };
}

