import React, { createContext, ReactNode, RefObject, useContext, useRef, useState } from 'react'

export type SidebarContextValue = {
  visible: boolean
  showSidebar: () => void
  hideSidebar: () => void
  toggleSidebar: () => void
  setSidebarContent: (content: ReactNode) => void
  scrollToTop: () => void
  sidebarProps: { ref: RefObject<HTMLDivElement>; onHide: () => void; visible: boolean }
  sidebarContent: ReactNode | null
}
export type SidebarProviderProps = { children: ReactNode }

export type SidebarData = {
  content: ReactNode | null
  visible: boolean
}

const SidebarContext = createContext<SidebarContextValue | undefined>(undefined)

const initialState: SidebarData = {
  content: null,
  visible: false,
}

export const SidebarProvider = ({ children }: SidebarProviderProps) => {
  const [sidebar, setSidebar] = useState<SidebarData>(initialState)

  const sidebarRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => {
    if (sidebarRef && sidebarRef.current) {
      sidebarRef.current.scrollTo(0, 0)
    }
  }

  const showSidebar = () => {
    setSidebar((prevSidebar) => ({ ...prevSidebar, visible: true }))
  }

  const hideSidebar = () => {
    setSidebar((prevSidebar) => ({ ...prevSidebar, visible: false }))
    setSidebarContent(null)
  }

  const toggleSidebar = () => {
    if (sidebar.visible) {
      hideSidebar()
    } else {
      showSidebar()
    }
  }

  const setSidebarContent = (content: ReactNode) => {
    setSidebar((prevSidebar) => ({ ...prevSidebar, content }))
  }

  const sidebarProps = {
    ref: sidebarRef,
    onHide: () => toggleSidebar(),
    visible: sidebar.visible,
  }

  return (
    <SidebarContext.Provider
      value={{
        visible: sidebar.visible,
        showSidebar,
        hideSidebar,
        toggleSidebar,
        setSidebarContent,
        scrollToTop,
        sidebarProps,
        sidebarContent: sidebar.content,
      }}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => {
  const context = useContext(SidebarContext)
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}
