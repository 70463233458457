import { AntilooppiConfig } from '../antilooppi-config'

export const antilooppiConfigTest: AntilooppiConfig = {
  hostname: 'https://test.spaces.antilooppi.fi',
  b2cClientId: '5c09161d-96ec-404f-9884-f49d58db3385',
  b2cTenantName: 'antilooppib2ctest',
  b2cApiAudience: 'dd61c530-1212-4fb0-8902-0d97e9c6187a',
  b2cHostName: 'test.login.antilooppi.fi',
  privateApiUrl: 'https://test.private-api.antilooppi.fi/api',
  privateApiKey: '40bee79f09aa4bfa85535d3a1e3f49f5',
  publicApiUrl: 'https://test.api.antilooppi.fi/api',
  publicApiKey: '583dae588aa24e9a816a0c6ff06eae50',
  googleMapsApiKey: 'AIzaSyDRC6qZ48e3_T6rlHmd-7xwDjz1eibbv60',
  stripePublicKey:
    'pk_test_51Ld5nFGavmKkMUZajLgRHd1AKqV7sTadTd4pt2iQdp22iWVcLCf8FUi2fq8PMd3Udywvf3VuuY9MalZEYSqA6gAO0085RmqEXH',
  friendlyCaptchaSiteKey: 'FCMJ275GALGSAB76',
  jsonContentCredentials: 'antilooppi:jsoncontent',
}
