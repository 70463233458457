/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiPaymentRow
 */
export interface ApiPaymentRow {
    /**
     * 
     * @type {string}
     * @memberof ApiPaymentRow
     */
    id?: string;
    /**
     * Date in ISO format
     * @type {string}
     * @memberof ApiPaymentRow
     */
    startDate: string;
    /**
     * Date in ISO format
     * @type {string}
     * @memberof ApiPaymentRow
     */
    endDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApiPaymentRow
     */
    paymentId?: string;
}

/**
 * Check if a given object implements the ApiPaymentRow interface.
 */
export function instanceOfApiPaymentRow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;

    return isInstance;
}

export function ApiPaymentRowFromJSON(json: any): ApiPaymentRow {
    return ApiPaymentRowFromJSONTyped(json, false);
}

export function ApiPaymentRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiPaymentRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startDate': json['startDate'],
        'endDate': json['endDate'],
        'paymentId': !exists(json, 'payment_id') ? undefined : json['payment_id'],
    };
}

export function ApiPaymentRowToJSON(value?: ApiPaymentRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'payment_id': value.paymentId,
    };
}

