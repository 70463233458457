import { ApiProperty, ApiSpace } from '@antilooppi/public-api'
import { isToday } from 'date-fns'
import add from 'date-fns/add'
import _ from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import modalDialogClose from '../assets/modal-dialog-close.svg'
import searchArrowHead from '../assets/search-arrow-head.svg'
import searchArrowStem from '../assets/search-arrow-stem.svg'
import ActiveFilterGroup from '../components/Filter/ActiveFilterGroup'
import CapacityAndArea from '../components/Filter/CapacityAndArea'
import { DateTimeTab } from '../components/Filter/DateTimeTab'
import DistrictsAndProperties from '../components/Filter/DistrictsAndProperties'
import FilterLoadingSpinner from '../components/Filter/FilterLoadingSpinner'
import ProductTypesAndKeywords from '../components/Filter/ProductTypesAndKeywords'
import SpaceGridItem from '../components/Filter/SpaceGridItem'
import Icon from '../components/Icon'
import Image from '../components/Image'
import ModalDialog from '../components/ModalDialog'
import DefaultNavBar from '../components/NavBar/DefaultNavBar'
import PageContent from '../components/PageContent'
import { useCategories } from '../lib/api/categories'
import { useDistricts } from '../lib/api/districts'
import { useProductDisplayTypes } from '../lib/api/product-display-types'
import { useProperties } from '../lib/api/properties'
import { useTaitoriAvailableSpaces } from '../lib/api/taitori-available-spaces'
import {
  filterByArea,
  filterByCapacity,
  filterByDistricts,
  filterByKeywords,
  filterByProductDisplayType,
  filterByProperties,
  filterByReservable,
  filterByTaitoriAvailableSpace,
} from '../lib/filter'
import { DateTimeSelectionState, FilterMinMaxItem, FilterSelectedItem } from '../lib/filter-types/filter-types'
import { iconChevron } from '../lib/icons/icons'
import { useOutsideClick } from '../lib/useOutsideClick'
import { setInSessionStorage } from '../lib/storage/sessionStorage'
import { PropertyIncludes } from '@antilooppi/helpers'
import Sidebar from '../components/Sidebar'
import { useSidebar } from '../lib/useSidebar'

type PropertySpace = {
  property: ApiProperty
  space: ApiSpace
}

enum MainFilterType {
  Renting = '1',
  Reserving = '2',
}

export default function Filter() {
  const [open, setOpen] = useState<boolean>(false)
  const [openedTab, setOpenedTab] = useState<boolean[]>([])
  const { i18n, t } = useTranslation()
  const { data: districts } = useDistricts()
  const router = useRouter()

  const { sidebarProps } = useSidebar()

  const {
    query: {
      s: state, // Vuokraamassa = 1, Varaamassa = 2, Selaa kaikkia = undefined
      districts: queryDistricts, // Selected district ids
      properties: queryProperties, // Selected property ids
      capacityMin: queryCapacityMin, // Selected min capacity
      capacityMax: queryCapacityMax, // Selected max capacity
      areaMin: queryAreaMin,
      areaMax: queryAreaMax,
      productDisplayTypes: queryProductDisplayTypes,
      keywords: queryKeywords,
      startTime: queryStartTime,
      endTime: queryEndTime,
    },
  } = router

  const isRenting = () => {
    return state === MainFilterType.Renting
  }

  const isReserving = () => {
    return state === MainFilterType.Reserving
  }

  const isBrowsingAll = () => {
    return state === undefined
  }

  const {
    isLoading,
    isError,
    data: properties,
  } = useProperties({}, [
    PropertyIncludes.FREE_SPACES_ONLY,
    PropertyIncludes.RESERVED_PUBLISHED_SPACES,
    PropertyIncludes.PUBLISHED_ONLY,
    PropertyIncludes.FIRST_SPACE_IMAGE_ONLY,
    PropertyIncludes.NO_PROPERTY_IMAGES,
    PropertyIncludes.NO_PDFS,
    PropertyIncludes.NO_PRODUCT_TYPE,
    PropertyIncludes.NO_SERVICES,
    PropertyIncludes.NO_RESPONSIBILITIES,
    PropertyIncludes.NO_DISTANCES,
  ])

  const propertySpaces: PropertySpace[] = (properties || [])
    .map((p) => (p.freeSpaces || []).map((s) => ({ property: p, space: s })))
    .flat()

  const [selectedProperties, setSelectedProperties] = useState<FilterSelectedItem[]>([])
  const [selectedDistricts, setSelectedDistricts] = useState<FilterSelectedItem[]>([])
  const [selectedKeywords, setSelectedKeywords] = useState<FilterSelectedItem[]>([])
  const [selectedProductDisplayTypes, setSelectedProductDisplayTypes] = useState<FilterSelectedItem[]>([])

  const { data: keywords } = useCategories()
  const { data: productDisplayTypes } = useProductDisplayTypes()

  const [dateTimeSelectionState, setDateTimeSelectionState] = useState<DateTimeSelectionState>(
    DateTimeSelectionState.AllClosed
  )

  const [selectedCapacity, setSelectedCapacity] = useState<FilterMinMaxItem>({ min: undefined, max: undefined })
  const [selectedArea, setSelectedArea] = useState<FilterMinMaxItem>({ min: undefined, max: undefined })
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return today
  })

  const [selectedStartTime, setSelectedStartTime] = useState<Date>(() => {
    const startTime = new Date()
    if (startTime.getMinutes() < 30) {
      startTime.setHours(startTime.getHours(), 30, 0, 0)
    } else {
      startTime.setHours(startTime.getHours() + 1, 0, 0, 0)
    }
    setInSessionStorage<Date>('startTime', startTime)
    return startTime
  })

  const [selectedEndTime, setSelectedEndTime] = useState<Date>(() => {
    const endTime = new Date(selectedStartTime)
    endTime.setHours(selectedStartTime.getHours() + 1)
    setInSessionStorage<Date>('endTime', endTime)
    return endTime
  })

  const setStartTime = (d: Date) => {
    const date = ensureSelectedDate(d)

    if (date.getTime() < selectedEndTime.getTime()) {
      storeStartTime(date)
    } else if (date.getTime() === selectedEndTime.getTime()) {
      // Start and end times are equal -> add 1 hour to end time
      const endTime = new Date(selectedEndTime)
      endTime.setHours(endTime.getHours() + 1)
      storeEndTime(endTime)
      storeStartTime(date)
      updateReservationDateTimeQueryParameters(date, endTime)
    } else if (date.getTime() > selectedEndTime.getTime()) {
      const endTime = new Date(date)
      endTime.setHours(endTime.getHours() + 1)
      storeEndTime(endTime)
      storeStartTime(date)
      updateReservationDateTimeQueryParameters(date, endTime)
    }
  }

  const setEndTime = (d: Date) => {
    const date = ensureSelectedDate(d)

    if (date.getTime() > selectedStartTime.getTime()) {
      storeEndTime(date)
    } else if (date.getTime() === selectedStartTime.getTime()) {
      const startTime = new Date(date)
      startTime.setHours(startTime.getHours() - 1)
      storeStartTime(startTime)
      storeEndTime(date)
      updateReservationDateTimeQueryParameters(startTime, date)
    } else if (date.getTime() < selectedStartTime.getTime()) {
      const startTime = new Date(date)
      startTime.setHours(date.getHours() - 1)
      storeStartTime(startTime)
      storeEndTime(date)
      updateReservationDateTimeQueryParameters(startTime, date)
    }
  }

  // Make sure we're using the selected date for space availability checks
  const ensureSelectedDate = (d: Date): Date => {
    const date = new Date(selectedDate)
    date.setHours(d.getHours())
    date.setMinutes(d.getMinutes())

    return date
  }

  const ensureValidStartTime = (date: Date): Date => {
    const now = new Date()
    const adjusted = date
    adjusted.setSeconds(0, 0)

    if (isToday(date)) {
      if (selectedStartTime.getTime() > now.getTime()) {
        return selectedStartTime
      } else {
        if (now.getMinutes() > 30) {
          adjusted.setHours(now.getHours() + 1)
          adjusted.setMinutes(0)
        } else {
          adjusted.setMinutes(30)
        }

        storeStartTime(adjusted)
        return adjusted
      }
    }

    return selectedStartTime
  }

  const ensureValidEndTime = (date: Date): Date => {
    const validStartTime = ensureValidStartTime(date)
    let adjusted = new Date(validStartTime)

    if (selectedEndTime.getTime() > validStartTime.getTime()) {
      return selectedEndTime
    } else {
      adjusted = add(adjusted, { hours: 1 })
    }

    storeEndTime(adjusted)

    return adjusted
  }

  const storeStartTime = (startTime: Date) => {
    updateReservationDateTimeQueryParameters(startTime, selectedEndTime)
    setSelectedStartTime(startTime)
    setInSessionStorage<Date>('startTime', startTime)
  }

  const storeEndTime = (endTime: Date) => {
    updateReservationDateTimeQueryParameters(selectedStartTime, endTime)
    setSelectedEndTime(endTime)
    setInSessionStorage<Date>('endTime', endTime)
  }

  // Get available rooms from Taitori matching selected date and start/end times
  const {
    isLoading: isTaitoriLoading,
    isError: isTaitoriError,
    data: taitoriAvailableSpaces,
    fetchStatus: taitoriFetchStatus,
  } = useTaitoriAvailableSpaces(
    ensureValidStartTime(selectedStartTime).toISOString(),
    ensureValidEndTime(selectedEndTime).toISOString(),
    isReserving() || isBrowsingAll()
  )

  const toggleTab = (tabIndex: number) => {
    // 1. All closed, click on tab 1 -> open tab 1
    if (!openedTab.includes(true)) {
      const tabs = _.cloneDeep(openedTab)
      tabs[tabIndex] = true
      setOpenedTab(tabs)
      setOpen(true)
      return
    }

    // 2. Tab 1 open, click on tab 1 -> close tab 1
    if (openedTab[tabIndex]) {
      const tabs = _.cloneDeep(openedTab)
      tabs.fill(false)
      setOpenedTab(tabs)
      setOpen(false)
      return
    }

    // 3. Tab 1 open, click on tab 2 -> open tab 2, close tab 1
    if (!openedTab[tabIndex] && openedTab.includes(true)) {
      const tabs = _.cloneDeep(openedTab)
      tabs.fill(false)
      tabs[tabIndex] = true
      setOpenedTab(tabs)
      setOpen(true)
      return
    }

    throw new Error('implementation error')
  }

  const tabStyle = (tabIndex: number) =>
    `${tabIndex === 0 ? 'rounded-l-full ' : ''} flex flex-row border-[1.5px] ${tabIndex !== 0 ? 'border-l-0' : ''} ${
      tabIndex !== 0 && openedTab[tabIndex] ? '' : ''
    } border-solid border-darkblue py-1.5 px-3 text-2xl duration-0 ${openedTab[tabIndex] ? 'bg-white' : ''}`

  const toggleDateTimeSelectionState = (targetState: DateTimeSelectionState) => {
    if (dateTimeSelectionState === targetState) {
      // User clicked on tab that was already open -> close tab
      setDateTimeSelectionState(DateTimeSelectionState.AllClosed)
    } else {
      // User clicked on new tab -> close previous one and open the new one
      setDateTimeSelectionState(targetState)
    }
  }

  const handleDateSelected = (date: Date) => {
    setDateTimeSelectionState(DateTimeSelectionState.AllClosed)
    setSelectedDate(date)

    // Also update start and time selections.
    // If current day is selected, ensure start/end times are in the future.
    const newStartTime = new Date(selectedStartTime)
    const newEndTime = new Date(selectedEndTime)
    newStartTime.setDate(date.getDate())
    newStartTime.setFullYear(date.getFullYear())
    newStartTime.setMonth(date.getMonth())
    newEndTime.setDate(date.getDate())
    newEndTime.setFullYear(date.getFullYear())
    newEndTime.setMonth(date.getMonth())

    if (isToday(date)) {
      const now = new Date()
      newStartTime.setHours(now.getHours() + 1)
      newEndTime.setHours(now.getHours() + 2)
    }

    storeStartTime(newStartTime)
    storeEndTime(newEndTime)

    updateReservationDateTimeQueryParameters(newStartTime, newEndTime)
  }

  const updateReservationDateTimeQueryParameters = (startTime: Date, endTime: Date) => {
    const queryParameters = constructQueryParameters({
      startTime: startTime.getTime(),
      endTime: endTime.getTime(),
    })
    router.replace(queryParameters, undefined, { shallow: true })
  }

  const handleClickOutside = () => {
    setOpenedTab(openedTab.fill(false))
    setOpen(false)
  }
  const ref = useOutsideClick(handleClickOutside, open)

  const [modalFilterLocationOpen, setModalFilterLocationOpen] = useState(false)
  const [modalFilterSizeOpen, setModalFilterSizeOpen] = useState(false)
  const [modalFilterSpaceTypeOpen, setModalFilterSpaceTypeOpen] = useState(false)
  const [modalFilterDateTimeOpen, setModalFilterDateTimeOpen] = useState(false)

  const setAllModalFiltersClosed = () => {
    if (window.scrollY > window.innerHeight) {
      // Ensure user doesn't have to scroll to top when switching between filter types
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    setModalFilterLocationOpen(false)
    setModalFilterSizeOpen(false)
    setModalFilterSpaceTypeOpen(false)
    setModalFilterDateTimeOpen(false)
  }

  const toggleModalFilterLocationOpen = () => {
    setAllModalFiltersClosed()
    setModalFilterLocationOpen(!modalFilterLocationOpen)
  }

  const toggleModalFilterSizeOpen = () => {
    setAllModalFiltersClosed()
    setModalFilterSizeOpen(!modalFilterSizeOpen)
  }

  const toggleModalFilterSpaceTypeOpen = () => {
    setAllModalFiltersClosed()
    setModalFilterSpaceTypeOpen(!modalFilterSpaceTypeOpen)
  }

  const toggleModalFilterDateTimeOpen = () => {
    setAllModalFiltersClosed()
    setModalFilterDateTimeOpen(!modalFilterDateTimeOpen)
  }

  const rentingReservingToggleStyle =
    'bg-offwhite border-darkblue flex cursor-pointer select-none justify-center border-solid px-5 py-1.5 text-2xl w-40 md:w-48 border-[2px] md:border-[1.5px]'
  const mobileFilterTitleStyle = 'border-darkblue relative rounded-full border-2 py-1.5 px-5 text-2xl w-full'

  const [modalFilterShown, setModalFilterShown] = useState(false)

  const toggleModalFilter = () => {
    setModalFilterShown(!modalFilterShown)
  }

  const modalFilterHeaderOpenStyle = 'rounded-t-3xl rounded-b-3xl bg-white'

  const filteredSpaces = useMemo(() => {
    return propertySpaces
      ?.filter((propSpace) => filterByReservable(propSpace, state, state === MainFilterType.Reserving))
      ?.filter((propSpace) => filterByDistricts(propSpace, selectedDistricts))
      ?.filter((propSpace) => filterByProperties(propSpace, selectedProperties))
      ?.filter((propSpace) => filterByKeywords(propSpace, selectedKeywords, i18n.language))
      ?.filter((propSpace) => filterByCapacity(propSpace, selectedCapacity))
      ?.filter((propSpace) => filterByArea(propSpace, selectedArea))
      ?.filter((propSpace) => filterByProductDisplayType(propSpace, selectedProductDisplayTypes, i18n.language))
      ?.filter((propSpace) =>
        state !== MainFilterType.Reserving
          ? true
          : filterByTaitoriAvailableSpace(propSpace, isTaitoriLoading, isTaitoriError, taitoriAvailableSpaces)
      )
  }, [
    i18n.language,
    isTaitoriError,
    isTaitoriLoading,
    propertySpaces,
    selectedArea,
    selectedCapacity,
    selectedDistricts,
    selectedKeywords,
    selectedProductDisplayTypes,
    selectedProperties,
    state,
    taitoriAvailableSpaces,
  ])

  useEffect(() => {
    const getDistrictsFromQueryString = (): FilterSelectedItem[] => {
      let items: FilterSelectedItem[] = []

      if (districts && queryDistricts && queryDistricts.length > 0) {
        const arr = queryDistricts.toString().split(' ')
        const filtered = districts.filter((d) => arr.find((a) => a === d.id))
        items = filtered.map((d) => {
          return {
            id: d.id,
            name: d.name,
          }
        })
      }
      return items
    }

    const getPropertiesFromQueryString = (): FilterSelectedItem[] => {
      let items: FilterSelectedItem[] = []

      if (properties && queryProperties && queryProperties.length > 0) {
        const arr = queryProperties.toString().split(' ')
        const filtered = properties.filter((p) => arr.find((a) => a === p.id))
        items = filtered.map((p) => {
          return {
            id: p.id,
            name: (i18n.language === 'fi' ? p.translations.fi.displayName : p.translations.en?.displayName) ?? '',
          }
        })
      }
      return items
    }

    const getCapacityFromQueryString = (): FilterMinMaxItem | undefined => {
      if (queryCapacityMin && !queryCapacityMax) {
        return { min: parseInt(queryCapacityMin.toString()), max: undefined }
      } else if (!queryCapacityMin && queryCapacityMax) {
        return { min: undefined, max: parseInt(queryCapacityMax.toString()) }
      } else if (queryCapacityMin && queryCapacityMax) {
        return { min: parseInt(queryCapacityMin.toString()), max: parseInt(queryCapacityMax.toString()) }
      }
    }

    const getAreaFromQueryString = (): FilterMinMaxItem | undefined => {
      if (queryAreaMin && !queryAreaMax) {
        return { min: parseInt(queryAreaMin.toString()), max: undefined }
      } else if (!queryAreaMin && queryAreaMax) {
        return { min: undefined, max: parseInt(queryAreaMax.toString()) }
      } else if (queryAreaMin && queryAreaMax) {
        return { min: parseInt(queryAreaMin.toString()), max: parseInt(queryAreaMax.toString()) }
      }
    }

    const getProductDisplayTypesFromQueryString = (): FilterSelectedItem[] => {
      let items: FilterSelectedItem[] = []

      if (productDisplayTypes && queryProductDisplayTypes && queryProductDisplayTypes.length > 0) {
        const arr = queryProductDisplayTypes.toString().split(' ')
        const filtered = productDisplayTypes.filter((pdt) => arr.find((a) => a === pdt.id))
        items = filtered.map((pdt) => {
          return {
            id: pdt.id,
            name: i18n.language === 'fi' ? pdt.nameFi : pdt.nameEn,
          }
        })
      }
      return items
    }

    const getKeywordsFromQueryString = (): FilterSelectedItem[] => {
      let items: FilterSelectedItem[] = []

      if (keywords && queryKeywords && queryKeywords.length > 0) {
        const arr = queryKeywords.toString().split(' ')
        const filtered = keywords.filter((keyword) => arr.find((a) => a === keyword.id))
        items = filtered.map((keyword) => {
          return {
            id: keyword.id,
            name: i18n.language === 'fi' ? keyword.nameFi : keyword.nameEn,
          }
        })
      }
      return items
    }

    const getReservationStartTimeFromQueryString = () => {
      if (queryStartTime) {
        return new Date(parseInt(queryStartTime as string))
      }
    }

    const getReservationEndTimeFromQueryString = () => {
      if (queryEndTime) {
        return new Date(parseInt(queryEndTime as string))
      }
    }

    setSelectedDistricts(getDistrictsFromQueryString())
    setSelectedProperties(getPropertiesFromQueryString())
    const capacity = getCapacityFromQueryString()
    if (capacity) {
      setSelectedCapacity(capacity)
    }
    const area = getAreaFromQueryString()
    if (area) {
      setSelectedArea(area)
    }
    setSelectedProductDisplayTypes(getProductDisplayTypesFromQueryString())
    setSelectedKeywords(getKeywordsFromQueryString())

    const startTime = getReservationStartTimeFromQueryString()
    if (startTime) {
      setSelectedStartTime(startTime)
      setSelectedDate(startTime)
    }
    const endTime = getReservationEndTimeFromQueryString()
    if (endTime) {
      setSelectedEndTime(endTime)
    }
  }, [
    queryDistricts,
    queryProperties,
    districts,
    properties,
    i18n.language,
    queryCapacityMin,
    queryCapacityMax,
    queryAreaMin,
    queryAreaMax,
    productDisplayTypes,
    queryProductDisplayTypes,
    keywords,
    queryKeywords,
    queryStartTime,
    queryEndTime,
  ])

  type SelectedItems = {
    districts: FilterSelectedItem[]
    properties: FilterSelectedItem[]
    area: FilterMinMaxItem
    capacity: FilterMinMaxItem
    productDisplayTypes: FilterSelectedItem[]
    keywords: FilterSelectedItem[]
    startTime: number
    endTime: number
  }

  const constructQueryParameters = (items?: Partial<SelectedItems>, newState?: string) => {
    let str = ''
    if (newState && newState !== '') {
      str += `?s=${newState.toString()}`
    } else if (newState === '') {
      str += ''
    } else if (newState === undefined && state && state !== '') {
      str += `?s=${state.toString()}`
    }

    if (str.length > 0) {
      str += '&'
    } else {
      str += '?'
    }

    if (items?.districts)
      str += items.districts.length > 0 ? `districts=${items?.districts.map((d) => d.id).join('+')}&` : ''
    else if (selectedDistricts.length > 0) str += `districts=${selectedDistricts.map((d) => d.id).join('+')}&`

    if (items?.properties)
      str += items.properties.length > 0 ? `properties=${items?.properties.map((d) => d.id).join('+')}&` : ''
    else if (selectedProperties.length > 0) str += `properties=${selectedProperties.map((d) => d.id).join('+')}&`

    if (items?.productDisplayTypes) {
      str +=
        items.productDisplayTypes.length > 0
          ? `productDisplayTypes=${items.productDisplayTypes.map((d) => d.id).join('+')}&`
          : ''
    } else {
      // Ensure there are only valid product display types in the URL
      let filteredProductDisplayTypes: FilterSelectedItem[] = []
      if (newState === MainFilterType.Renting) {
        filteredProductDisplayTypes = selectedProductDisplayTypes.filter((pdt) => pdt.reservable === false)
      } else if (newState === MainFilterType.Reserving) {
        filteredProductDisplayTypes = selectedProductDisplayTypes.filter((pdt) => pdt.reservable === true)
      }
      if (filteredProductDisplayTypes.length > 0) {
        str += `productDisplayTypes=${selectedProductDisplayTypes.map((d) => d.id).join('+')}&`
      }
    }

    if (items?.keywords) {
      str += items.keywords.length > 0 ? `keywords=${items.keywords.map((d) => d.id).join('+')}&` : ''
    } else if (selectedKeywords.length > 0) {
      str += `keywords=${selectedKeywords.map((d) => d.id).join('+')}&`
    }

    // Update min/max area when set or cleared, otherwise use the stored state value
    if (items?.area && items.area.min !== undefined) str += `areaMin=${items.area.min}&`
    else if (items?.area && items.area.min === undefined) str += ''
    else if (selectedArea.min !== undefined) str += `areaMin=${selectedArea.min}&`
    if (items?.area && items.area.max !== undefined) str += `&areaMax=${items.area.max}`
    else if (items?.area && items.area.max === undefined) str += ''
    else if (selectedArea.max !== undefined) str += `areaMax=${selectedArea.max}&`

    // Update min/max capacity when set or cleared, otherwise use the stored state value
    if (items?.capacity && items.capacity.min !== undefined) str += `capacityMin=${items.capacity.min}&`
    else if (items?.capacity && items.capacity.min === undefined) str += ''
    else if (selectedCapacity.min !== undefined) str += `capacityMin=${selectedCapacity.min}`
    if (items?.capacity && items.capacity.max !== undefined) str += `capacityMax=${items.capacity.max}&`
    else if (items?.capacity && items.capacity.max === undefined) str += ''
    else if (selectedCapacity.max !== undefined) str += `capacityMax=${selectedCapacity.max}&`

    // Reservation start and end times (if not default)
    if (items?.startTime && items?.endTime) {
      str += `startTime=${items.startTime}&endTime=${items.endTime}&`
    }

    // Clean up URL
    if (str.charAt(str.length - 1).search('[?|&]') === 0) {
      str = str.slice(0, str.length - 1)
    }
    str = str.replace('&&', '&')

    return str
  }

  const taitoriIdle = () => {
    return taitoriFetchStatus === 'idle'
  }

  return (
    <>
      <DefaultNavBar />
      <PageContent>
        <div className='relative flex w-full flex-col px-8'>
          {/* Title and description */}
          <div className='flex w-full flex-row justify-center'>
            <div className='flex w-fit flex-col items-center'>
              <h1 className='text-center text-[2.9rem] leading-[3rem]'>{t('searchPageTitle')}</h1>
              <div className='mt-2 flex flex-col px-3 text-center text-2xl md:w-[35rem] md:px-0'>
                {t('searchPageDescription')}
              </div>
            </div>
          </div>
          {/* Vuokraamassa / Varaamassa */}
          <div className='mt-5 flex flex-col md:w-full'>
            <div className='flex w-full flex-row justify-center'>
              <Link
                href={constructQueryParameters(undefined, MainFilterType.Renting)}
                replace={true}
                onClick={() => {
                  // Clear selected product types that are not reservable
                  setSelectedProductDisplayTypes(selectedProductDisplayTypes.filter((pdt) => !pdt.reservable))
                }}>
                <div
                  className={`${
                    isRenting() ? 'bg-darkblue text-offwhite' : ''
                  } ${rentingReservingToggleStyle} rounded-l-full border-r-[2px] md:border-r-[1.5px]`}>
                  {t('renting')}
                </div>
              </Link>
              <Link
                href={constructQueryParameters(undefined, MainFilterType.Reserving)}
                replace={true}
                onClick={() => {
                  // Clear selected product types that are reservable
                  setSelectedProductDisplayTypes(selectedProductDisplayTypes.filter((pdt) => pdt.reservable))
                }}>
                <div
                  className={`${
                    isReserving() ? 'bg-darkblue text-offwhite' : ''
                  } ${rentingReservingToggleStyle} rounded-r-full border-l-[0px] md:border-l-[0px]`}>
                  {t('reserving')}
                </div>
              </Link>
            </div>
            <div className='mt-4 flex select-none flex-row justify-center text-2xl'>
              {t('or')}&nbsp;
              <Link
                href={constructQueryParameters(undefined, '')}
                replace={true}
                className='cursor-pointer underline decoration-1 underline-offset-2'>
                {t('browseAll')}
              </Link>
            </div>
          </div>

          {/* Mobile only: 'Tarkenna hakua' button */}
          <div
            className='mt-5 w-fit cursor-pointer self-center rounded-full border-[2px] px-5 py-1.5 text-2xl md:hidden'
            onClick={() => toggleModalFilter()}>
            {t('filterResults')}
          </div>

          {/* Desktop only: Full-width filter controls */}
          <div className='mt-8 mr-[5rem] hidden h-full min-w-[43rem] flex-row self-center md:flex'>
            <div className='mr-4 w-[9rem] select-none whitespace-nowrap py-2 text-right text-2xl'>
              {t('filterResults')}
            </div>
            <div className='flex w-full flex-col' ref={ref}>
              <div className='relative flex cursor-pointer select-none flex-row'>
                <div
                  className={`${tabStyle(0)} relative`}
                  onClick={() => {
                    toggleTab(0)
                  }}>
                  <div className={`ml-[0.5rem] ${isReserving() ? 'w-[7rem]' : 'w-[10rem]'} whitespace-nowrap`}>
                    {t('location')}
                  </div>
                  <TabChevronIcon tabOpen={openedTab[0]} />

                  {/* Bottom border cover */}
                  <div
                    className={`duration-0 ${
                      openedTab[0]
                        ? isReserving()
                          ? 'w-[8.875rem] bg-white'
                          : 'w-[11.875rem] bg-white'
                        : 'bg-offwhite w-0'
                    } absolute -bottom-[1.5px] right-0 mt-1 h-[1.5px]`}
                  />
                </div>
                <div
                  className={`${tabStyle(1)} duration-0 relative`}
                  onClick={() => {
                    toggleTab(1)
                  }}>
                  <div className={`${isReserving() ? 'w-[7rem]' : 'w-[10rem]'} whitespace-nowrap`}>{t('size')}</div>
                  <TabChevronIcon tabOpen={openedTab[1]} />

                  {/* Bottom border cover */}
                  <div
                    className={`duration-0 ${
                      openedTab[1]
                        ? isReserving()
                          ? 'w-[9.9rem] bg-white'
                          : 'w-[12.875rem] bg-white'
                        : 'bg-offwhite w-0'
                    } absolute -bottom-[1.5px] right-0 mt-1 h-[1.5px]`}
                  />
                </div>
                <div
                  className={`${tabStyle(2)} relative`}
                  onClick={() => {
                    toggleTab(2)
                  }}>
                  <div className={`${isReserving() ? 'w-[7rem]' : 'w-[9.45rem]'} whitespace-nowrap`}>
                    {t('spaceType')}
                  </div>
                  <TabChevronIcon className={`${isReserving() ? 'ml-2' : 'ml-2 mr-6'}`} tabOpen={openedTab[2]} />

                  {/* Bottom border cover */}
                  <div
                    className={`duration-0 ${
                      openedTab[2]
                        ? isReserving()
                          ? 'w-[9.9rem] bg-white'
                          : 'w-[13.825rem] bg-white'
                        : 'bg-offwhite w-0'
                    } absolute -bottom-[1.5px] right-0 mt-1 h-[1.5px]`}
                  />
                </div>
                {isReserving() && (
                  <div
                    className={`${tabStyle(3)} relative min-w-[8rem]`}
                    onClick={() => {
                      toggleTab(3)
                    }}>
                    <div className='w-[5.5rem] whitespace-nowrap'>{t('time')}</div>
                    <TabChevronIcon className='mr-6' tabOpen={openedTab[3]} />
                    {/* Bottom border cover */}
                    <div
                      className={`duration-0 ${
                        openedTab[3] ? 'w-[9.875rem] bg-white' : 'bg-offwhite w-0'
                      } absolute -bottom-[1.5px] right-0 mt-1 h-[1.5px]`}
                    />
                  </div>
                )}

                {/* Arrow circle */}
                <div className='relative' onClick={handleClickOutside}>
                  <div className='bg-darkblue absolute right-[-1.35rem] h-[2.95rem] w-[2.95rem] rounded-full' />
                  <div className='absolute right-[-0.85rem] top-[0.85rem] h-5 w-5'>
                    <Image src={searchArrowStem} alt='' layout='fill' />
                  </div>
                  <div className='absolute right-[-0.45rem] top-[0.85rem] h-5 w-5'>
                    <Image src={searchArrowHead} alt='' layout='fill' />
                  </div>
                </div>
              </div>
              {open && openedTab[0] && districts && properties && (
                <DistrictsAndProperties
                  districts={districts}
                  selectedDistricts={selectedDistricts}
                  properties={properties.filter((property) => property.translations.fi.displayName)}
                  selectedProperties={selectedProperties}
                  selectDistricts={(districts) => {
                    setSelectedDistricts(districts)
                    const queryParameters = constructQueryParameters({ districts: districts })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                  selectProperties={(properties) => {
                    setSelectedProperties(properties)
                    const queryParameters = constructQueryParameters({ properties: properties })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                />
              )}
              {open &&
                openedTab[1] && ( // Henkilömäärä
                  <CapacityAndArea
                    selectedCapacity={selectedCapacity}
                    selectedArea={selectedArea}
                    selectCapacity={(capacity) => {
                      setSelectedCapacity(capacity)
                      const queryParameters = constructQueryParameters({ capacity: capacity })
                      router.replace(queryParameters, undefined, { shallow: true })
                    }}
                    selectArea={(area) => {
                      setSelectedArea(area)
                      const queryParameters = constructQueryParameters({ area: area })
                      router.replace(queryParameters, undefined, { shallow: true })
                    }}
                    maxCapacity={500}
                    maxArea={2000}
                    isReserving={isReserving()}
                  />
                )}
              {open && openedTab[2] && productDisplayTypes && keywords && (
                // Tilan tyyppi (tuotetyypin näyttönimi, avainsanat)
                <ProductTypesAndKeywords
                  productDisplayTypes={
                    isBrowsingAll()
                      ? productDisplayTypes
                      : productDisplayTypes.filter((pdt) => (isReserving() ? pdt.reservable : !pdt.reservable))
                  }
                  selectedProductDisplayTypes={selectedProductDisplayTypes}
                  keywords={keywords}
                  selectedKeywords={selectedKeywords}
                  selectProductDisplayTypes={(productDisplayTypes) => {
                    setSelectedProductDisplayTypes(productDisplayTypes)
                    const queryParameters = constructQueryParameters({ productDisplayTypes: productDisplayTypes })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                  selectKeywords={(keywords) => {
                    setSelectedKeywords(keywords)
                    const queryParameters = constructQueryParameters({ keywords: keywords })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                />
              )}
              {open &&
                openedTab[3] && ( // Aika
                  <DateTimeTab
                    selectedDate={selectedDate}
                    selectedStartTime={selectedStartTime}
                    selectedEndTime={selectedEndTime}
                    dateTimeSelectionState={dateTimeSelectionState}
                    toggleDateTimeSelectionState={toggleDateTimeSelectionState}
                    onDateSelected={handleDateSelected}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                  />
                )}
            </div>
          </div>
          <div className='relative mt-4 flex min-h-[3rem] flex-col text-xl md:mx-[5.25rem]'>
            {!isLoading && taitoriIdle() && (
              <ActiveFilterGroup
                selectedDistricts={selectedDistricts}
                selectedProperties={selectedProperties}
                selectedCapacity={selectedCapacity}
                selectedArea={selectedArea}
                selectedProductDisplayTypes={selectedProductDisplayTypes}
                selectedKeywords={selectedKeywords}
                selectedDate={selectedDate}
                selectedStartTime={selectedStartTime}
                selectedEndTime={selectedEndTime}
                isReserving={isReserving()}
                clearDistrictFilter={() => {
                  setSelectedDistricts([])
                  const queryParameters = constructQueryParameters({ districts: [] })
                  router.replace(queryParameters, undefined, { shallow: true })
                }}
                clearPropertyFilter={() => {
                  setSelectedProperties([])
                  const queryParameters = constructQueryParameters({ properties: [] })
                  router.replace(queryParameters, undefined, { shallow: true })
                }}
                clearCapacityFilter={() => {
                  const newCapacity = { min: undefined, max: undefined }
                  setSelectedCapacity(newCapacity)
                  const queryParameters = constructQueryParameters({ capacity: newCapacity })
                  router.replace(queryParameters, undefined, { shallow: true })
                }}
                clearAreaFilter={() => {
                  const newArea = { min: undefined, max: undefined }
                  setSelectedArea(newArea)
                  const queryParameters = constructQueryParameters({ area: newArea })
                  router.replace(queryParameters, undefined, { shallow: true })
                }}
                clearProductDisplayTypeFilter={() => {
                  setSelectedProductDisplayTypes([])
                  const queryParameters = constructQueryParameters({ productDisplayTypes: [] })
                  router.replace(queryParameters, undefined, { shallow: true })
                }}
                clearKeywordFilter={() => {
                  setSelectedKeywords([])
                  const queryParameters = constructQueryParameters({ keywords: [] })
                  router.replace(queryParameters, undefined, { shallow: true })
                }}
              />
            )}
            {(isLoading || ((isBrowsingAll() || isReserving()) && isTaitoriLoading)) && (
              <div className='ml-[0.4rem] h-[3rem] self-center'>
                <FilterLoadingSpinner />
              </div>
            )}
          </div>
          <div className='align-center z-0 w-full md:mt-2'>
            <div className='align-center grid grid-cols-1 gap-y-8 gap-x-4 md:grid-cols-3'>
              {!isLoading &&
                !isError &&
                filteredSpaces?.map((item) =>
                  !isReserving() ? (
                    <SpaceGridItem key={item.space.id} property={item.property} space={item.space} />
                  ) : (
                    <SpaceGridItem
                      key={item.space.id}
                      property={item.property}
                      space={item.space}
                      startTime={selectedStartTime}
                      endTime={selectedEndTime}
                    />
                  )
                )}
            </div>
          </div>
        </div>
        {/* Enable this to prevent window from scrolling when choosing items (e.g. properties).
          This is because there can be a large variation in the number of elements displayed.
          <div className='border-1 border-darkblue h-[70rem] w-[1rem] border-solid' /> */}
      </PageContent>

      {/* ModalDialog is for mobile only */}
      <ModalDialog visible={modalFilterShown} onHide={toggleModalFilter}>
        <div className={`relative ${modalFilterDateTimeOpen ? 'min-h-[31rem]' : ''}`}>
          {/* Close dialog button */}
          <div className='absolute right-0 top-0' onClick={() => setModalFilterShown(false)}>
            <Image className='' src={modalDialogClose} alt='close' width='35' height='35' />
          </div>

          <div className='text-center text-2xl'>{t('filterResults')}</div>

          {/* Modal filter options */}
          <div className='mt-5 flex flex-col items-center'>
            {/*  Location */}
            <div className={`${mobileFilterTitleStyle} ${modalFilterLocationOpen ? modalFilterHeaderOpenStyle : ''}`}>
              <div onClick={toggleModalFilterLocationOpen}>
                <Icon className='absolute right-5 top-4' size='small' icon={iconChevron} />
                {t('location')}
              </div>
              {modalFilterLocationOpen && districts && properties && (
                <DistrictsAndProperties
                  districts={districts}
                  selectedDistricts={selectedDistricts}
                  properties={properties}
                  selectedProperties={selectedProperties}
                  selectDistricts={(districts) => {
                    setSelectedDistricts(districts)
                    const queryParameters = constructQueryParameters({ districts: districts })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                  selectProperties={(properties) => {
                    setSelectedProperties(properties)
                    const queryParameters = constructQueryParameters({ properties: properties })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                />
              )}
            </div>

            {/* Size */}
            <div
              className={`${mobileFilterTitleStyle} -top-[2px] ${
                modalFilterSizeOpen ? modalFilterHeaderOpenStyle : ''
              }`}>
              <div onClick={toggleModalFilterSizeOpen}>
                <Icon className='absolute right-5 top-4' size='small' icon={iconChevron} />
                {t('size')}
              </div>
              {modalFilterSizeOpen && (
                <CapacityAndArea
                  selectedCapacity={selectedCapacity}
                  selectedArea={selectedArea}
                  selectCapacity={(capacity) => {
                    setSelectedCapacity(capacity)
                    const queryParameters = constructQueryParameters({ capacity: capacity })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                  selectArea={(area) => {
                    setSelectedArea(area)
                    const queryParameters = constructQueryParameters({ area: area })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                  maxCapacity={500}
                  maxArea={2000}
                  isReserving={isReserving()}
                />
              )}
            </div>

            {/* Space type */}
            <div
              className={`${mobileFilterTitleStyle} -top-[4px] ${
                modalFilterSpaceTypeOpen ? modalFilterHeaderOpenStyle : ''
              }`}>
              <div onClick={toggleModalFilterSpaceTypeOpen}>
                <Icon className='absolute right-5 top-4' size='small' icon={iconChevron} />
                {t('spaceType')}
              </div>
              {productDisplayTypes && keywords && modalFilterSpaceTypeOpen && (
                <ProductTypesAndKeywords
                  productDisplayTypes={
                    isBrowsingAll()
                      ? productDisplayTypes
                      : productDisplayTypes.filter((pdt) => (isReserving() ? pdt.reservable : !pdt.reservable))
                  }
                  selectedProductDisplayTypes={selectedProductDisplayTypes}
                  keywords={keywords}
                  selectedKeywords={selectedKeywords}
                  selectProductDisplayTypes={(productDisplayTypes) => {
                    setSelectedProductDisplayTypes(productDisplayTypes)
                    const queryParameters = constructQueryParameters({ productDisplayTypes: productDisplayTypes })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                  selectKeywords={(keywords) => {
                    setSelectedKeywords(keywords)
                    const queryParameters = constructQueryParameters({ keywords: keywords })
                    router.replace(queryParameters, undefined, { shallow: true })
                  }}
                />
              )}
            </div>

            {/* Date/time for reservation */}
            {isReserving() && (
              <div
                className={`${mobileFilterTitleStyle} -top-[6px] ${
                  modalFilterDateTimeOpen ? `${modalFilterHeaderOpenStyle} rounded-b-none` : ''
                }`}>
                <div onClick={toggleModalFilterDateTimeOpen}>
                  <Icon className='absolute right-5 top-4' size='small' icon={iconChevron} />
                  {t('time')}
                </div>
                {modalFilterDateTimeOpen && (
                  <DateTimeTab
                    selectedDate={selectedDate}
                    selectedStartTime={selectedStartTime}
                    selectedEndTime={selectedEndTime}
                    dateTimeSelectionState={dateTimeSelectionState}
                    toggleDateTimeSelectionState={toggleDateTimeSelectionState}
                    onDateSelected={handleDateSelected}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                  />
                )}
              </div>
            )}

            <div className='relative mt-8 mb-5 w-2/3' onClick={() => setModalFilterShown(false)}>
              <div className={`${mobileFilterTitleStyle} flex flex-row`}>
                {t('showSpacesWithCount', { count: filteredSpaces.length })}

                {/* Arrow button */}
                <div className='absolute right-5'>
                  <div className='bg-darkblue absolute right-[-1.35rem] -top-[0.45rem] h-[2.95rem] w-[2.95rem] rounded-full' />
                  <div className='absolute right-[-0.85rem] top-[0.45rem] h-5 w-5'>
                    <Image src={searchArrowStem} alt='' layout='fill' />
                  </div>
                  <div className='absolute right-[-0.45rem] top-[0.45rem] h-5 w-5'>
                    <Image src={searchArrowHead} alt='' layout='fill' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>

      <Sidebar {...sidebarProps} />
    </>
  )
}

const TabChevronIcon = ({ className, tabOpen }: { className?: string; tabOpen: boolean }) => {
  return (
    <Icon
      className={`${className ?? ''} duration-0 ml-2 mt-[0.625rem] ${tabOpen ? 'rotate-180' : ''}`}
      size='small'
      icon={iconChevron}
    />
  )
}
