import { ReactComponent as AddIcon } from '../../assets/add.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/arrow-right.svg'
import { ReactComponent as BurgerIcon } from '../../assets/burger.svg'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as GlobeIcon } from '../../assets/globe.svg'
import { ReactComponent as LabelIcon } from '../../assets/label.svg'
import { ReactComponent as PlusIcon } from '../../assets/plus.svg'
import { ReactComponent as CheckmarkIcon } from '../../assets/checkmark.svg'
import { ReactComponent as POIIcon } from '../../assets/pin-antilooppi.svg'
import { ReactComponent as ProfileIcon } from '../../assets/profile.svg'
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg'
import { ReactComponent as CalendarArrowLeftIcon } from '../../assets/calendar-arrow-left.svg'
import { ReactComponent as CalendarArrowRightIcon } from '../../assets/calendar-arrow-right.svg'

export const iconAdd = AddIcon
export const iconArrowLeft = ArrowLeftIcon
export const iconArrowRight = ArrowRightIcon
export const iconBurger = BurgerIcon
export const iconClose = CloseIcon
export const iconGlobe = GlobeIcon
export const iconLabel = LabelIcon
export const iconPlus = PlusIcon
export const iconCheckmark = CheckmarkIcon
export const iconPOI = POIIcon
export const iconProfile = ProfileIcon
export const iconChevron = ChevronIcon
export const iconCalendarArrowLeft = CalendarArrowLeftIcon
export const iconCalendarArrowRight = CalendarArrowRightIcon
