/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiProductDisplayType,
} from '../models';
import {
    ApiProductDisplayTypeFromJSON,
    ApiProductDisplayTypeToJSON,
} from '../models';

/**
 * 
 */
export class ProductDisplayTypesPublicApi extends runtime.BaseAPI {

    /**
     * List product display types
     * List product display types
     */
    async listProductDisplayTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiProductDisplayType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-display-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiProductDisplayTypeFromJSON));
    }

    /**
     * List product display types
     * List product display types
     */
    async listProductDisplayTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiProductDisplayType>> {
        const response = await this.listProductDisplayTypesRaw(initOverrides);
        return await response.value();
    }

}
