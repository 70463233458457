/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiInvoice,
  ApiReservation,
  ApiReservationCancellationInput,
  ApiReservationInput,
  ApiReservationTimeslot,
  ApiTaitoriPaymentInput,
} from '../models';
import {
    ApiInvoiceFromJSON,
    ApiInvoiceToJSON,
    ApiReservationFromJSON,
    ApiReservationToJSON,
    ApiReservationCancellationInputFromJSON,
    ApiReservationCancellationInputToJSON,
    ApiReservationInputFromJSON,
    ApiReservationInputToJSON,
    ApiReservationTimeslotFromJSON,
    ApiReservationTimeslotToJSON,
    ApiTaitoriPaymentInputFromJSON,
    ApiTaitoriPaymentInputToJSON,
} from '../models';

export interface CancelTaitoriReservationRequest {
    apiReservationCancellationInput: ApiReservationCancellationInput;
}

export interface CreateCheckoutRequest {
    apiReservationInput: ApiReservationInput;
}

export interface CreateReservationWithInvoiceRequest {
    apiReservationInput: ApiReservationInput;
}

export interface GetCheckoutSessionRequest {
    sessionId: string;
}

export interface GetReservationsRequest {
    bookingReferences: string;
}

export interface ListReservationTimeslotsRequest {
    spaceId: string;
}

export interface TaitoriPaymentRequest {
    apiTaitoriPaymentInput: ApiTaitoriPaymentInput;
}

/**
 * 
 */
export class ReservationsPublicApi extends runtime.BaseAPI {

    /**
     * Cancel Taitori reservation and Stripe payment
     * Cancel Taitori reservation and Stripe payment
     */
    async cancelTaitoriReservationRaw(requestParameters: CancelTaitoriReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.apiReservationCancellationInput === null || requestParameters.apiReservationCancellationInput === undefined) {
            throw new runtime.RequiredError('apiReservationCancellationInput','Required parameter requestParameters.apiReservationCancellationInput was null or undefined when calling cancelTaitoriReservation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reservation-cancellation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiReservationCancellationInputToJSON(requestParameters.apiReservationCancellationInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel Taitori reservation and Stripe payment
     * Cancel Taitori reservation and Stripe payment
     */
    async cancelTaitoriReservation(requestParameters: CancelTaitoriReservationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.cancelTaitoriReservationRaw(requestParameters, initOverrides);
    }

    /**
     * Create checkout session (before redirecting to Stripe)
     * Create checkout session
     */
    async createCheckoutRaw(requestParameters: CreateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.apiReservationInput === null || requestParameters.apiReservationInput === undefined) {
            throw new runtime.RequiredError('apiReservationInput','Required parameter requestParameters.apiReservationInput was null or undefined when calling createCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reservations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiReservationInputToJSON(requestParameters.apiReservationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Create checkout session (before redirecting to Stripe)
     * Create checkout session
     */
    async createCheckout(requestParameters: CreateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.createCheckoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create reservation with invoice. Taitori reservation is marked as paid.
     * Create reservation with invoice
     */
    async createReservationWithInvoiceRaw(requestParameters: CreateReservationWithInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiInvoice>> {
        if (requestParameters.apiReservationInput === null || requestParameters.apiReservationInput === undefined) {
            throw new runtime.RequiredError('apiReservationInput','Required parameter requestParameters.apiReservationInput was null or undefined when calling createReservationWithInvoice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice-reservation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiReservationInputToJSON(requestParameters.apiReservationInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiInvoiceFromJSON(jsonValue));
    }

    /**
     * Create reservation with invoice. Taitori reservation is marked as paid.
     * Create reservation with invoice
     */
    async createReservationWithInvoice(requestParameters: CreateReservationWithInvoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiInvoice> {
        const response = await this.createReservationWithInvoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get checkout session
     * Get checkout session
     */
    async getCheckoutSessionRaw(requestParameters: GetCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.sessionId === null || requestParameters.sessionId === undefined) {
            throw new runtime.RequiredError('sessionId','Required parameter requestParameters.sessionId was null or undefined when calling getCheckoutSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reservations/{sessionId}`.replace(`{${"sessionId"}}`, encodeURIComponent(String(requestParameters.sessionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get checkout session
     * Get checkout session
     */
    async getCheckoutSession(requestParameters: GetCheckoutSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getCheckoutSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Taitori reservations by booking references
     * Get Taitori reservations by booking references
     */
    async getReservationsRaw(requestParameters: GetReservationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiReservation>>> {
        if (requestParameters.bookingReferences === null || requestParameters.bookingReferences === undefined) {
            throw new runtime.RequiredError('bookingReferences','Required parameter requestParameters.bookingReferences was null or undefined when calling getReservations.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookingReferences !== undefined) {
            queryParameters['bookingReferences'] = requestParameters.bookingReferences;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reservations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiReservationFromJSON));
    }

    /**
     * Get Taitori reservations by booking references
     * Get Taitori reservations by booking references
     */
    async getReservations(requestParameters: GetReservationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiReservation>> {
        const response = await this.getReservationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List existing bookings for a space (excluding past bookings)
     * List existing bookings for a space
     */
    async listReservationTimeslotsRaw(requestParameters: ListReservationTimeslotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiReservationTimeslot>>> {
        if (requestParameters.spaceId === null || requestParameters.spaceId === undefined) {
            throw new runtime.RequiredError('spaceId','Required parameter requestParameters.spaceId was null or undefined when calling listReservationTimeslots.');
        }

        const queryParameters: any = {};

        if (requestParameters.spaceId !== undefined) {
            queryParameters['spaceId'] = requestParameters.spaceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reservation-timeslots`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiReservationTimeslotFromJSON));
    }

    /**
     * List existing bookings for a space (excluding past bookings)
     * List existing bookings for a space
     */
    async listReservationTimeslots(requestParameters: ListReservationTimeslotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiReservationTimeslot>> {
        const response = await this.listReservationTimeslotsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark booking as paid in Taitori by getting payableguid for booking reference and then calling /api/reservation/payment
     * Mark booking as paid in Taitori
     */
    async taitoriPaymentRaw(requestParameters: TaitoriPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.apiTaitoriPaymentInput === null || requestParameters.apiTaitoriPaymentInput === undefined) {
            throw new runtime.RequiredError('apiTaitoriPaymentInput','Required parameter requestParameters.apiTaitoriPaymentInput was null or undefined when calling taitoriPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reservation-payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiTaitoriPaymentInputToJSON(requestParameters.apiTaitoriPaymentInput),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark booking as paid in Taitori by getting payableguid for booking reference and then calling /api/reservation/payment
     * Mark booking as paid in Taitori
     */
    async taitoriPayment(requestParameters: TaitoriPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.taitoriPaymentRaw(requestParameters, initOverrides);
    }

}
