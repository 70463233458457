/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiProductDisplayType,
  ApiProductDisplayTypeCreateInput,
  ApiProductDisplayTypeUpdateInput,
} from '../models';
import {
    ApiProductDisplayTypeFromJSON,
    ApiProductDisplayTypeToJSON,
    ApiProductDisplayTypeCreateInputFromJSON,
    ApiProductDisplayTypeCreateInputToJSON,
    ApiProductDisplayTypeUpdateInputFromJSON,
    ApiProductDisplayTypeUpdateInputToJSON,
} from '../models';

export interface CreateProductDisplayTypeRequest {
    apiProductDisplayTypeCreateInput: ApiProductDisplayTypeCreateInput;
}

export interface DeleteProductDisplayTypeRequest {
    productDisplayTypeId: string;
}

export interface UpdateProductDisplayTypeRequest {
    productDisplayTypeId: string;
    apiProductDisplayTypeUpdateInput: ApiProductDisplayTypeUpdateInput;
}

/**
 * 
 */
export class ProductDisplayTypesPrivateApi extends runtime.BaseAPI {

    /**
     * Create product display type
     * Create product display type
     */
    async createProductDisplayTypeRaw(requestParameters: CreateProductDisplayTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiProductDisplayType>> {
        if (requestParameters.apiProductDisplayTypeCreateInput === null || requestParameters.apiProductDisplayTypeCreateInput === undefined) {
            throw new runtime.RequiredError('apiProductDisplayTypeCreateInput','Required parameter requestParameters.apiProductDisplayTypeCreateInput was null or undefined when calling createProductDisplayType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-display-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiProductDisplayTypeCreateInputToJSON(requestParameters.apiProductDisplayTypeCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductDisplayTypeFromJSON(jsonValue));
    }

    /**
     * Create product display type
     * Create product display type
     */
    async createProductDisplayType(requestParameters: CreateProductDisplayTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiProductDisplayType> {
        const response = await this.createProductDisplayTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete product display type
     * Delete product display type
     */
    async deleteProductDisplayTypeRaw(requestParameters: DeleteProductDisplayTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.productDisplayTypeId === null || requestParameters.productDisplayTypeId === undefined) {
            throw new runtime.RequiredError('productDisplayTypeId','Required parameter requestParameters.productDisplayTypeId was null or undefined when calling deleteProductDisplayType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-display-types/{productDisplayTypeId}`.replace(`{${"productDisplayTypeId"}}`, encodeURIComponent(String(requestParameters.productDisplayTypeId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete product display type
     * Delete product display type
     */
    async deleteProductDisplayType(requestParameters: DeleteProductDisplayTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductDisplayTypeRaw(requestParameters, initOverrides);
    }

    /**
     * Update product display type
     * Update product display type
     */
    async updateProductDisplayTypeRaw(requestParameters: UpdateProductDisplayTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiProductDisplayType>> {
        if (requestParameters.productDisplayTypeId === null || requestParameters.productDisplayTypeId === undefined) {
            throw new runtime.RequiredError('productDisplayTypeId','Required parameter requestParameters.productDisplayTypeId was null or undefined when calling updateProductDisplayType.');
        }

        if (requestParameters.apiProductDisplayTypeUpdateInput === null || requestParameters.apiProductDisplayTypeUpdateInput === undefined) {
            throw new runtime.RequiredError('apiProductDisplayTypeUpdateInput','Required parameter requestParameters.apiProductDisplayTypeUpdateInput was null or undefined when calling updateProductDisplayType.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/product-display-types/{productDisplayTypeId}`.replace(`{${"productDisplayTypeId"}}`, encodeURIComponent(String(requestParameters.productDisplayTypeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiProductDisplayTypeUpdateInputToJSON(requestParameters.apiProductDisplayTypeUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiProductDisplayTypeFromJSON(jsonValue));
    }

    /**
     * Update product display type
     * Update product display type
     */
    async updateProductDisplayType(requestParameters: UpdateProductDisplayTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiProductDisplayType> {
        const response = await this.updateProductDisplayTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
