/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiOppoInput
 */
export interface ApiOppoInput {
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiOppoInput
     */
    productDisplayTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    propertyId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    propertyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    spaceId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    spaceName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOppoInput
     */
    businessId: string;
}

/**
 * Check if a given object implements the ApiOppoInput interface.
 */
export function instanceOfApiOppoInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "businessId" in value;

    return isInstance;
}

export function ApiOppoInputFromJSON(json: any): ApiOppoInput {
    return ApiOppoInputFromJSONTyped(json, false);
}

export function ApiOppoInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiOppoInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'productDisplayTypes': !exists(json, 'productDisplayTypes') ? undefined : json['productDisplayTypes'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'propertyId': !exists(json, 'propertyId') ? undefined : json['propertyId'],
        'propertyName': !exists(json, 'propertyName') ? undefined : json['propertyName'],
        'spaceId': !exists(json, 'spaceId') ? undefined : json['spaceId'],
        'spaceName': !exists(json, 'spaceName') ? undefined : json['spaceName'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'businessId': json['businessId'],
    };
}

export function ApiOppoInputToJSON(value?: ApiOppoInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'jobTitle': value.jobTitle,
        'description': value.description,
        'phoneNumber': value.phoneNumber,
        'productDisplayTypes': value.productDisplayTypes,
        'productType': value.productType,
        'propertyId': value.propertyId,
        'propertyName': value.propertyName,
        'spaceId': value.spaceId,
        'spaceName': value.spaceName,
        'companyName': value.companyName,
        'businessId': value.businessId,
    };
}

