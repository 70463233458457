/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiTaitoriAvailableSpaceInput
 */
export interface ApiTaitoriAvailableSpaceInput {
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriAvailableSpaceInput
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTaitoriAvailableSpaceInput
     */
    endDate: string;
}

/**
 * Check if a given object implements the ApiTaitoriAvailableSpaceInput interface.
 */
export function instanceOfApiTaitoriAvailableSpaceInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;

    return isInstance;
}

export function ApiTaitoriAvailableSpaceInputFromJSON(json: any): ApiTaitoriAvailableSpaceInput {
    return ApiTaitoriAvailableSpaceInputFromJSONTyped(json, false);
}

export function ApiTaitoriAvailableSpaceInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiTaitoriAvailableSpaceInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['startDate'],
        'endDate': json['endDate'],
    };
}

export function ApiTaitoriAvailableSpaceInputToJSON(value?: ApiTaitoriAvailableSpaceInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate,
        'endDate': value.endDate,
    };
}

