import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translations from './translations.json'
import { getFromLocalStorage } from '../lib/storage/localStorage'
import { LocalStorageKey } from '../lib/storage/localStorageKey'

export const defaultNS = 'public'
export const resources = translations

export type LanguageCode = 'en' | 'fi'
export const supportedLanguages = ['fi', 'en']
export const defaultLanguage = 'fi'

const isSupportedLanguageCode = (lang: string): lang is LanguageCode => {
  return supportedLanguages.includes(lang)
}

export const getSupportedLanguageOrDefault = (lang: string): LanguageCode => {
  return isSupportedLanguageCode(lang) ? lang : defaultLanguage
}

const getPreferredLanguage = () => {
  if (typeof window !== 'undefined') {
    const lang = getFromLocalStorage(LocalStorageKey.LANGUAGE)
    return lang ? lang : defaultLanguage
  }
  return defaultLanguage
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['public', 'admin', 'validation'],
    defaultNS,
    debug: false,
    lng: getPreferredLanguage(),
    fallbackLng: defaultLanguage,
    interpolation: { escapeValue: false },
    resources: translations,
    supportedLngs: supportedLanguages,
    preload: supportedLanguages,
  })

export default i18n
