import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { ReactComponent as AntilooppiLogo } from '../../assets/antilooppi-logo.svg'
import Icon from '../Icon'
import { iconBurger } from '../../lib/icons/icons'
import { useRouter } from 'next/router'
import NavBarLink from './NavBarLink'
import ContactUsButton from './ContactUsButton'
import Image from '../Image'
import profileImage from '../../assets/profile.svg'
import profileImageSelected from '../../assets/profile-selected.svg'
import { useIsAuthenticated } from '@azure/msal-react'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '../..//lib/api/current-user'
import { useAuth } from '../../lib/auth/useAuth'
import { useOutsideClick } from '../../lib/useOutsideClick'

export type NavBarLinkConfig = { title: string; url?: string; onClick?: () => void }
export type NavBarTheme = 'blue' | 'white'

const getColorClasses = (theme: NavBarTheme) => {
  switch (theme) {
    case 'blue':
      return 'text-blue'
    default:
      return 'text-white'
  }
}

const NavBar = ({
  home = '/',
  primaryLinks = [],
  secondaryLinks = [],
  showContactUs = false,
  theme = 'blue',
}: {
  home?: string
  primaryLinks?: Array<NavBarLinkConfig>
  secondaryLinks?: Array<NavBarLinkConfig>
  showContactUs?: boolean
  theme?: NavBarTheme
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const colorClasses = getColorClasses(theme)
  const authenticated = useIsAuthenticated()
  const { data: user } = useCurrentUser()

  const handleClick = () => {
    setBurgerMenuOpen(!burgerMenuOpen)
  }

  const { auth } = useAuth()

  const signOut = (redirectUrl?: string) => {
    auth.instance.logout(redirectUrl)
  }

  const [loggedIn, setLoggedIn] = useState(false)

  useEffect(() => {
    setLoggedIn(authenticated)
  }, [authenticated])

  const [profileOpen, setProfileOpen] = useState<boolean>(false)

  const handleClickOutside = () => {
    setProfileOpen(false)
  }
  const ref = useOutsideClick(handleClickOutside, profileOpen)

  return (
    <nav className={`bg-offwhite fixed top-0 z-30 w-full md:h-[128px] ${burgerMenuOpen ? 'h-full' : ''}`}>
      <div className={`flex flex-wrap items-center px-5 py-3 md:h-full ${colorClasses} max-w-6xl md:mx-auto`}>
        <div className='self-center'>
          <Link href={home} legacyBehavior>
            <a className='relative inline-flex h-[27px] w-[31px] items-center md:h-[53px] md:w-[60px]'>
              <AntilooppiLogo width='100%' height='100%' />
            </a>
          </Link>
        </div>
        <button
          className='ml-auto inline-flex rounded p-3 outline-none hover:bg-neutral-300 md:hidden'
          onClick={handleClick}>
          <Icon icon={iconBurger} size='large' />
        </button>
        <div
          className={`ml-4 w-full flex-col items-start md:mt-0 md:ml-auto md:inline-flex md:h-auto md:w-auto md:flex-grow md:flex-col-reverse md:items-end md:justify-start ${
            burgerMenuOpen ? '' : 'hidden'
          }`}>
          <div className='md:font-body mt-4 flex flex-col font-serif text-3xl md:mt-0 md:flex-row md:items-baseline md:text-2xl'>
            {primaryLinks.map((item, index) => (
              <NavBarLink
                active={item.url === router.pathname}
                title={item.title}
                href={item.url}
                onClick={item.onClick}
                key={index}
                className='md:h-[50px]'
              />
            ))}
            {showContactUs && <ContactUsButton className='hidden md:block' />}
          </div>
          <div className='text-gray md:font-body mt-4 flex h-8 flex-col font-serif text-3xl md:mt-0 md:flex-row md:text-xl'>
            {secondaryLinks.map((item, index) => (
              <NavBarLink title={item.title} href={item.url} onClick={item.onClick} key={index} className='md:py-0' />
            ))}
            {loggedIn && (
              <div ref={ref} className='align-right justify-right relative h-8 w-16 cursor-pointer'>
                <>
                  <Image
                    src={profileOpen ? profileImageSelected : profileImage}
                    alt='Profiili'
                    onClick={() => {
                      setProfileOpen(!profileOpen)
                    }}
                    layout='fill'
                  />
                  {profileOpen && (
                    <div className='text-darkblue absolute h-fit w-fit border-2 border-solid border-black bg-white px-3 pb-2 pt-1 md:right-0 md:top-9'>
                      <div className='cursor-auto select-text'>{user?.email}</div>
                      {!router.asPath.includes('/admin') && (
                        <Link href='/profile'>
                          <div>{t('reservationHistory')}</div>
                        </Link>
                      )}
                      <div
                        onClick={async () => {
                          // Redirect to the same page (now without being logged in), or to the
                          // main page in case the user is on a page that requires being logged in
                          signOut(router.asPath.includes('/profile') ? '/' : undefined)
                          setProfileOpen(false)
                        }}>
                        {t('signOut')}
                      </div>
                    </div>
                  )}
                </>
              </div>
            )}
          </div>
          {showContactUs && <ContactUsButton className='mt-4 block md:hidden' />}
        </div>
      </div>
    </nav>
  )
}

export default NavBar
