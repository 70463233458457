/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiResponsibility,
  ApiResponsibilityCreateInput,
  ApiResponsibilityUpdateInput,
} from '../models';
import {
    ApiResponsibilityFromJSON,
    ApiResponsibilityToJSON,
    ApiResponsibilityCreateInputFromJSON,
    ApiResponsibilityCreateInputToJSON,
    ApiResponsibilityUpdateInputFromJSON,
    ApiResponsibilityUpdateInputToJSON,
} from '../models';

export interface CreateResponsibilityRequest {
    apiResponsibilityCreateInput: ApiResponsibilityCreateInput;
}

export interface DeleteResponsibilityRequest {
    responsibilityId: string;
}

export interface UpdateResponsibilityRequest {
    responsibilityId: string;
    apiResponsibilityUpdateInput: ApiResponsibilityUpdateInput;
}

/**
 * 
 */
export class ResponsibilitiesPrivateApi extends runtime.BaseAPI {

    /**
     * Create responsibility
     * Create responsibility
     */
    async createResponsibilityRaw(requestParameters: CreateResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiResponsibility>> {
        if (requestParameters.apiResponsibilityCreateInput === null || requestParameters.apiResponsibilityCreateInput === undefined) {
            throw new runtime.RequiredError('apiResponsibilityCreateInput','Required parameter requestParameters.apiResponsibilityCreateInput was null or undefined when calling createResponsibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/responsibilities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiResponsibilityCreateInputToJSON(requestParameters.apiResponsibilityCreateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponsibilityFromJSON(jsonValue));
    }

    /**
     * Create responsibility
     * Create responsibility
     */
    async createResponsibility(requestParameters: CreateResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiResponsibility> {
        const response = await this.createResponsibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete responsibility
     * Delete responsibility
     */
    async deleteResponsibilityRaw(requestParameters: DeleteResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.responsibilityId === null || requestParameters.responsibilityId === undefined) {
            throw new runtime.RequiredError('responsibilityId','Required parameter requestParameters.responsibilityId was null or undefined when calling deleteResponsibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/responsibilities/{responsibilityId}`.replace(`{${"responsibilityId"}}`, encodeURIComponent(String(requestParameters.responsibilityId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete responsibility
     * Delete responsibility
     */
    async deleteResponsibility(requestParameters: DeleteResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteResponsibilityRaw(requestParameters, initOverrides);
    }

    /**
     * Responsibilities
     * Responsibilities
     */
    async listResponsibilitiesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiResponsibility>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/responsibilities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiResponsibilityFromJSON));
    }

    /**
     * Responsibilities
     * Responsibilities
     */
    async listResponsibilities(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiResponsibility>> {
        const response = await this.listResponsibilitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update responsibility
     * Update responsibility
     */
    async updateResponsibilityRaw(requestParameters: UpdateResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiResponsibility>> {
        if (requestParameters.responsibilityId === null || requestParameters.responsibilityId === undefined) {
            throw new runtime.RequiredError('responsibilityId','Required parameter requestParameters.responsibilityId was null or undefined when calling updateResponsibility.');
        }

        if (requestParameters.apiResponsibilityUpdateInput === null || requestParameters.apiResponsibilityUpdateInput === undefined) {
            throw new runtime.RequiredError('apiResponsibilityUpdateInput','Required parameter requestParameters.apiResponsibilityUpdateInput was null or undefined when calling updateResponsibility.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/responsibilities/{responsibilityId}`.replace(`{${"responsibilityId"}}`, encodeURIComponent(String(requestParameters.responsibilityId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApiResponsibilityUpdateInputToJSON(requestParameters.apiResponsibilityUpdateInput),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResponsibilityFromJSON(jsonValue));
    }

    /**
     * Update responsibility
     * Update responsibility
     */
    async updateResponsibility(requestParameters: UpdateResponsibilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiResponsibility> {
        const response = await this.updateResponsibilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
