export type FilterSelectedItem = {
  id: string
  name: string
  reservable?: boolean
}

export type FilterMinMaxItem = {
  min: number | undefined
  max: number | undefined
}

export enum DateTimeSelectionState {
  AllClosed,
  SelectingDate,
  SelectingStartTime,
  SelectingEndTime,
}
