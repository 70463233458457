import { ApiSpace } from '@antilooppi/public-api'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const SpaceGridItemDetails = ({ space }: { space: ApiSpace }) => {
  const { i18n, t } = useTranslation()
  const productDisplayType = _.first(space.productDisplayTypes)
  const comma = productDisplayType && space.floorNumber ? ', ' : ''
  const floorNumberAndProductDisplayTypeAvailable = space.floorNumber && productDisplayType
  const onlyProductDisplayTypeAvailable = !space.floorNumber && productDisplayType
  const onlyFloorNumberAvailable = space.floorNumber && !productDisplayType

  const getNumPeople = () => {
    if (!space.minCapacity && space.maxCapacity) {
      return `${space.maxCapacity} ${t('people')}`
    }

    if (space.minCapacity && space.maxCapacity) {
      return `${space.minCapacity}-${space.maxCapacity} ${t('people')}`
    }
  }

  return (
    <div className='flex w-full flex-wrap gap-x-3'>
      {space.roomArea !== 0 && <SpaceDetails text={`${space.roomArea} m²`} />}
      {floorNumberAndProductDisplayTypeAvailable && (
        <SpaceDetails
          text={`${i18n.language === 'fi' ? productDisplayType?.nameFi : productDisplayType?.nameEn}${
            space.floorNumber !== undefined ? comma + space.floorNumber + '.' + t('floorAbbr') : ''
          }`}
        />
      )}
      {onlyFloorNumberAvailable && (
        <SpaceDetails text={space.floorNumber !== undefined ? comma + space.floorNumber + '.' + t('floorAbbr') : ''} />
      )}
      {onlyProductDisplayTypeAvailable && (
        <SpaceDetails text={i18n.language === 'fi' ? productDisplayType.nameFi : productDisplayType.nameEn} />
      )}
      <SpaceDetails text={getNumPeople() ?? ''} />
    </div>
  )
}

const SpaceDetails = ({ text }: { text: string }) => {
  return (
    <div className='flex flex-row'>
      <div className='ml-1 text-2xl'>&#x2022;</div>
      <div className='ml-3 text-2xl'>{text}</div>
    </div>
  )
}

export default SpaceGridItemDetails
