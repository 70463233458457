import { Portal } from './Portal'
import React, { Children, isValidElement, ReactNode, Ref, useEffect } from 'react'
import Button from './Button'
import { useTranslation } from 'react-i18next'
import { useSidebar } from '../lib/useSidebar'
import { useRouter } from 'next/router'

type SidebarWithRefProps = {
  visible: boolean
  onHide: () => void
  children?: ReactNode
  forwardedRef?: Ref<HTMLDivElement> | null
}

const SidebarWithRef = ({ visible, onHide, children, forwardedRef }: SidebarWithRefProps) => {
  const { t } = useTranslation()

  const router = useRouter()

  const { sidebarContent, hideSidebar } = useSidebar()

  useEffect(() => {
    // Escape key closes sidebar
    document.onkeydown = (event) => {
      if (event.key === 'Escape') {
        onHide()
        hideSidebar()
        document.onkeydown = null
        try {
          // Prevent focus ring being displayed on the active element by unfocusing it
          const element = document.activeElement as HTMLElement
          element.blur()
        } catch {
          // Ignore
        }
      }
    }

    const onRouteChangeStart = () => {
      hideSidebar()
    }
    router.events.on('routeChangeStart', onRouteChangeStart)
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [hideSidebar, onHide, router.events])

  useEffect(() => {
    if (visible) {
      document.querySelector('body')?.classList.add('sidebar-open')
    } else {
      document.querySelector('body')?.classList.remove('sidebar-open')
    }
  }, [visible])

  return visible ? (
    <Portal wrapperId='sidebar'>
      <div className='fixed top-0 right-0 z-40 h-full w-full md:w-[36rem]'>
        <div className='h-full w-full overflow-y-auto bg-white p-5 pt-5 md:px-20 md:py-10' ref={forwardedRef}>
          {sidebarContent ||
            Children.map(children, (child) => {
              return isValidElement(child) ? child : null
            })}
          <Button text={t('cancel')} className='mt-4 mb-4 w-full md:hidden' onClick={onHide} />
        </div>
      </div>
      <div className='fixed left-0 top-0 z-30 h-screen w-screen bg-black opacity-[15%]' onClick={onHide} />
    </Portal>
  ) : null
}

export type SidebarProps = Omit<SidebarWithRefProps, 'forwardedRef'>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => (
  <SidebarWithRef forwardedRef={ref} visible={props.visible} onHide={props.onHide}>
    {props.children}
  </SidebarWithRef>
))
Sidebar.displayName = 'Sidebar'

export default Sidebar
