import { z } from 'zod'
import { ApiServiceCreateInput, ApiServiceUpdateInput } from '../../generated/private-api'

export const ServiceCreateInputSchema: z.ZodType<ApiServiceCreateInput> = z.object({
  nameFi: z.string(),
  nameEn: z.string(),
  iconUrl: z.string(),
})

export const ServiceUpdateInputSchema: z.ZodType<ApiServiceUpdateInput> = z.object({
  id: z.string(),
  nameFi: z.string(),
  nameEn: z.string(),
  iconUrl: z.string(),
})
