import { ApiBookingCalendar } from '../../generated/public-api'
import { z } from 'zod'

export const BookingCalendarSchema: z.ZodType<ApiBookingCalendar> = z.object({
  customerFirstname: z.string(),
  customerLastname: z.string(),
  customerCompany: z.string(),
  customerEmail: z.string(),
  customerPhone: z.string(),
  spaceId: z.string(),
  propertyId: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  staffId: z.string(),
  businessId: z.string(),
})
