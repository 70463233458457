import { ApiImage, ApiProperty, ApiSpace } from '@antilooppi/public-api'

export type ImageInfoEmpty = {
  state: 'empty'
  type: string
}
export type ImageInfoLoaded = {
  state: 'loaded'
  type: string
  key: string
  fileInfo: File
  data: string
  order: number
  image: string
}
export type ImageInfoSaved = {
  state: 'saved'
  type: string
  id: string
  baseurl: string
  path: string
  order?: number
}
export type ImageInfoRemoved = {
  state: 'removed'
  id?: string
  key?: string
}
export type ImageInfo = ImageInfoEmpty | ImageInfoLoaded | ImageInfoSaved

export type ImageId = {
  id: string
  key?: string
}

export const getPropertyPromoImagePath = (property: ApiProperty) => {
  const promoImage = (property.images || []).find((img) => img.id === property.defaultImageId)
  return promoImage ? `${promoImage.baseurl}${promoImage.thumbnailPath ?? promoImage.path}` : undefined
}

export const getPropertyFullPromoImagePath = (property: ApiProperty) => {
  const promoImage = (property.images || []).find((img) => img.id === property.defaultImageId)
  return promoImage ? `${promoImage.baseurl}${promoImage.path}` : undefined
}

export const getSpacePromoImagePath = (space: ApiSpace) => {
  const promoImage = (space.images || []).find((img) => img.id === space.defaultImageId)
  return promoImage ? `${promoImage.baseurl}${getOptimizedImage(promoImage)}` : undefined
}

export const getOptimizedImage = (image: ApiImage): string => {
  return image.thumbnailPath !== undefined ? image.thumbnailPath : image.path
}

export enum ImageType {
  Space = 'space',
  Blueprint = 'blueprint',
  Service = 'service',
  Responsibility = 'responsibility',
  Distance = 'distance',
}
