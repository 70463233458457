import { InteractionStatus } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Auth } from './auth'
import { AccessToken } from '@antilooppi/api'
import { useTranslation } from 'react-i18next'

export type UseAuthUser = {
  username: string
  accessToken: AccessToken
}

export type UseAuthAuth = {
  instance: Auth
  user: UseAuthUser | null
}

export type UseAuthResult = {
  auth: UseAuthAuth
  error: Error | null
  isLoading: boolean
}

export const useAuth = (): UseAuthResult => {
  const router = useRouter()

  const { i18n } = useTranslation()

  const { instance, inProgress, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})
  const isLoading = inProgress !== InteractionStatus.None

  const [auth, setAuth] = useState<UseAuthAuth>(() => {
    return {
      instance: new Auth(instance, i18n.language),
      user: null,
    }
  })
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (account && !auth.user) {
      auth.instance
        .acquireAccessToken(account, router.pathname)
        .then((authUser) => {
          if (authUser) {
            setAuth({
              instance: auth.instance,
              user: {
                username: authUser.username,
                accessToken: authUser.accessToken,
              },
            })
          }
        })
        .catch((error) => {
          setError(error as Error)
        })
    }
  }, [account, router.pathname, auth.instance, auth.user])

  return { auth, error, isLoading }
}
