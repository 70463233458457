import { z } from 'zod'
import {
  ApiReservationCancellationInput,
  ApiReservationConfirmationInput,
  ApiReservationInput,
} from '../../generated/public-api'
import { parseISO } from 'date-fns'

export const ReservationCancellationInputSchema: z.ZodType<ApiReservationCancellationInput> = z.object({
  bookingReference: z.string(),
  cancellationSecret: z.string(),
  paymentMethod: z.string(),
})

export const ReservationCustomerDetailsInputSchema = z.object({
  email: z.string().min(1, 'validation:emailRequired').email('validation:invalidEmail'),
  firstName: z.string().min(1, 'validation:firstNameRequired'),
  lastName: z.string().min(1, 'validation:lastNameRequired'),
  phoneNumber: z.string().min(1, 'validation:phoneNumberRequired'),
  companyName: z.string().min(1, 'validation:companyNameRequired'),
  businessId: z.string().regex(/\d{6,7}-\d/, 'validation:invalidBusinessId'),
  termsAccepted: z.boolean().optional(),
  shouldCreateAccount: z.boolean().optional(),
  solution: z.string().min(1).optional(),
})

export const ReservationTimeSlotInputSchema = z.object({
  startDate: z.string().refine((val) => parseISO(val)),
  endDate: z.string().refine((val) => parseISO(val)),
})

export const ReservationConfirmationInputSchema: z.ZodType<ApiReservationConfirmationInput> = z.object({
  spaceId: z.string().min(1),
  timeslots: z.array(ReservationTimeSlotInputSchema),
  customerDetails: ReservationCustomerDetailsInputSchema.optional(),
})

export const ReservationPaymentRowInputSchema = z.object({
  id: z.string().optional(),
  paymentId: z.string().optional(),
  startDate: z.string(),
  endDate: z.string(),
})

export const ReservationInputSchema: z.ZodType<ApiReservationInput> = z.object({
  name: z.string(),
  spaceId: z.string(),
  propertyId: z.string(),
  reservedHours: z.number(),
  termsAccepted: z.boolean(),
  vatIncluded: z.number(),
  vatExcluded: z.number(),
  customerDetails: ReservationCustomerDetailsInputSchema,
  paymentRows: z.array(ReservationPaymentRowInputSchema).optional(),
  timeslots: z.array(ReservationTimeSlotInputSchema),
  paymentMethod: z.enum(['creditCard', 'invoice', 'internalFree']),
  invoiceReference: z.string().optional(),
  internal: z.boolean(),
})
