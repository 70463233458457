/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Public API
 * Antilooppi Digital Platform Public API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiBookingBusinessHours,
  ApiBookingCalendar,
} from '../models';
import {
    ApiBookingBusinessHoursFromJSON,
    ApiBookingBusinessHoursToJSON,
    ApiBookingCalendarFromJSON,
    ApiBookingCalendarToJSON,
} from '../models';

export interface BookingAvailabilityRequest {
    startDate?: string;
}

export interface BookingCalendarRequest {
    apiBookingCalendar: ApiBookingCalendar;
    lang?: string;
}

export interface BookingCalendarAppointmentRequest {
    appointmentId: string;
}

/**
 * 
 */
export class CalendarBookingsPublicApi extends runtime.BaseAPI {

    /**
     * Get availability booking to MS Booking calendar
     * Get availability booking to MSBooking calendar
     */
    async bookingAvailabilityRaw(requestParameters: BookingAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/booking-availability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get availability booking to MS Booking calendar
     * Get availability booking to MSBooking calendar
     */
    async bookingAvailability(requestParameters: BookingAvailabilityRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.bookingAvailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create booking to MS Booking calendar
     * Create booking to MSBooking calendar
     */
    async bookingCalendarRaw(requestParameters: BookingCalendarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiBookingCalendar>> {
        if (requestParameters.apiBookingCalendar === null || requestParameters.apiBookingCalendar === undefined) {
            throw new runtime.RequiredError('apiBookingCalendar','Required parameter requestParameters.apiBookingCalendar was null or undefined when calling bookingCalendar.');
        }

        const queryParameters: any = {};

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/booking-calendar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApiBookingCalendarToJSON(requestParameters.apiBookingCalendar),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiBookingCalendarFromJSON(jsonValue));
    }

    /**
     * Create booking to MS Booking calendar
     * Create booking to MSBooking calendar
     */
    async bookingCalendar(requestParameters: BookingCalendarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiBookingCalendar> {
        const response = await this.bookingCalendarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get created booking
     * Get created booking
     */
    async bookingCalendarAppointmentRaw(requestParameters: BookingCalendarAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiBookingCalendar>> {
        if (requestParameters.appointmentId === null || requestParameters.appointmentId === undefined) {
            throw new runtime.RequiredError('appointmentId','Required parameter requestParameters.appointmentId was null or undefined when calling bookingCalendarAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/booking-calendar/{appointmentId}`.replace(`{${"appointmentId"}}`, encodeURIComponent(String(requestParameters.appointmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiBookingCalendarFromJSON(jsonValue));
    }

    /**
     * Get created booking
     * Get created booking
     */
    async bookingCalendarAppointment(requestParameters: BookingCalendarAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiBookingCalendar> {
        const response = await this.bookingCalendarAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get business working hours
     * Get business working hours
     */
    async getBusinessWorkingHoursRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApiBookingBusinessHours>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Ocp-Apim-Subscription-Key"] = this.configuration.apiKey("Ocp-Apim-Subscription-Key"); // api-key authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer-token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/booking-businesshours`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiBookingBusinessHoursFromJSON(jsonValue));
    }

    /**
     * Get business working hours
     * Get business working hours
     */
    async getBusinessWorkingHours(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApiBookingBusinessHours> {
        const response = await this.getBusinessWorkingHoursRaw(initOverrides);
        return await response.value();
    }

}
