/* tslint:disable */
/* eslint-disable */
/**
 * Antilooppi Digital Platform Private API
 * Antilooppi Digital Platform Private API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiUserReservationInput
 */
export interface ApiUserReservationInput {
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof ApiUserReservationInput
     */
    reservationType?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    endDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    spaceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    propertyId: string;
    /**
     * 
     * @type {number}
     * @memberof ApiUserReservationInput
     */
    exVatPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ApiUserReservationInput
     */
    vatPercent: number;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    bookingReference: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    cancellationSecret: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    paymentOrInvoiceId: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUserReservationInput
     */
    paymentMethod: string;
}

/**
 * Check if a given object implements the ApiUserReservationInput interface.
 */
export function instanceOfApiUserReservationInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "spaceId" in value;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "exVatPrice" in value;
    isInstance = isInstance && "vatPercent" in value;
    isInstance = isInstance && "bookingReference" in value;
    isInstance = isInstance && "cancellationSecret" in value;
    isInstance = isInstance && "paymentOrInvoiceId" in value;
    isInstance = isInstance && "paymentMethod" in value;

    return isInstance;
}

export function ApiUserReservationInputFromJSON(json: any): ApiUserReservationInput {
    return ApiUserReservationInputFromJSONTyped(json, false);
}

export function ApiUserReservationInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiUserReservationInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'reservationType': !exists(json, 'reservationType') ? undefined : json['reservationType'],
        'startDate': json['startDate'],
        'endDate': !exists(json, 'endDate') ? undefined : json['endDate'],
        'spaceId': json['spaceId'],
        'propertyId': json['propertyId'],
        'exVatPrice': json['exVatPrice'],
        'vatPercent': json['vatPercent'],
        'bookingReference': json['bookingReference'],
        'cancellationSecret': json['cancellationSecret'],
        'paymentOrInvoiceId': json['paymentOrInvoiceId'],
        'paymentMethod': json['paymentMethod'],
    };
}

export function ApiUserReservationInputToJSON(value?: ApiUserReservationInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'reservationType': value.reservationType,
        'startDate': value.startDate,
        'endDate': value.endDate,
        'spaceId': value.spaceId,
        'propertyId': value.propertyId,
        'exVatPrice': value.exVatPrice,
        'vatPercent': value.vatPercent,
        'bookingReference': value.bookingReference,
        'cancellationSecret': value.cancellationSecret,
        'paymentOrInvoiceId': value.paymentOrInvoiceId,
        'paymentMethod': value.paymentMethod,
    };
}

