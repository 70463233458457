import { policies, PolicyName } from './authConfig'
import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  RedirectRequest,
} from '@azure/msal-browser'
import { AccessToken, makeAccessToken } from '@antilooppi/api'
import { ADMIN_PATH } from './protectedRoute'

export type AuthUser = {
  username: string
  accessToken: AccessToken
}

export class Auth {
  constructor(private instance: IPublicClientApplication, private language: string = 'en') {}

  async login(policyName: PolicyName, params?: Partial<RedirectRequest>) {
    const policy = policies[policyName]
    await this.instance
      .loginRedirect({
        ...policy,
        extraQueryParameters: {
          ...{ ui_locales: this.language },
          ...(policy.extraQueryParameters || {}),
          ...(params?.extraQueryParameters || {}),
        },
      })
      .catch((error) => {
        console.error('Login failed', error)
        throw error
      })
  }

  getAuthPolicy(pathname: string) {
    if (pathname.toLowerCase().startsWith(ADMIN_PATH)) {
      return policies.antilooppisignupsignin
    } else {
      return policies.signupsignin
    }
  }

  private pickUserName(result: AuthenticationResult): string {
    if (!result.account?.username) {
      throw new Error('Username is missing')
    }
    return result.account?.username
  }

  private pickAccessToken(result: AuthenticationResult): AccessToken {
    const accessToken = makeAccessToken(result.accessToken)
    if (!accessToken) {
      throw new Error('Invalid access token')
    }
    return accessToken
  }

  async acquireAccessToken(account: AccountInfo, pathname: string): Promise<AuthUser | null> {
    try {
      const result = await this.instance.acquireTokenSilent({
        ...this.getAuthPolicy(pathname),
        account,
      })
      return {
        username: this.pickUserName(result),
        accessToken: this.pickAccessToken(result),
      }
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await this.instance.acquireTokenRedirect({
          ...this.getAuthPolicy(pathname),
          account,
        })
        return null
      } else {
        console.error('Acquire access token failed', error)
        throw error
      }
    }
  }

  async logout(redirectUrl?: string) {
    await this.instance.logoutRedirect({
      postLogoutRedirectUri: redirectUrl ?? (window?.location.href || '/'),
    })
  }
}
