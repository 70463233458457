import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { usePublicApi } from '../usePublicApi'
import { useAuth } from '../auth/useAuth'
import { ApiError, ApiTaitoriAvailableSpace } from '@antilooppi/public-api'

export const useTaitoriAvailableSpaces = (
  startDate: string,
  endDate: string,
  isReserving: boolean
): UseQueryResult<ApiTaitoriAvailableSpace[], ApiError> => {
  const { auth } = useAuth()
  const { taitoriAvailableSpacesApi } = usePublicApi(auth.user)
  return useQuery<ApiTaitoriAvailableSpace[], ApiError>(
    ['taitori-available-spaces', { startDate, endDate }],
    () =>
      taitoriAvailableSpacesApi.listAvailableSpaces({
        apiTaitoriAvailableSpaceInput: { startDate: startDate, endDate: endDate },
      }),
    {
      staleTime: 60000,
      useErrorBoundary: false, // Do not throw errors (see Azure function logs instead)
      enabled: isReserving,
    }
  )
}
