import { ApiError, ApiUser } from '@antilooppi/public-api'
import { useQuery } from '@tanstack/react-query'
import { useAuth } from '../auth/useAuth'
import { usePublicApi } from '../usePublicApi'

export const useCurrentUser = () => {
  const { auth } = useAuth()
  const { usersApi } = usePublicApi(auth.user)

  return useQuery<ApiUser, ApiError>([auth?.user?.username, 'currentUser'], () => usersApi.getCurrentUser(), {
    enabled: auth.user != null,
  })
}
